import React, { useEffect, useState } from 'react'

import _ from 'lodash'
import { useFormContext } from 'react-hook-form'

import FormControl from '../../modules/form/FormControl'
import FormSwitch from '../../modules/form/FormSwitch'

const FormPermissions = ({
  name,
  permissions: permissionsRaw = {},
  selectedPermissions: selectedPermissionsRaw = [],
  namespace,
  namespaceLabel,
  ungrouped,
  switchOptions = {}
}) => {
  const [selectedPermissions, setSelectedPermissions] = useState(
    selectedPermissionsRaw
  )
  const [permissions, setPermissions] = useState({})
  const { setValue, getValues } = useFormContext()

  const handleChange = (value, nameToAdd) => {
    if (value) {
      setSelectedPermissions(prevState => [...prevState, nameToAdd])
    } else
      setSelectedPermissions(prevState =>
        prevState.filter(sp => sp !== nameToAdd)
      )
  }

  const handleChangeManagePermission = (value, manageKey, actions) => {
    Object.entries(actions).forEach(([actionKey, action]) => {
      if (actionKey == ':') return

      setValue(action.name, value)

      if (!getValues(`${name}[]`).includes(action.name))
        handleChange(value, action.name)
    })
  }

  useEffect(() => {
    setValue(`${name}[]`, selectedPermissions)
  }, [selectedPermissions, setValue, name])

  useEffect(() => {
    if (ungrouped) setPermissions(permissionsRaw)
    else {
      let grouped = {}

      Object.entries(permissionsRaw).forEach(([key, value]) => {
        const keySplitedByNamespace = key.split(`${namespace}/`)
        if (keySplitedByNamespace.length > 1) {
          const keyWithoutNamespace = keySplitedByNamespace[1]
          const keyWithoutNamespaceSplited = keyWithoutNamespace.split(':')
          const group = keyWithoutNamespaceSplited[0]
          const action = keyWithoutNamespaceSplited[1]

          if (!_.has(grouped, group)) {
            grouped[group] = {
              groupLabel: value.split(' - ')[0].split(`${namespaceLabel}/`)[1],
              items: {
                ':': {
                  label: value.split(' - ')[1],
                  name: key
                }
              }
            }
          } else {
            grouped[group].items[action] = {
              label: value.split(' - ')[1],
              name: key
            }
          }
        } else {
          grouped[':'] = {
            name: key,
            label: 'Gerente'
          }
        }
      })

      setPermissions(grouped)
    }
  }, [permissionsRaw, namespace, ungrouped])

  return (
    <div className="mt-3">
      {ungrouped
        ? Object.entries(permissions).map(([key, value], index) => (
            <div key={index}>
              <div className="d-flex align-items-center mb-4">
                <FormSwitch
                  name={key}
                  onChange={activated => handleChange(activated, value)}
                  className="me-3"
                  options={switchOptions}
                />{' '}
                <span className="form-check-label ms-0 fw-bold fs-6 text-gray-700">
                  {namespace
                    ? `${value.split(' - ')[1]} ${value
                        .split(' - ')[0]
                        .replace(`${_.capitalize(namespaceLabel)}/`, '')}`
                    : `${value.split(' - ')[1]} ${value.split(' - ')[0]}`}
                </span>
              </div>
              <div className="separator separator-dashed my-8"></div>
            </div>
          ))
        : Object.entries(permissions).map(([key, value], index) =>
            key == ':' ? (
              <div key={index}>
                <div className="d-flex align-items-center mb-4">
                  <FormSwitch
                    name={key}
                    onChange={activated => handleChange(activated, key)}
                    className="me-3"
                    options={switchOptions}
                  />{' '}
                  <span className="form-check-label ms-0 fw-bold fs-6 text-gray-700">
                    Gerente Geral
                  </span>
                </div>
                <div className="separator separator-dashed my-8"></div>
              </div>
            ) : (
              <div key={index}>
                <div className="fs-5 fw-bolder mb-4">{value.groupLabel}</div>
                <div className="d-flex flex-column flex-sm-row align-items-sm-center flex-wrap mb-2">
                  {Object.entries(value.items).map(([actionKey, action]) => (
                    <div
                      key={action.name}
                      className="d-flex align-items-center me-8 flex-fill mb-4"
                    >
                      <FormSwitch
                        name={action.name}
                        onChange={activated => {
                          if (actionKey == ':' && activated)
                            handleChangeManagePermission(
                              activated,
                              key,
                              value.items
                            )
                          handleChange(activated, action.name)
                        }}
                        className="me-3"
                        options={switchOptions}
                        defaultValue={selectedPermissionsRaw.includes(
                          action.name
                        )}
                      />
                      <span className="form-check-label ms-0 fw-bold fs-6 text-gray-700">
                        {action.label}
                      </span>
                    </div>
                  ))}
                </div>
                <div className="separator separator-dashed mb-8 mt-6"></div>
              </div>
            )
          )}
      <FormControl name={`${name}[]`} type="hidden" />
    </div>
  )
}

export default FormPermissions
