import classNames from 'classnames'
import RBInputGroup from 'react-bootstrap/InputGroup'

const InputGroup = ({ solid, className, children, ...rest }) => {
  const classes = classNames(className, {
    'input-group-solid': solid
  })

  return (
    <RBInputGroup className={classes} {...rest}>
      {children}
    </RBInputGroup>
  )
}

InputGroup.Text = RBInputGroup.Text

export default InputGroup
