import React, { useState, useEffect, memo } from 'react'

import dynamic from 'next/dynamic'
import _ from 'lodash'
import pluralize from 'pluralize'
import { useController, useFormContext } from 'react-hook-form'
// import { AsyncPaginate } from 'react-select-async-paginate'

import { useDataProviderContext } from '../../../context/DataProviderContext'

const AsyncPaginate = dynamic(
  () =>
    import('react-select-async-paginate').then(module => module.AsyncPaginate),
  { ssr: false }
)

const sleep = ms =>
  new Promise(resolve => {
    setTimeout(() => {
      resolve()
    }, ms)
  })

const AsyncSelect = ({
  name,
  customSrcName,
  multiple,
  label,
  labelKey = 'name',
  valueKey = 'id',
  filterKey,
  isClearable,
  clearable,
  disabled,
  sort,
  promise,
  optionsMapping,
  url,
  href,
  path,
  placeholder: propsPlaceholder,
  onChange,
  control,
  rules,
  defaultValue,
  className,
  search,
  defaultOptions,
  ...rest
}) => {
  const {
    field: { ref, value, onChange: onChangeInput, ...inputProps },
    fieldState: { invalid, isTouched, isDirty },
    formState: { touchedFields, dirtyFields }
  } = useController({
    name,
    control,
    rules,
    defaultValue
  })
  const { getValues } = useFormContext()
  const record = getValues()
  let firstLoadedOptions = false

  const { dataProvider } = useDataProviderContext()
  const [options, setOptions] = useState(null)
  const urlHrefOrPath = url || href || path

  const placeholder = propsPlaceholder
    ? propsPlaceholder
    : 'Digite pelo menos 3 caracteres...'

  const loadOptions = async (searchTerm, loadedOptions, { page }) => {
    // if (searchTerm.length <= 0)
    //   return { options: currentOptions, hasMore: false }
    await sleep(250)
    if (!dataProvider) throw new UserException('Undefined Data Provider')

    let response = null
    try {
      response = await dataProvider.getList(urlHrefOrPath, {
        pagination: { page },
        sort: sort ? sort : { field: 'name', order: 'asc' },
        filter:
          search && _.isFunction(search)
            ? search(searchTerm)
            : { [`${filterKey ? filterKey : 'name_cont'}`]: searchTerm }
      })

      firstLoadedOptions = true
    } catch (error) {
      console.log(error)
    }

    if (optionsMapping) return optionsMapping(response, page)
    else {
      const { records, pagination } = response
      const currentOptions = records.map(r => ({
        value: _.get(r, valueKey),
        label: label && _.isFunction(label) ? label(r) : _.get(r, labelKey),
        record: r
      }))

      setOptions(prevState =>
        _.uniqBy(
          [...(prevState !== null ? prevState : []), ...currentOptions],
          'value'
        )
      )

      return {
        options: currentOptions,
        hasMore: pagination ? pagination.page < pagination.page_count : false,
        additional: {
          page: page + 1
        }
      }
    }
  }

  const getOptionsOnRecord = () => {
    if (multiple) {
      const optionsOnRecord = _.get(
        record,
        customSrcName ? customSrcName : pluralize(name.replace('_ids', ''))
      )

      if (!optionsOnRecord) return []

      return optionsOnRecord.map(o => ({
        value: _.get(o, valueKey),
        label: _.get(o, labelKey),
        record: o
      }))
    }

    // Get the current option object on record
    const optionOnRecord = _.get(
      record,
      customSrcName ? customSrcName : name.replace('_id', '')
    )

    if (optionOnRecord && _.isObject(optionOnRecord)) {
      return [
        {
          value: _.get(optionOnRecord, valueKey),
          label:
            label && _.isFunction(label)
              ? label(optionOnRecord)
              : _.get(optionOnRecord, labelKey),
          record: optionOnRecord
        }
      ]
    }

    return [
      {
        value: _.get(record, name),
        label: _.get(record, name)
      }
    ]
  }

  const getOptions = () => {
    if (!options) return getOptionsOnRecord()

    return _.uniqBy(options.concat(getOptionsOnRecord()), 'value')
  }

  const getSelectedOptions = values => {
    if (!_.isArray(values) || values.length === 0) return []

    const options = getOptions()

    if (!options || options.length === 0) return []

    const selectedOptions = options.filter(option =>
      values.includes(option.value)
    )

    return selectedOptions
  }

  const getSelectedOption = value => {
    if (value == null || value === undefined) return null

    const options = getOptions()

    if (!options || options.length === 0) return []

    const selectedOption = options.find(option => value == option.value)

    return selectedOption
  }

  const handleOnChange = selection => {
    let value = selection ? selection.value : null
    let record = !multiple && _.find(options, { value })?.record

    if (selection && multiple) {
      value = selection.map(s => s.value)

      try {
        record = options
          .filter(opt => value.includes(opt.value))
          .map(opt => opt.record)
      } catch (error) {}
    }

    onChangeInput(value)
    onChange && onChange(value, record)
  }

  return (
    <AsyncPaginate
      selectRef={ref}
      value={multiple ? getSelectedOptions(value) : getSelectedOption(value)}
      minLength={3}
      defaultOptions={defaultOptions}
      onChange={handleOnChange}
      placeholder={placeholder}
      debounceTimeout={250}
      loadOptions={loadOptions}
      additional={{
        page: 1
      }}
      className={`${className}`}
      classNamePrefix="form-react-select"
      isMulti={multiple}
      isClearable={isClearable || clearable}
      isDisabled={disabled}
      {...inputProps}
      {...rest}
    />
  )
}

const AsyncSelectContainer = ({ name, ...rest }) => {
  const { control } = useFormContext()

  return <AsyncSelect name={name} control={control} {...rest} />
}

export default AsyncSelectContainer
