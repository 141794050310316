import * as yup from 'yup'

export const getValidationSchema = (props = {}) => {
  const { withTerms, withEmailConfirmation, customValidations } = props

  return yup
    .object()
    .shape({
      name: yup
        .string()
        .nullable()
        .required()
        .test(
          'is-valid-name',
          'Por favor, insira nome completo sem abreviações',
          value => {
            const names = value.split(' ')
            const hasAbbr = names.find(
              l => l.length == 1 || (l.length == 2 && l.includes('.'))
            )
            const fewNames = names.length <= 1

            // if (hasAbbr) return false
            if (fewNames) return false
            return true
          }
        )
        .label('Nome'),
      email: yup.string().email().nullable().required().label('E-mail'),
      email_confirmation:
        withEmailConfirmation &&
        yup
          .string()
          .email()
          .nullable()
          .oneOf([yup.ref('email'), null], 'E-mails devem estar iguais')
          .required()
          .label('Confirmação de email'),
      password: yup
        .string()
        .nullable()
        .min(8, 'Digite no mínimo 8 caracteres')
        .max(128, 'Digite no máximo 128 caracteres')
        .matches(/^\S*$/, 'Senha não pdoeconter espaços')
        .required()
        .label('Senha'),
      password_confirmation: yup
        .string()
        .nullable()
        .oneOf([yup.ref('password'), null], 'Senhas devem estar iguais')
        .required()
        .label('Confirmação de senha'),
      accept_terms: yup
        .bool()
        .nullable()
        .test(
          'is-checked',
          'Antes de continuar, aceite os Termos de Uso',
          val => (!!withTerms && _.isString(withTerms) ? val : true)
        )
        .label('Termos de Uso')
    })
    .concat(customValidations)
}
