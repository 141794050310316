import { DateTime } from 'luxon'

export const formatDateString = (d = '', formattedAsISO) => {
  if (!d) return ''
  const date =
    !formattedAsISO || d.length <= 10
      ? d.includes('/')
        ? DateTime.fromFormat(d, 'dd/MM/yyyy')
        : DateTime.fromFormat(d, 'yyyy-MM-dd')
      : DateTime.fromISO(d)

  if (!date.isValid) return ''

  const now = DateTime.now()
  const diff = now.diff(date, ['days'])
  const diffDays = Math.floor(diff.days)

  const capitalizeFirstLetter = s => s.charAt(0).toUpperCase() + s.slice(1)

  if (now.year == date.year)
    return capitalizeFirstLetter(date.toFormat("dd 'de' LLLL 'de' yyyy"))
  else if (diffDays > 7)
    return capitalizeFirstLetter(date.toFormat("dd 'de' LLLL 'de' yyyy"))
  else if (diffDays == 7) return 'Há uma semana'
  else if (diffDays >= 2) return `Há ${Math.floor(diffDays)} dias`
  else if (diffDays == 1) return `Ontem`
  else if (diffDays == 0) return `Hoje` // Between 0.0 and 0.9 days
  return capitalizeFirstLetter(date.toFormat("dd 'de' LLLL 'de' yyyy"))
}
