import { Button } from 'react-bootstrap'
import _ from 'lodash'

import { useAuthContext } from '../context'

const BRAND_LOGOS = {
  google: '/svg/brand-logos/google-icon.svg',
  facebook: '/svg/brand-logos/facebook-4.svg',
  apple: '/svg/brand-logos/apple-black.svg'
}
export const SignUpProviders = () => {
  const { providers, styleConfig, PROVIDERS_ENABLED } = useAuthContext()
  const socialProviders = _.omit(providers, [
    'domain-sign-in',
    'refresh-session'
  ])

  if (!PROVIDERS_ENABLED) return <></>
  return (
    <>
      {Object.values(socialProviders).length > 0 && (
        <>
          <div className="row g-3 mb-9">
            {Object.values(socialProviders).map(provider => (
              <Button
                key={provider.id}
                variant="light"
                type="button"
                size={styleConfig.size}
                onClick={() => onSignUp(provider.id)}
                className="btn btn-flex flex-center w-100 mb-5"
              >
                <img
                  alt="Logo"
                  src={BRAND_LOGOS[provider.id]}
                  className="h-20px me-3"
                />
                Entre com {provider.name}
              </Button>
            ))}
          </div>
          <div className="separator separator-content my-14">
            <span className="w-125px text-gray-500 fw-semibold fs-7">
              Ou com e-mail
            </span>
          </div>
        </>
      )}
    </>
  )
}
