import _ from 'lodash'

import { FormControl, FormGroup } from '@tootz/react-admin/form'

import { useAuthContext } from '../../context'

import { validationSchema } from './validationSchema'

export const ForgotPasswordFormFields = () => {
  const { styleConfig } = useAuthContext()

  return (
    <>
      <FormGroup
        label="E-mail"
        labelClassName="fw-bolder"
        name="email"
        size={styleConfig.size}
        solid={styleConfig.solid ? true : undefined}
        control={FormControl}
        type="email"
        vertical
      />
    </>
  )
}

ForgotPasswordFormFields.validationSchema = validationSchema
