import React, { useState, useEffect, createContext, useContext } from 'react'

import { Button } from 'react-bootstrap'
import { useFormContext, useFieldArray, useWatch } from 'react-hook-form'
import _ from 'lodash'

import { Modal, Separator } from '@tootz/react-admin/components'
import { Select, BtnGroupRadio, FormGroup } from '@tootz/react-admin/form'
import { useRecords } from '@tootz/react-admin'

import useRecord from '../../hooks/useRecord'

const BondsFieldsContext = createContext()
const useBondsFieldsContext = () => useContext(BondsFieldsContext)
const BondsFieldsProvider = ({
  clientKindLabel,
  emptyFieldsConfig,
  bonds,
  pathToGetClients,
  pathToGetRoles,
  kinds,
  children
}) => {
  return (
    <BondsFieldsContext.Provider
      value={{
        clientKindLabel,
        emptyFieldsConfig,
        pathToGetClients,
        pathToGetRoles,
        kinds
      }}
    >
      {children}
    </BondsFieldsContext.Provider>
  )
}

export { useBondsFieldsContext, BondsFieldsProvider }

const ModalBondFields = ({
  selectedBond,
  handleClose,
  handleAddBond,
  ...rest
}) => {
  const { clientKindLabel, pathToGetRoles, pathToGetClients, kinds } =
    useBondsFieldsContext()
  const { getValues, setValue, control } = useFormContext()
  const selectedKind = useWatch({
    control,
    name: 'newBond.kind',
    defaultValue: null
  })
  const selectedClient = useWatch({
    control,
    name: 'newBond.client_id',
    defaultValue: null
  })

  useEffect(() => {
    if (selectedBond) setValue('newBond', selectedBond)
    else setValue('newBond.kind', 'admin')
  }, [])

  useEffect(() => {
    if (selectedKind == 'admin') setValue('newBond.client_id', null)
  }, [selectedKind])

  return (
    <Modal show={rest.show} centered>
      <Modal.Header>
        <Modal.Title>Adicione um vínculo</Modal.Title>
        <Modal.SubTitle>
          Você precisa selecionar o tipo de vínculo e os níveis de acesso
        </Modal.SubTitle>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-3">
          <BtnGroupRadio
            name="newBond.kind"
            options={kinds}
            className="flex-nowrap"
            wrapperClassName="btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center me-3 mb-3 w-50"
            asCards
          />
        </div>
        {selectedKind == 'client' && (
          <div className="">
            <FormGroup
              name="newBond.client_id"
              control={Select}
              async={{
                path: pathToGetClients
              }}
              label={`Selecione um ${clientKindLabel.toLowerCase()}`}
              vertical
              solid
            />
          </div>
        )}
        {(selectedKind == 'admin' ||
          (selectedKind == 'client' && selectedClient)) && (
          <div className="">
            <FormGroup
              key={`role_ids-${selectedKind}-${selectedClient}`}
              name="newBond.role_ids"
              control={Select}
              async={{
                path: pathToGetRoles,
                search: searchTerm => ({
                  name_cont: searchTerm,
                  ...(selectedKind == 'admin'
                    ? { client_id_null: true }
                    : { client_id_eq: selectedClient })
                })
              }}
              label="Selecione os níveis de acesso"
              multiple
              vertical
              solid
            />
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-light" onClick={handleClose}>
          Cancelar
        </button>
        <button
          className="btn btn-primary"
          onClick={() => handleAddBond(getValues('newBond'))}
        >
          Adicionar
        </button>
      </Modal.Footer>
    </Modal>
  )
}

const BondCard = ({ client_id, role_ids, kind, handleRemove }) => {
  const { pathToGetRoles, pathToGetClients } = useBondsFieldsContext()

  const { records: roles, isLoading: isLoadingRoles } = useRecords(
    role_ids.length > 0 ? pathToGetRoles : undefined,
    {
      filter: { id_in: role_ids }
    }
  )

  const { record: client, isLoading: isLoadingClient } = useRecord({
    r: pathToGetClients,
    id: client_id
  })

  return (
    <div className="d-flex border border-gray-300 border-dashed rounded p-6 h-100">
      <div className="d-flex flex-grow-1 me-2 me-sm-5">
        <div className="symbol symbol-50px me-4">
          <span className="symbol-label">
            <i
              className={`fa-duotone fa-${
                kind == 'admin' ? 'user-group-crown' : 'shop'
              } fs-1`}
            ></i>
          </span>
        </div>
        <div className="me-2">
          <p className="text-gray-800 fs-6 fw-bold mb-0">
            {client_id && !isLoadingClient && client?.username}
            {!client_id && 'Administração interna'}
          </p>
          {!isLoadingRoles && (
            <span className="text-gray-500 fw-bold d-block fs-7">
              {_.join(
                role_ids.map(roleId => roles.find(r => r.id == roleId).name),
                ', '
              )}
            </span>
          )}
          <div className="mt-3">
            <button
              className="btn btn-sm btn-light-danger btn-icon"
              onClick={handleRemove}
            >
              <i className="fa-duotone fa-trash fs-7"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

const BondsFields = ({
  clientKindLabel = 'Cliente',
  emptyFieldsConfig = {
    title: 'Quais os níveis de acesso deste usuário?',
    subtitle: 'Cliquei no botão abaixo e adicione quantos desejar',
    buttonLabel: 'Vamos Lá!',
    icon: {
      class: 'fa-duotone fa-user-shield',
      size: 'fs-3qx'
    }
  },
  pathToGetRoles = '/roles',
  pathToGetClients = '/clients'
}) => {
  const [showBondModal, setShowBondModal] = useState(false)
  const kinds = [
    {
      value: 'admin',
      label: 'Administração interna',
      icon: 'fa-duotone fa-user-group-crown'
    },
    {
      value: 'client',
      label: clientKindLabel,
      icon: 'fa-duotone fa-shop'
    }
  ]

  const { control } = useFormContext()
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      control,
      name: 'bonds'
    }
  )
  const bondsWatch = useWatch({
    control,
    name: 'bonds_attributes',
    defaultValue: []
  })

  const onCloseBondFieldsModal = () => setShowBondModal(false)

  const onAddBond = newBond => {
    append(newBond)
    onCloseBondFieldsModal()
  }

  return (
    <BondsFieldsProvider
      clientKindLabel={clientKindLabel}
      emptyFieldsConfig={emptyFieldsConfig}
      pathToGetClients={pathToGetClients}
      pathToGetRoles={pathToGetRoles}
      bonds={bondsWatch}
      kinds={kinds}
    >
      <div>
        {fields.length == 0 && (
          <div className="alert bg-light d-flex flex-center flex-column py-10 px-10 px-lg-20 mb-10">
            <i
              className={`${emptyFieldsConfig.icon.class} ${emptyFieldsConfig.icon.size} mb-6`}
            ></i>

            <div className="text-center">
              <h3 className="fw-bolder mb-5">{emptyFieldsConfig.title}</h3>
              <Separator style="dashed" className="mb-5" />
              <div className="mb-9 text-dark">{emptyFieldsConfig.subtitle}</div>

              <div className="d-flex flex-center flex-wrap">
                <Button
                  variant="primary"
                  onClick={() => setShowBondModal(true)}
                >
                  {emptyFieldsConfig.buttonLabel}
                </Button>
              </div>
            </div>
          </div>
        )}
        {fields.length >= 1 && (
          <FormGroup label="Vínculos">
            <div className="row">
              {fields.map((field, index) => (
                <div key={field.id} className="col-md-6 col-lg-4 mb-6">
                  <BondCard {...field} handleRemove={() => remove(index)} />
                </div>
              ))}
              <div className="col-md-6 col-lg-4 mb-6">
                <div
                  className="d-flex align-items-center justify-content-center border border-gray-300 border-dashed rounded p-6 mb-6 h-100 cursor-pointer"
                  onClick={() => setShowBondModal(true)}
                >
                  <div className="symbol symbol-50px me-4">
                    <span className="symbol-label bg-light-primary">
                      <i className="fa-duotone fa-plus fs-1 text-primary"></i>
                    </span>
                  </div>
                  <p className="text-primary fs-6 fw-bolder mb-0">
                    Adicionar vínculo
                  </p>
                </div>
              </div>
            </div>
          </FormGroup>
        )}
        {showBondModal && (
          <ModalBondFields
            show={showBondModal}
            handleClose={onCloseBondFieldsModal}
            handleAddBond={onAddBond}
          />
        )}
      </div>
    </BondsFieldsProvider>
  )
}

export default BondsFields
