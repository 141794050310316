import { Modal } from '@tootz/react-admin/components'

import { ConfirmationForm } from '../Confirmation'

export const ConfirmationFormModal = ({ email, show, hideEmail, onHide }) => {
  return (
    <Modal show={show} onHide={onHide} dialogClassName="mw-650px">
      <Modal.Body className="pt-15">
        <ConfirmationForm
          email={email}
          hideEmail={hideEmail}
          onConfirm={onHide}
        />
      </Modal.Body>
    </Modal>
  )
}
