import React from 'react'

import Form from 'react-bootstrap/Form'
import { useController, useFormContext } from 'react-hook-form'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import getInputClasses from './utils/getInputClasses'
import getRules from './utils/getRules'

const FormRadioController = ({
  name,
  control,
  options = [
    { label: 'Sim', value: true },
    { label: 'Não', value: false }
  ],
  switch: isSwitch,
  inline,
  size,
  solid,
  white,
  flush,
  shadow,
  className,
  defaultValue = '',
  required,
  min,
  max,
  minLength,
  maxLength,
  pattern,
  validate,
  onChange,
  ...rest
}) => {
  const {
    field: { ref, value, onChange: onChangeField, ...inputProps },
    fieldState: { invalid, isTouched, isDirty },
    formState: { errors, touchedFields, dirtyFields }
  } = useController({
    name,
    control,
    rules: getRules({
      required,
      min,
      max,
      minLength,
      maxLength,
      pattern,
      validate
    }),
    defaultValue
  })
  const classes = getInputClasses({
    prefix: 'form-check',
    size,
    solid,
    white,
    flush,
    shadow,
    className,
    'form-check-custom': true,
    'pt-2': true,
    'mb-2': !inline,
    'mb-4 me-4': inline
  })
  const wrapperClasses = classNames('d-flex g-6', {
    'flex-row flex-wrap': inline,
    'flex-column': !inline
  })

  const isChecked = optionValue =>
    optionValue !== undefined &&
    value !== undefined &&
    optionValue !== null &&
    value !== null &&
    value.toString() == optionValue.toString()

  const handleChange = value => {
    onChangeField(value)
    onChange && onChange(value)
  }

  return (
    <div className={wrapperClasses}>
      {options.map(option => (
        <Form.Check
          key={option.value}
          value={option.value}
          name={name}
          id={`${name}_${option.value}`}
          label={option.label}
          type="radio"
          checked={isChecked(option.value)}
          onChange={() => handleChange(option.value)}
          className={classes}
          {...rest}
          {...inputProps}
        />
      ))}
    </div>
  )
}

const FormRadioContainer = ({ name, ...rest }) => {
  const { control } = useFormContext()

  return <FormRadioController name={name} control={control} {...rest} />
}

FormRadioContainer.propTypes = {
  name: PropTypes.string.isRequired
}

export default FormRadioContainer
