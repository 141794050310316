import { createContext, useContext, useEffect, useState } from 'react'

import _ from 'lodash'
import { DateTime } from 'luxon'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { getSession } from 'next-auth/react'
import { useCookies } from 'react-cookie'
import { api } from 'src/services/api'
import store from 'store2'

import { useRecord, useSession } from '@tootz/react-admin'

import { formatDateString } from '@/services/format/date'

import { useModalContext } from './ModalContext'

const AppContext = createContext()

const useAppContext = () => useContext(AppContext)

const isDev = process.env.NODE_ENV == 'development'

const SERVICES_STORE_KEY = 'maestria.services'
const VISITED_DOCUMENTS_STORE_KEY = 'maestria.visited-documents'
const RECENT_SEARCHES_STORE_KEY = 'maestria.recent-searches'
const DOCUMENTS_IN_COLLECTIONS_STORE_KEY = 'maestria.documents-in-collections'
const RECENT_DOCUMENTS_IN_COLLECTIONS_STORE_KEY =
  'maestria.recent-documents-in-collections'

const ProductFruits = dynamic(
  () => import('react-product-fruits').then(mod => mod.ProductFruits),
  { ssr: false }
)

const AppProvider = ({ session, children, ...rest }) => {
  const [cookies, setCookie, removeCookie] = useCookies()
  const { showModal } = useModalContext()
  const router = useRouter()
  const { data: sessionData, status } = useSession()

  // Services
  const [services, setServices] = useState(
    store(SERVICES_STORE_KEY) || { collections: false }
  )
  useEffect(() => {
    store(SERVICES_STORE_KEY, services || { collections: false })
  }, [services])

  // Recent Searches
  const [recentSearches, setRecentSearches] = useState(
    store(RECENT_SEARCHES_STORE_KEY) || []
  )

  useEffect(() => {
    store(
      'maestria.recent-searches',
      !recentSearches || !Array.isArray(recentSearches) ? [] : recentSearches
    )
  }, [recentSearches])

  const handleDeleteRecentSearch = search => {
    const newRecentSearches = [...recentSearches]
    const index = newRecentSearches.findIndex(
      s => s.toLowerCase() == search.toLowerCase()
    )

    if (index >= 0) {
      newRecentSearches.splice(index, 1)
      setRecentSearches(newRecentSearches)
    }
  }

  // Visited Documents
  const [visitedDocuments, setVisitedDocuments] = useState(
    (_.isArray(store(VISITED_DOCUMENTS_STORE_KEY))
      ? store(VISITED_DOCUMENTS_STORE_KEY)
      : []) || []
  )
  useEffect(() => {
    store(VISITED_DOCUMENTS_STORE_KEY, visitedDocuments || [])
  }, [visitedDocuments])

  const documentWasVisited = documentId => {
    const wasVisited = visitedDocuments.find(doc => doc.id == documentId)

    return [
      wasVisited,
      wasVisited?.visited,
      formatDateString(wasVisited?.visited, true)
    ]
  }

  const addDocumentToVisited = documentId => {
    let alreadyVisitedDocuments = [...visitedDocuments]
    let visitedIndex = alreadyVisitedDocuments.findIndex(
      doc => doc.id == documentId
    )

    if (visitedIndex != -1) alreadyVisitedDocuments.splice(visitedIndex, 1)

    visitedIndex = alreadyVisitedDocuments.findIndex(
      doc => doc.id == documentId
    )

    if (visitedIndex == -1 && documentId)
      setVisitedDocuments(() =>
        [
          { id: documentId, visited: DateTime.now().toISO() },
          ...alreadyVisitedDocuments
        ].splice(0, 5)
      )
  }

  // Documents saved in collections
  const [savedDocumentsIds, setSavedDocumentsIds] = useState(
    store(DOCUMENTS_IN_COLLECTIONS_STORE_KEY) || []
  )

  const [recentSavedDocumentsIds, setRecentSavedDocumentsIds] = useState(
    store(RECENT_DOCUMENTS_IN_COLLECTIONS_STORE_KEY) || []
  )

  useEffect(() => {
    store(DOCUMENTS_IN_COLLECTIONS_STORE_KEY, savedDocumentsIds || [])
  }, [savedDocumentsIds])

  useEffect(() => {
    store(
      RECENT_DOCUMENTS_IN_COLLECTIONS_STORE_KEY,
      recentSavedDocumentsIds || []
    )
  }, [recentSavedDocumentsIds])

  const refetchCollectionsDocumentsIds = async () => {
    // try {
    //   const resCollections = await selfApi.get('/data/${user}/collections')
    //   const { records: collections } = resCollections.data
    //   let allDocumentsIds = []
    //   collections.forEach(collection => {
    //     allDocumentsIds = allDocumentsIds.concat(collection.documentIds)
    //   })
    //   setSavedDocumentsIds(_.uniq(allDocumentsIds))
    //   setServices(prevState => ({ ...prevState, collections: true }))
    // } catch (error) {
    //   setServices(prevState => ({ ...prevState, collections: false }))
    // }
  }

  const toggleDocumentToCollection = async (documentId, collection) => {
    // let newDocumentIds = [...collection.documentIds]
    // const index = newDocumentIds.findIndex(id => id == documentId)
    // if (index >= 0) {
    //   newDocumentIds.splice(index, 1)
    //   setRecentSavedDocumentsIds(prevState => {
    //     const newRecentSavedDocumentsIds = [...prevState]
    //     const documentIdIndex = newRecentSavedDocumentsIds.findIndex(
    //       id => id == documentId
    //     )
    //     newRecentSavedDocumentsIds.splice(documentIdIndex, 1)
    //     return _.uniq(newRecentSavedDocumentsIds)
    //   })
    // } else {
    //   newDocumentIds = [...newDocumentIds, documentId]
    //   setRecentSavedDocumentsIds(prevState =>
    //     _.uniq([...prevState, documentId])
    //   )
    // }
    // await selfApi.put(`/data/${userId}/collections/${collection.id}`, {
    //   name: collection.name,
    //   documentIds: _.uniq(newDocumentIds)
    // })
    // await refetchCollectionsDocumentsIds()
  }

  const verifyClient = async () => {
    const session = await getSession()

    if (router.pathname !== '/' && session) {
      const { user } = session

      try {
        if (
          !cookies[`maestria.daily-login-${user.id}`] ||
          cookies[`maestria.daily-login-${user.id}`] !=
            DateTime.now().toFormat('yyLLdd')
        ) {
          await api.post('/client/active_day')

          setCookie(
            `maestria.daily-login-${user.id}`,
            DateTime.now().toFormat('yyLLdd')
          )
        }
      } catch (error) {}

      // if (
      //   router.pathname !== '/' &&
      //   !isLoadingSubscription &&
      //   (!subscription ||
      //     DateTime.fromISO(subscription?.expires_at) < DateTime.now()) &&
      //   DateTime.fromISO(user?.roles[0]?.client?.finish_free_plan_at) <
      //     DateTime.now()
      // )
      //   showModal({
      //     component: SubscriptionModal,
      //     blocking: true,
      //     onSave: async () => {
      //       await signIn('refresh-session', {
      //         token: session.accessToken,
      //         redirect: false
      //       })
      //     }
      //   })
    }
  }

  useEffect(() => {
    refetchCollectionsDocumentsIds()
  }, [])

  useEffect(() => {
    if (router.pathname !== '/') verifyClient()
  }, [router.pathname])

  return (
    <AppContext.Provider
      value={{
        services,
        recentSearches,
        setRecentSearches,
        handleDeleteRecentSearch,
        documentWasVisited,
        addDocumentToVisited,
        visitedDocuments,
        savedDocumentsIds,
        recentSavedDocumentsIds,
        refetchCollectionsDocumentsIds,
        toggleDocumentToCollection,
        showModal,
        ...rest
      }}
    >
      {!isDev && global.window && (
        <ProductFruits
          workspaceCode={process.env.NEXT_PUBLIC_PRODUCT_FRUITS_WORKSPACE_CODE}
          language="pt"
          user={{
            username: sessionData?.user?.email || 'cloud@maestrialaw.com.br'
          }}
        />
      )}
      {children}
    </AppContext.Provider>
  )
}

const withApp = Component => {
  const WrappedComponent = props => {
    return (
      <AppProvider>
        <Component {...props} />
      </AppProvider>
    )
  }

  WrappedComponent.displayName = `withApp(${Component.displayName})`

  return WrappedComponent
}

export { useAppContext, AppProvider, withApp }
