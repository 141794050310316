import React from 'react'

import _ from 'lodash'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import ErrorMessage from './ErrorMessage'

// const Control = memo(
//   ({ name, control: ControlComponent, ...rest }) => (
//     <ControlComponent name={name} {...rest} />
//   ),
//   (prevProps, nextProps) =>
//     prevProps.control === nextProps.control &&
//     (!prevProps.options || prevProps.options === nextProps.options) &&
//     (!prevProps.disabled || prevProps.disabled === nextProps.disabled) &&
//     (!prevProps.value || prevProps.value === nextProps.value)
// )

const Control = ({ name, control: ControlComponent, ...rest }) => (
  <ControlComponent name={name} {...rest} />
)

const HelpText = ({ text }) => (
  <Form.Text className="text-muted d-block w-100">{text}</Form.Text>
)

const FormLabel = ({
  label,
  labelInfo,
  labelClassName = '',
  vertical,
  required
}) => (
  <Form.Label
    column={!vertical}
    lg={vertical ? null : 4}
    xl={vertical ? null : 3}
    className={`${vertical ? 'd-block' : ''} ${labelClassName}`}
  >
    <span className={`${required ? 'required' : ''}`}>{label}</span>
    {labelInfo && (
      <OverlayTrigger overlay={<Tooltip>{labelInfo}</Tooltip>}>
        <i className="fad fa-exclamation-circle ms-2 fs-4 text-hover-primary"></i>
      </OverlayTrigger>
    )}
  </Form.Label>
)

const FormGroup = ({
  name,
  onChange,
  label,
  labelInfo,
  labelClassName,
  horizontal = true,
  vertical = false,
  margin = 6,
  required,
  helpText,
  control,
  solid,
  children,
  ...rest
}) => {
  if (vertical)
    return (
      <div className={`mb-${margin}`}>
        {label && (
          <FormLabel
            label={label}
            labelInfo={labelInfo}
            labelClassName={labelClassName}
            horizontal={horizontal}
            vertical={vertical}
            required={required}
          />
        )}
        {control && (
          <Control
            name={name}
            control={control}
            onChange={onChange}
            required={required}
            solid={solid}
            {...rest}
          />
        )}
        {children}
        {helpText && <HelpText text={helpText} />}
        <ErrorMessage name={name} />
      </div>
    )

  return (
    <div className={`row mb-${margin}`}>
      <FormLabel
        label={label}
        labelInfo={labelInfo}
        labelClassName={labelClassName}
        horizontal={horizontal}
        vertical={vertical}
        required={required}
      />
      <Col lg={8} xl={9}>
        {control && (
          <Control
            name={name}
            control={control}
            onChange={onChange}
            required={required}
            solid={solid}
            {...rest}
          />
        )}
        {children}
        {helpText && <HelpText text={helpText} />}
        <ErrorMessage name={name} />
      </Col>
    </div>
  )
}

export default FormGroup
