import _ from 'lodash'
import '@formatjs/intl-listformat/polyfill'
import '@formatjs/intl-listformat/locale-data/pt'

export const getErrorMessage = (error, keysNames = {}) => {
  if (!error) return {}

  let errors = error
  let errorTitle = 'Oops'
  let errorMessage = ''
  let errorStatus = error?.code || _.get(error, 'response.status', 500)

  // Error 😨
  if (error.response) {
    /*
     * The request was made and the server responded with a
     * status code that falls out of the range of 2xx
     */
    errorTitle = error.response.status

    if (
      _.has(error.response, 'data.error_message') &&
      error.response.data.error_message
    ) {
      errorTitle = 'Oops'
      errors = error.response.data.error_message
    } else if (_.has(error.response, 'data.errors'))
      errors = error.response.data.errors
    else if (_.has(error.response, 'data.error'))
      errors = error.response.data.error
    else if (_.has(error.response, 'data.erro'))
      errors = error.response.data.erro
    else if (_.has(error.response, 'data.message.errors'))
      errors = error.response.data.message.errors
    else if (_.has(error.response, 'data.message.error'))
      errors = error.response.data.message.error
    else if (_.has(error.response, 'data.message'))
      errors = error.response.data.message
    else if (_.has(error.response, 'data')) errors = error.response.data
    else errors = 'Ocorreu um erro inesperado'
  } else if (error.request) {
    /*
     * The request was made but no response was received, `error.request`
     * is an instance of XMLHttpRequest in the browser and an instance
     * of http.ClientRequest in Node.js
     */
    errorTitle = 'Sem internet'
    errors =
      'Sua conexão falhou durante o processo e não conseguimos continuar, verifique e tente novamente.'
  } else if (error.message) {
    // Something happened in setting up the request and triggered an Error
    errors = error.message
  }

  let errorsArray = []
  if (_.isArray(errors)) {
    errorsArray = errors.map(error =>
      _.isObject(error)
        ? error.message || error.text || JSON.stringify(error)
        : error
    )
  } else if (_.isObject(errors)) {
    for (const errorKey of Object.keys(errors)) {
      const errorMessages = errors[errorKey]
      const name = _.get(keysNames, errorKey, errorKey)

      if (_.isArray(errorMessages)) {
        try {
          errorsArray.push(
            `${name} ${new Intl.ListFormat('pt-BR').format(errorMessages)}`
          )
        } catch (error) {
          errorsArray.push(`${name} ${JSON.stringify(errorMessages)}`)
        }
      } else {
        errorsArray.push(
          `${name} ${
            _.isString(errorMessages)
              ? errorMessages
              : JSON.stringify(errorMessages)
          }`
        )
      }
    }
  } else {
    errorsArray = _.isArray(errors) ? errors : [errors]
  }

  errorMessage = new Intl.ListFormat('pt-BR').format(errorsArray)

  return {
    status: errorStatus,
    statusCode: errorStatus,
    code: error?.name || errorMessage,
    name: error?.name || errorMessage,
    title: errorTitle.toString(),
    message: errorMessage,
    errors
  }
}

export default getErrorMessage
