import React from 'react'

import dynamic from 'next/dynamic'
import PropTypes from 'prop-types'
import { useFormContext, useController } from 'react-hook-form'
const ReactQuill = dynamic(() => import('react-quill'), { ssr: false })

import getInputClasses from './utils/getInputClasses'
import getRules from './utils/getRules'

/*
 * Quill modules to attach to editor
 * See https://quilljs.com/docs/modules/ for complete options
 */
const defaultModules = {
  toolbar: [
    [{ header: '1' }, { header: '2' }],
    [{ size: ['small', 'normal'] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' }
    ],
    ['link', 'image', 'video'],
    ['clean']
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false
  }
}

const QuillTextEditor = ({
  name,
  control,

  bottomToolbar,
  modules: userModules,

  size,
  solid,
  white,
  flush,
  shadow,
  className,

  defaultValue = '',
  required,
  min,
  max,
  minLength,
  maxLength,
  pattern,
  validate,
  onChange,
  ...rest
}) => {
  const {
    field: { ref, value, onChange: onChangeField, ...inputProps },
    fieldState: { invalid, isTouched, isDirty },
    formState: { errors, touchedFields, dirtyFields }
  } = useController({
    name,
    control,
    rules: getRules({
      required,
      min,
      max,
      minLength,
      maxLength,
      pattern,
      validate
    }),
    defaultValue
  })
  const classes = getInputClasses({
    prefix: 'form-editor',
    size,
    solid,
    white,
    flush,
    shadow,
    className,
    'form-editor-toolbar-bottom': bottomToolbar,
    'h-100': true
  })
  const handleChange = e => {
    onChangeField(e)
    onChange && onChange(e)
  }

  const modules = { ...defaultModules, ...userModules }

  return (
    <ReactQuill
      className={classes}
      onChange={handleChange}
      value={value}
      modules={modules}
      formats={QuillTextEditor.formats}
      isInvalid={!!errors[name] || invalid}
      {...inputProps}
      {...rest}
    />
  )
}

const QuillTextEditorContainer = ({ name, ...rest }) => {
  const { control } = useFormContext()

  return <QuillTextEditor name={name} control={control} {...rest} />
}

QuillTextEditorContainer.propTypes = {
  name: PropTypes.string.isRequired
}

export default QuillTextEditorContainer
