import React from 'react'

import { useFormContext, useController } from 'react-hook-form'
import ReactInputRange from 'react-input-range'
import PropTypes from 'prop-types'

const DEFAULT_CLASS_NAMES = {
  activeTrack: 'input-range__track input-range__track--active',
  disabledInputRange: 'input-range input-range--disabled',
  inputRange: 'input-range',
  labelContainer: 'input-range__label-container',
  maxLabel: 'input-range__label input-range__label--max',
  minLabel: 'input-range__label input-range__label--min',
  slider: 'input-range__slider',
  sliderContainer: 'input-range__slider-container',
  track: 'input-range__track input-range__track--background',
  valueLabel: 'input-range__label input-range__label--value'
}

const InputRange = ({
  name,
  rules,
  defaultValue,
  control,
  classNames,
  onChange,
  minValue,
  maxValue,
  minMax,
  ...rest
}) => {
  const {
    field: { ref, value, onChange: onChangeInput, ...inputProps },
    fieldState: { invalid, isTouched, isDirty },
    formState: { touchedFields, dirtyFields }
  } = useController({
    name,
    control,
    rules,
    defaultValue
  })

  const handleChange = e => {
    onChangeInput(e)
    onChange && onChange(e)
  }

  return (
    <ReactInputRange
      onChange={handleChange}
      value={value ? value : minMax ? { min: minValue, max: maxValue } : 0}
      minValue={minValue}
      maxValue={maxValue}
      className="input-range-cluster"
      classNames={{
        ...DEFAULT_CLASS_NAMES,
        ...classNames
      }}
      {...inputProps}
      {...rest}
    />
  )
}

const InputRangeContainer = ({ name, minValue, maxValue, ...rest }) => {
  const { control } = useFormContext()

  return (
    <InputRange
      name={name}
      control={control}
      minValue={minValue}
      maxValue={maxValue}
      {...rest}
    />
  )
}

InputRangeContainer.propTypes = {
  name: PropTypes.string.isRequired,
  minValue: PropTypes.number.isRequired,
  maxValue: PropTypes.number.isRequired
}

export default InputRangeContainer
