import React from 'react'

import _ from 'lodash'
import { Button } from 'react-bootstrap'

const TimeIntervals = ({ intervals, onChange }) => {
  const getTimeInterval = key => {
    const totalMinutes = key * 30
    const hours = Math.floor(totalMinutes / 60)
      .toString()
      .padStart(2, '0')
    const minutes = (totalMinutes % 60).toString().padStart(2, '0')
    return `${hours}:${minutes}`
  }

  const onToggleIntervals = item => {
    const newIntervalsArray = intervals.includes(item)
      ? intervals.filter(i => i !== item)
      : [...intervals, item]
    onChange(newIntervalsArray)
  }

  return (
    <>
      {_.range(48).map((item, key) => (
        <Button
          key={key}
          className={`${
            !intervals.includes(key) ? 'bg-light' : 'text-dark-100'
          } btn-pill toggle-button mx-2 mb-2`}
          onClick={e => {
            onToggleIntervals(key)
            e.target.blur()
          }}
          variant={intervals.includes(key) ? 'primary' : 'light'}
          active={intervals.includes(key)}
          style={{ width: '100px' }}
        >
          {getTimeInterval(item)}
        </Button>
      ))}
    </>
  )
}

export default TimeIntervals
