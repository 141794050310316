import _ from 'lodash'
import PropTypes from 'prop-types'

import { Select } from '@tootz/react-admin/form'

import allColors from './colors.json'

const colors = _.uniqBy(allColors, 'code')

const Option = props => {
  const {
    data,
    children,
    className,
    cx,
    getStyles,
    isDisabled,
    isFocused,
    isSelected,
    innerRef,
    innerProps
  } = props

  return (
    <div
      ref={innerRef}
      style={getStyles('option', props)}
      className={cx(
        {
          option: true,
          'option--is-disabled': isDisabled,
          'option--is-focused': isFocused,
          'option--is-selected': isSelected
        },
        className
      )}
      {...innerProps}
    >
      <div className="d-flex align-items-center p-2 fw-bold bg-hover-light-primary">
        <div
          style={{ backgroundColor: data.value }}
          className="w-20px h-15px rounded-1 me-2 border border-1 border-gray-200"
        />
        {children}
      </div>
    </div>
  )
}

const MultiValueLabel = props => {
  const { data, children, innerRef, innerProps } = props

  return (
    <div ref={innerRef} {...innerProps}>
      <div className="d-flex align-items-center">
        <div
          style={{ backgroundColor: data.value }}
          className="w-20px h-15px rounded-1 me-2 border border-1 border-gray-200"
        />
        {children}
      </div>
    </div>
  )
}

const ColorsSelector = ({
  name,
  options = colors.map(color => ({
    label: color.name,
    value: color.code
  })),
  ...rest
}) => {
  return (
    <Select
      name={name}
      options={options}
      components={{ Option, MultiValueLabel, SingleValue: MultiValueLabel }}
      {...rest}
    />
  )
}

ColorsSelector.colors = colors
ColorsSelector.getColorByHex = hex => _.find(colors, { code: hex })
ColorsSelector.getColorByName = name => _.find(colors, { name })

ColorsSelector.propTypes = {
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
  ])
}

export default ColorsSelector
