import _ from 'lodash'

import { selfApi } from '@/services/api'

const getConfig = () => {
  return {
    httpClient: selfApi,
    afterSignRedirectUrl: '/',
    styleConfig: {
      size: 'md',
      inputsSize: 'md'
    },
    customNavigation: true,
    reCaptchaVersion: 'v3',
    reCaptchaKey: process.env.NEXT_PUBLIC_RECAPTCHA_KEY,
    disableReCaptcha: ['sign-in'],
    config: {
      signIn: {
        loginLabel: 'E-mail',
        hideSignUpAction: true
      },
      signUp: {
        skipConfirmation: true,
        hideSignInAction: true
      },
      forgotPassword: {},
      resetPassword: {},
      resendConfirmation: {}
    }
  }
}

export default getConfig
