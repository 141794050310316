import React from 'react'

import { ErrorMessage as HookFormErrorMessage } from '@hookform/error-message'
import _ from 'lodash'
import Form from 'react-bootstrap/Form'
import { useFormContext } from 'react-hook-form'

const ErrorMessage = ({ name }) => {
  const {
    formState: { errors }
  } = useFormContext()

  const getMessage = () => {
    const error = _.get(errors, name)

    if (!error) return ''

    let message = ''

    // required, min, max, minLength, maxLength, pattern, validate
    switch (error.type) {
      case 'required':
        message = 'Este campo é obrigatório'
        break
      case 'min':
        message = 'Valor inserido é abaixo do mínimo permitido'
        break
      case 'max':
        message = 'Valor inserido é acima do máximo permitido'
        break
      default:
        message = 'Este campo está inválido, verifique e tente novamente'
    }

    return message
  }

  return (
    <HookFormErrorMessage
      errors={errors}
      name={name}
      render={({ message }) => (
        <Form.Control.Feedback type="invalid" className="d-block">
          {message ? message : getMessage()}
        </Form.Control.Feedback>
      )}
    />
  )
}

export default ErrorMessage
