import axios from 'axios'
import qs from 'qs'

const api = axios.create({
  paramsSerializer: {
    serialize: params => qs.stringify(params, { arrayFormat: 'brackets' })
  }
})

export default api
