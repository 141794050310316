import { validateCPF } from 'validations-br'
import * as yup from 'yup'

const schema = yup.object().shape({
  name: yup.string().nullable().required().label('Nome completo'),
  registration_number: yup
    .string()
    .nullable()
    .test('is-cpf', 'Não é um CPF válido', value => validateCPF(value))
    .required()
    .label('CPF'),
  password: yup
    .string()
    .nullable()
    .min(8, 'Digite no mínimo 8 caracteres')
    .max(128, 'Digite no máximo 128 caracteres')
    .matches(/^\S*$/, 'Senha não pdoeconter espaços')
    .required()
    .label('Senha'),
  password_confirmation: yup
    .string()
    .nullable()
    .oneOf([yup.ref('password'), null], 'Senhas devem estar iguais')
    .required()
    .label('Confirmação de senha')
})
export default schema
