import { useState } from 'react'

import _ from 'lodash'
import PasswordStrengthBar from 'react-password-strength-bar'

import { FormGroup, FormControl, InputGroup } from '@tootz/react-admin/form'

import { useAuthContext } from '../../context'

import { validationSchema } from './validationSchema'

const ResetPasswordFields = () => {
  const { styleConfig } = useAuthContext()
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [showPasswordConfirmation, setShowPasswordConfirmation] =
    useState(false)

  return (
    <>
      <FormGroup
        label="Senha"
        labelClassName="fw-bolder"
        name="password"
        helpText="Use 8 ou mais caracteres."
        vertical
        size={styleConfig.size}
        solid={styleConfig.solid ? true : undefined}
      >
        <InputGroup
          size={styleConfig.size}
          solid={styleConfig.solid ? true : undefined}
        >
          <FormControl
            name="password"
            onChange={e => setPassword(e.target.value)}
            type={showPassword ? 'text' : 'password'}
            min={8}
            max={128}
            size={styleConfig.size}
            solid={styleConfig.solid ? true : undefined}
          />
          <button
            type="button"
            className={`btn btn-icon btn-${styleConfig.btnStyle} bg-hover-light btn-${styleConfig.size}`}
            onClick={() => setShowPassword(prevState => !prevState)}
          >
            <i className={`fad fa-${showPassword ? 'eye' : 'eye-slash'}`} />
          </button>
        </InputGroup>
        <PasswordStrengthBar
          password={password}
          min={8}
          max={128}
          scoreWords={false}
          shortScoreWord={false}
        />
      </FormGroup>
      <FormGroup
        label="Confirme sua senha"
        labelClassName="fw-bolder"
        className="position-relative"
        name="password_confirmation"
        vertical
      >
        <InputGroup
          size={styleConfig.size}
          solid={styleConfig.solid ? true : undefined}
        >
          <FormControl
            name="password_confirmation"
            type={showPasswordConfirmation ? 'text' : 'password'}
            size={styleConfig.size}
            solid={styleConfig.solid ? true : undefined}
            required
          />
          <button
            type="button"
            className={`btn btn-icon btn-${styleConfig.btnStyle} bg-hover-light btn-${styleConfig.size}`}
            onClick={() => setShowPasswordConfirmation(prevState => !prevState)}
          >
            <i
              className={`fad fa-${
                showPasswordConfirmation ? 'eye' : 'eye-slash'
              }`}
            />
          </button>
        </InputGroup>
      </FormGroup>
    </>
  )
}

ResetPasswordFields.validationSchema = validationSchema

export { ResetPasswordFields }
