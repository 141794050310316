import React from 'react'

import PropTypes from 'prop-types'
import InputGroup from 'react-bootstrap/InputGroup'
import ReactDatePicker from 'react-datepicker'
import { useFormContext, useController } from 'react-hook-form'

import getInputClasses from './utils/getInputClasses'
import getRules from './utils/getRules'

const DateInputInner = ({
  name,
  control,

  size,
  solid,
  white,
  flush,
  shadow,

  className,
  defaultValue = '',

  required,

  pattern,
  validate,

  dateFormat: propsDateFormat = 'dd/MM/yyyy',
  withTime,
  onlyTime,
  hideIcon,
  placeholder: propsPlaceholder,

  onChange,
  ...rest
}) => {
  const {
    field: { ref, value, onChange: onChangeField, ...inputProps },
    fieldState: { invalid, isTouched, isDirty },
    formState: { errors, touchedFields, dirtyFields }
  } = useController({
    name,
    control,
    rules: getRules({
      required,
      pattern,
      validate
    }),
    defaultValue
  })
  const classes = getInputClasses({
    size,
    solid,
    white,
    flush,
    shadow,
    className
  })
  const inputGroupClasses = getInputClasses({
    prefix: 'input-group',
    size,
    solid,
    white,
    flush,
    shadow
  })
  let dateFormat = propsDateFormat
  let placeholder = propsPlaceholder || 'Selecione uma data'
  if (onlyTime) {
    dateFormat = 'HH:mm:ss'
    placeholder = propsPlaceholder || 'Selecione uma hora'
  } else if (withTime) {
    dateFormat = 'dd/MM/yyyy HH:mm:ss'
    placeholder = propsPlaceholder || 'Selecione uma data e hora'
  }

  const handleChange = e => {
    onChangeField(e)
    onChange && onChange(e)
  }
  const getDate = dateString => {
    let date
    if (/^\d{4}-\d{2}-\d{2}$/.test(dateString))
      date = new Date(dateString.split('-'))
    else {
      if (dateString === null) date = undefined
      else date = new Date(dateString)
    }

    if (Object.prototype.toString.call(date) === '[object Date]') {
      if (isNaN(date.getTime())) {
        return undefined
      } else {
        return date
      }
    } else {
      return undefined
    }
  }

  return (
    <InputGroup size={size} className={inputGroupClasses}>
      {!hideIcon && (
        <InputGroup.Text>
          <i className="fad fa-calendar"></i>
        </InputGroup.Text>
      )}
      <ReactDatePicker
        selected={getDate(value)}
        onChange={handleChange}
        className={classes}
        isInvalid={!!errors[name] || invalid}
        placeholderText={placeholder}
        dateFormat={dateFormat}
        timeIntervals={15}
        showTimeSelect={withTime || onlyTime}
        showTimeSelectOnly={onlyTime}
        autoComplete="off"
        {...rest}
        {...inputProps}
      />
    </InputGroup>
  )
}

const DateInput = ({ name, ...rest }) => {
  const { control } = useFormContext()

  return <DateInputInner name={name} control={control} {...rest} />
}

DateInput.propTypes = {
  name: PropTypes.string.isRequired
}

export default DateInput
