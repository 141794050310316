import { useEffect, useState } from 'react'

import { DateTime } from 'luxon'
import mixpanel from 'mixpanel-browser'
import { useRouter } from 'next/router'
import { getSession } from 'next-auth/react'
import ReactLoading from 'react-loading'

import { useSession } from '@tootz/react-admin'

import { AppProvider } from '@/domain/App/context'
import { ModalProvider } from '@/domain/App/ModalContext'
import { ClientSubscriptionProvider } from '@/domain/ClientSubscription/context'

import MyApp from './App'
import AuthModal from './Auth/Modal'

const isDev = process.env.NODE_ENV === 'development'

const GuardContainer = ({ isPublic, children }) => {
  const router = useRouter()
  const [showAuthModal, setShowAuthModal] = useState(false)
  const { data: session, status } = useSession()
  const loading = status === 'loading'

  const checkSession = async callback => {
    const session = await getSession()

    if (!session) {
      setShowAuthModal(true)

      return false
    } else return true
  }

  useEffect(() => {
    if (DateTime.now().hour >= 0 && DateTime.now().hour <= 5) {
      if (!router.asPath.includes('manutencao')) router.push('/?manutencao=1')
    }
    // } else if (!loading && !session && !isPublic) checkSession()

    // Mixpanel: Identify user
    if (!isDev && session) {
      mixpanel.identify(session.user?.id)

      mixpanel.people.set({
        $name: session.user?.name,
        $email: session.user?.email
      })
    }
  }, [loading, session, isPublic])

  if (router.pathname.startsWith('/auth/')) return <>{children}</>

  if (loading)
    return (
      <div className="d-flex justify-content-center align-items-center vh-100 vw-100 bg-primary">
        <ReactLoading type="bubbles" color="#fff" height={50} width={50} />
      </div>
    )

  return (
    <MyApp>
      <ModalProvider>
        <AppProvider>
          <ClientSubscriptionProvider>
            {children}
            {showAuthModal && (
              <AuthModal onSignIn={() => setShowAuthModal(false)} />
            )}
          </ClientSubscriptionProvider>
        </AppProvider>
      </ModalProvider>
    </MyApp>
  )
}

export default GuardContainer
