import axios from 'axios'
import _ from 'lodash'
import { signOut, getSession } from 'next-auth/react'
import qs from 'qs'

import { getErrorMessage, showErrorAlert, getQuery } from '@tootz/react-admin'

const api = axios.create({
  // withCredentials: true,
  baseURL: process.env.NEXT_PUBLIC_BACKEND_API_BASE_URL,
  headers: { accept: 'application/json' },
  paramsSerializer: {
    serialize: params => qs.stringify(params, { arrayFormat: 'brackets' })
  }
})

api.interceptors.request.use(async config => {
  const session = await getSession()

  if (session?.accessToken !== undefined)
    config.headers.Authorization = `Bearer ${session.accessToken}`

  return config
})

api.interceptors.response.use(
  response => response,
  async error => {
    if (401 === _.get(error, 'response.status')) {
      try {
        return signOut()
      } catch (error) {
        return Promise.reject(error)
      }
    } else return Promise.reject(error)
  }
)

// Self API
const selfApi = axios.create({
  baseURL: process.env.NEXT_PUBLIC_SELF_API_BASE_URL,
  headers: { accept: 'application/json' },
  paramsSerializer: {
    serialize: params => qs.stringify(params, { arrayFormat: 'brackets' })
  }
})

// Search API
const searchApi = axios.create({
  baseURL: process.env.NEXT_PUBLIC_SEARCH_API_BASE_URL,
  headers: { accept: 'application/json' },
  paramsSerializer: {
    serialize: params => qs.stringify(params, { arrayFormat: 'brackets' })
  }
})

export { api, selfApi, getErrorMessage, showErrorAlert, getQuery, searchApi }
