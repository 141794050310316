import React, { useEffect, memo } from 'react'

import _ from 'lodash'
import { useFormContext } from 'react-hook-form'

import { useResourceRecordContext } from '../../context/ResourceRecordContext'

const ReferenceFields = ({ value = [] }) => {
  const { record } = useResourceRecordContext()
  const { register, setValue, getValues } = useFormContext()

  const prevState = getValues('reference_fields')

  return (
    <>
      {value
        .filter(w => w => w != null && w != '' && w != undefined)
        .map((v, index) => (
          <div key={`reference-field-${v}`}>
            {_.has(record, `${v}.id`) && (
              <input
                {...register(`${v}.id`)}
                type="hidden"
                value={_.get(record, `[${v}].id`)}
              />
            )}
            <input
              {...register(
                `reference_fields[${
                  (prevState ? prevState.length : 0) + index
                }]`
              )}
              type="hidden"
              value={v}
            />
          </div>
        ))}
    </>
  )
}
export default ReferenceFields
