import { useState } from 'react'

import { useFormContext } from 'react-hook-form'
import toastr from 'toastr'

import FormGroup from './FormGroup'
import FormControl from './FormControl'

import { useResourceRecordContext } from '../../context/ResourceRecordContext'

const PaymentCouponField = ({
  pathToCheck = code => `/coupons/${code}/check`
}) => {
  const { dataProvider } = useResourceRecordContext()
  const { setValue, getValues } = useFormContext()

  const [userHasCouponCode, setUserHasCouponCode] = useState(false)
  const [applyingCouponCode, setApplyingCouponCode] = useState(false)
  const [appliedCouponCode, setAppliedCouponCode] = useState(undefined)

  const undoCouponCode = () => {
    setUserHasCouponCode(false)
    setApplyingCouponCode(false)
    setAppliedCouponCode(undefined)
    setValue('coupon_code', null)
    setValue('is_valid_coupon', null)
    setValue('coupon', null)
  }

  const applyCouponCode = () => {
    const code = getValues('coupon_code')
    setAppliedCouponCode(undefined)
    setApplyingCouponCode(true)

    dataProvider
      .get(pathToCheck(code))
      .then(response => {
        setValue('is_valid_coupon', true)
        setValue('coupon', response.data.record)
        setAppliedCouponCode(code)
        toastr.success('Cupom aplicado!')
      })
      .catch(error => {
        toastr.error(error.response.data.error)
        undoCouponCode()
      })
      .finally(() => setApplyingCouponCode(false))
  }

  if (userHasCouponCode)
    return (
      <>
        <div className="d-flex align-items-end gap-3 mb-3">
          <div className="flex-fill">
            <FormGroup
              name="coupon_code"
              label="Insira o código do cupom"
              control={FormControl}
              readOnly={!!applyingCouponCode}
              vertical
              disabled={!!appliedCouponCode}
            />
            <FormControl type="hidden" name="is_valid_coupon" />
            <FormControl type="hidden" name="coupon" />
          </div>
          <div className="d-flex gap-1 mb-6">
            <button
              type="button"
              className={`btn btn-${appliedCouponCode ? 'success' : 'dark'}`}
              onClick={applyCouponCode}
              disabled={applyingCouponCode || !!appliedCouponCode}
            >
              {!!appliedCouponCode && (
                <i className="fas fa-check me-1 mt-n1"></i>
              )}
              {applyingCouponCode
                ? 'Aplicando...'
                : !!appliedCouponCode
                ? 'Cupom aplicado'
                : 'Aplicar'}
            </button>
            <button
              type="button"
              className="btn btn-outline-secondary"
              onClick={undoCouponCode}
            >
              Desfazer
            </button>
          </div>
        </div>
      </>
    )

  return (
    <div className="mb-3">
      <span
        className="btn btn-link text-primary"
        onClick={() => setUserHasCouponCode(true)}
      >
        Tenho um cupom de desconto
      </span>
    </div>
  )
}

export default PaymentCouponField
