import { useEffect } from 'react'

import _ from 'lodash'
import PropTypes from 'prop-types'
import { useController, useFormContext } from 'react-hook-form'
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
  getLatLng
} from 'react-google-places-autocomplete'

import getInputClasses from './utils/getInputClasses'
import getRules from './utils/getRules'

const GooglePlacesFields = ({
  name,
  control,
  latName = 'lat',
  lngName = 'lng',
  size,
  solid,
  white,
  flush,
  shadow,
  className,
  defaultValue = '',
  required,
  min,
  max,
  minLength,
  maxLength,
  pattern,
  validate,
  onChange,
  ...rest
}) => {
  const { register, setValue, getValues } = useFormContext()
  const {
    field: { ref, value, onChange: onChangeField, ...inputProps },
    fieldState: { invalid, isTouched, isDirty },
    formState: { errors, touchedFields, dirtyFields }
  } = useController({
    name,
    control,
    rules: getRules({
      required,
      min,
      max,
      minLength,
      maxLength,
      pattern,
      validate
    }),
    defaultValue
  })
  const classes = getInputClasses(
    {
      prefix: 'form-react-select',
      size,
      solid,
      white,
      flush,
      shadow,
      className
    },
    !!errors[name] || invalid
  )

  const handleChange = async e => {
    onChangeField(e)

    try {
      const geocodeRes = await geocodeByPlaceId(_.get(e, 'value.place_id'))
      const geocode = geocodeRes[0]
      const { address_components } = geocode
      const zipCode = address_components.find(ac =>
        ac.types.includes('postal_code')
      )
      const country = address_components.find(ac =>
        ac.types.includes('country')
      )
      const state = address_components.find(ac =>
        ac.types.includes('administrative_area_level_1')
      )
      const city = address_components.find(ac =>
        ac.types.includes('administrative_area_level_2')
      )
      const neighbourhood = address_components.find(ac =>
        ac.types.includes('sublocality_level_1')
      )
      const street = address_components.find(ac => ac.types.includes('route'))
      const number = address_components.find(ac =>
        ac.types.includes('street_number')
      )

      const latLng = await getLatLng(geocode)

      setValue(latName, latLng.lat)
      setValue(lngName, latLng.lng)

      onChange &&
        onChange(e, {
          name: _.get(e, 'value.structured_formatting.main_text', e?.label),
          zipCode: zipCode?.long_name,
          country,
          state,
          city,
          neighbourhood,
          street,
          number,
          ...latLng
        })
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    register(latName)
    register(lngName)
  }, [])

  return (
    <GooglePlacesAutocomplete
      apiOptions={{
        language: 'PT_BR',
        region: 'BR',
        componentRestrictions: { country: 'BR' }
      }}
      selectProps={{
        value: _.isEmpty(value) ? null : value,
        onChange: handleChange,
        classNamePrefix: 'form-react-select',
        className: classes,
        ...rest
      }}
      {...inputProps}
      {...rest}
    />
  )
}

const GooglePlacesFieldsContainer = ({ name, ...rest }) => {
  const { control } = useFormContext()

  return <GooglePlacesFields name={name} control={control} {...rest} />
}

GooglePlacesFieldsContainer.propTypes = {
  name: PropTypes.string.isRequired
}

export default GooglePlacesFieldsContainer
