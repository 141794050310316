import * as yup from 'yup'

export const validationSchema = yup.object().shape({
  password: yup
    .string()
    .nullable()
    .min(8, 'Digite no mínimo 8 caracteres')
    .max(128, 'Digite no máximo 128 caracteres')
    .matches(/^\S*$/, 'Senha não pdoeconter espaços')
    .required()
    .label('Senha'),
  password_confirmation: yup
    .string()
    .nullable()
    .oneOf([yup.ref('password'), null], 'Senhas devem estar iguais')
    .required()
    .label('Confirmação de senha')
})
