const Alert = ({
  title,
  subtitle,
  icon = 'fad fa-square-exclamation',
  variant = 'primary',
  className = '',
  noBorder,
  children
}) => {
  return (
    <div
      className={`alert alert-${variant} d-flex align-items-center p-5 ${
        noBorder ? 'border-0' : ''
      } ${className}`}
    >
      <i className={`${icon} text-${variant} me-4 fs-2hx flex-shrink-0`} />
      <div className="d-flex flex-column flex-grow-1">
        {title && <h4 className={`mb-1 text-${variant}`}>{title}</h4>}
        {subtitle && <span>{subtitle}</span>}
        {children}
      </div>
    </div>
  )
}

export default Alert
