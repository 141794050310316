import React, { useEffect, useState } from 'react'

import _ from 'lodash'
import PropTypes from 'prop-types'
import { useDropzone } from 'react-dropzone'
import { useFormContext, useController, useWatch } from 'react-hook-form'
import { v4 as uuidv4 } from 'uuid'
import toastr from 'toastr'
import axios from 'axios'

import { useDataProviderContext } from '@tootz/react-admin'

import { humanFileSize } from '../../services/Format/Size'

import getInputClasses from './utils/getInputClasses'
import getRules from './utils/getRules'

const cleanFilename = filename => {
  let [name, ...parts] = filename.split('.')
  let extension = ''
  if (parts.length >= 1) extension = '.' + parts.pop()
  const cleanedName = name.trim().replace(/ /g, '_')
  const cleanedNameWithoutSpecialChars = cleanedName.replace(/[^\w\s-]/g, '')
  const cleanedFilename = `${cleanedNameWithoutSpecialChars}${extension}`

  return cleanedFilename
}

const DropdzoneFieldFile = ({
  id,
  single,
  name,
  size,
  preview,
  file,
  _destroy,
  onChange,
  onRemove,
  onError
}) => {
  const { httpClient } = useDataProviderContext()
  const [uploading, setUploading] = useState(false)
  const [onUploadProgress, setUploadProgress] = useState(0)

  const uploadFile = async file => {
    setUploading(true)

    try {
      /**
       * Get the file request from the API
       */
      const newFileName = cleanFilename(file.name)
      const resGetUploadUrl = await httpClient.get('/medias/upload_url', {
        params: {
          filename: newFileName
        }
      })
      const { put_url: putUrl, get_url: getUrl } = _.get(
        resGetUploadUrl,
        'data.record'
      )

      /**
       * Upload the file to the S3 bucket
       */
      setUploadProgress(0)

      let formData = new FormData()
      formData.append('file', file)

      await axios.put(putUrl, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          filename: newFileName
        },
        onUploadProgress: event => {
          let progress = Math.round((event.loaded * 100) / event.total)

          setUploadProgress(progress)
        }
      })

      setUploadProgress(100)

      /**
       * Create a media record in the API
       */
      const resMedia = await httpClient.post('/medias', {
        record: {
          remote_file_url: getUrl,
          name: newFileName,
          size: file.size,
          content_type: file.type
        }
      })
      const { id: mediaId, file: mediaFile } = _.get(resMedia, 'data.record')

      onChange(mediaId, { file: mediaFile })
    } catch (error) {
      onError && onError(error)
    }

    setUploading(false)
  }

  useEffect(() => {
    if (file && !uploading && !file?.url) uploadFile(file)
  }, [file])

  return (
    <div
      className={`dz-preview dz-file-preview dz-processing dz-error--disabled dz-complete ${
        _destroy ? 'd-none' : ''
      }`}
    >
      <div className="dz-image">
        {preview && <img alt={name} src={preview} />}
      </div>
      {uploading ? (
        <div className="dz-progress">
          <span
            className="dz-upload"
            style={{ width: `${onUploadProgress}%` }}
          />
        </div>
      ) : (
        <div className="dz-details">
          <div className="dz-size">
            <span>
              <strong>{humanFileSize(size)}</strong>
            </span>
          </div>
          <div className="dz-filename">
            <span data-dz-name>{name}</span>
          </div>
        </div>
      )}

      {/*
        <div className="dz-error-message">
          <span data-dz-errormessage="">Server responded with 0 code.</span>
        </div>
        <div className="dz-success-mark"> a </div>
        <div className="dz-error-mark"> b </div>
      */}
      <button
        type="button"
        className="dz-remove border-0 w-30px h-30px text-hover-danger"
        onClick={e => {
          e.stopPropagation()
          e.preventDefault()

          onRemove()
        }}
      >
        <i className="fad fa-times text-hover-danger"></i>
      </button>
    </div>
  )
}

const DropzoneField = ({
  name,
  control,
  multiple = false,
  options = {
    maxFiles: 10
  },
  accept = '.png, .jpg, .jpeg, .gif',
  mediaApiScope,
  size,
  solid,
  white,
  flush,
  shadow,
  className,
  defaultValue = '',
  required,
  min,
  max,
  srcName: _srcName,
  minLength,
  maxLength,
  pattern,
  validate,
  onChange,
  ...rest
}) => {
  const { getValues } = useFormContext()
  const {
    field: { ref, value, onChange: onChangeField, ...inputProps },
    fieldState: { invalid, isTouched, isDirty },
    formState: { errors, touchedFields, dirtyFields }
  } = useController({
    name,
    control,
    rules: getRules({
      required,
      min,
      max,
      minLength,
      maxLength,
      pattern,
      validate
    }),
    defaultValue
  })
  const classes = getInputClasses({
    prefix: 'dropzone',
    size,
    solid,
    white,
    flush,
    shadow,
    className,
    'dz-clickable': true,
    'dz-started': true
  })
  const [files, setFiles] = useState([])
  const { getRootProps, getInputProps } = useDropzone({
    ...options,
    maxFiles: multiple ? options?.maxFiles || 10 : 1,
    onDrop: acceptedFiles => {
      if (!multiple && files.length > 0) return

      const newFiles = acceptedFiles.map(file => {
        return {
          id: uuidv4(),
          file: file,
          size: file.size,
          name: file.name,
          content_type: file.type,
          preview: file.type.includes('image') && URL.createObjectURL(file)
        }
      })

      setFiles(newFiles)
    }
  })

  const handleError = (file, index) => {
    const filesCopy = files.slice()
    filesCopy.splice(index, 1)

    setFiles(filesCopy)

    toastr.error('Erro ao fazer upload do arquivo', 'Erro')
  }
  const handleChange = (mediaId, mediaFile) => {
    const newValue = multiple ? [...value, mediaId] : mediaId

    onChangeField(newValue)
    onChange && onChange(newValue, mediaFile)
  }
  const handleRemove = (file, index) => {
    const filesCopy = files.slice()

    filesCopy.splice(index, 1)

    // const fileCopy = _.get(filesCopy, index)

    // if (_.has(fileCopy, 'id')) {
    //   fileCopy._destroy = true
    //   filesCopy.splice(index, 1, fileCopy)
    //  } else filesCopy.splice(index, 1)

    if (multiple) {
      onChangeField(filesCopy.map(file => file.id))
      onChange && onChange(filesCopy.map(file => file.id))
    } else {
      onChangeField(null)
      onChange && onChange(null)
    }
    setFiles(filesCopy)
  }

  const srcName = _srcName
    ? _srcName
    : multiple
    ? name.replace('_ids', '') + 's'
    : name.replace('_id', '')

  const record = useWatch({
    name: srcName
  })
  useEffect(() => {
    let newFiles = []
    const srcOnRecord = getValues(srcName)

    if (srcOnRecord) {
      if (multiple) {
        newFiles = srcOnRecord.map(media => ({
          ...media,
          preview:
            media?.content_type?.includes('image') &&
            (media?.url || media?.file?.url)
        }))
      } else {
        newFiles.push({
          ...srcOnRecord,
          preview:
            srcOnRecord?.content_type?.includes('image') &&
            (srcOnRecord?.url || srcOnRecord?.file?.url)
        })
      }

      setFiles(newFiles)
    }
  }, [name, record])

  useEffect(() => {
    return () =>
      files.forEach(file => file?.preview && URL.revokeObjectURL(file.preview))
  }, [])

  return (
    <div className={classes} {...getRootProps()}>
      <input {...getInputProps()} />

      {files.length == 0 && (
        <div className="dz-message needsclick d-flex align-items-center p-2">
          <i className="fad fa-arrow-up-from-bracket text-primary fs-2x"></i>
          <div className="ms-4">
            <span className="fs-6 fw-bold text-gray-900 d-block">
              {multiple
                ? 'Solte os arquivos aqui para fazer upload'
                : 'Solte o arquivo aqui para fazer upload'}
            </span>
            <span className="fs-7 fw-semibold text-primary opacity-75">
              {multiple
                ? 'Faça upload de até 10 arquivos'
                : 'Ou clique para selecionar'}
            </span>
          </div>
        </div>
      )}

      {files.map((file, index) => (
        <DropdzoneFieldFile
          id={file.id}
          key={`file-${index}`}
          {...file}
          onError={() => handleError(file, index)}
          onChange={handleChange}
          onRemove={() => handleRemove(file, index)}
          single={!multiple}
        />
      ))}
    </div>
  )
}

const DropzoneFieldContainer = ({ name, ...rest }) => {
  const { control } = useFormContext()

  return <DropzoneField name={name} control={control} {...rest} />
}

DropzoneFieldContainer.propTypes = {
  name: PropTypes.string.isRequired
}

export default DropzoneFieldContainer
