import { createContext, useContext, useState } from 'react'

import _ from 'lodash'

const ModalContext = createContext()

const useModalContext = () => useContext(ModalContext)

const ModalProvider = ({ children }) => {
  const [_showModal, _setShowModal] = useState(false)
  const showModal = _setShowModal

  const Modal = ({ component, onHide, onSave, ...rest }) => {
    const Component = component

    return (
      <Component
        {...rest}
        show={_showModal}
        onHide={() => {
          _setShowModal(false)
          onHide && onHide()
        }}
        onSave={record => {
          _setShowModal(false)
          onSave && onSave(record)
        }}
      />
    )
  }

  return (
    <ModalContext.Provider
      value={{
        showModal,
        modalIsOpen: !!_showModal
      }}
    >
      {children}
      {_showModal && <Modal {..._showModal} />}
    </ModalContext.Provider>
  )
}

const withModal = Component => {
  const WrappedComponent = props => {
    return (
      <ModalProvider>
        <Component {...props} />
      </ModalProvider>
    )
  }

  WrappedComponent.displayName = `withModal(${
    Component.displayName || Component.name
  })`

  return WrappedComponent
}

export { useModalContext, ModalProvider, withModal }
