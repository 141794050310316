import React, { Fragment } from 'react'

import PropTypes from 'prop-types'
import { useController, useFormContext } from 'react-hook-form'

import getInputClasses from './utils/getInputClasses'
import getRules from './utils/getRules'

const PaymentMethodField = ({
  name,
  control,
  options = [
    { value: 'pix', description: 'PIX' },
    { value: 'bank_slip', description: 'Boleto à vista' },
    { value: 'credit_card', description: 'Cartão de crédito' },
    { value: 'bank_slip_in_installments', description: 'Boleto parcelado' }
  ],
  vertical,
  size,
  solid,
  white,
  flush,
  shadow,
  className,
  defaultValue = '',
  required,
  pattern,
  validate,
  onChange,
  ...rest
}) => {
  const {
    field: { ref, value, onChange: onChangeField, ...inputProps },
    fieldState: { invalid, isTouched, isDirty },
    formState: { errors, touchedFields, dirtyFields }
  } = useController({
    name,
    control,
    rules: getRules({
      required,
      pattern,
      validate
    }),
    defaultValue
  })
  const classes = getInputClasses({
    prefix: 'form-payment-methods',
    size,
    solid,
    white,
    flush,
    shadow,
    className,
    ['d-flex flex-wrap']: true
  })

  const handleChange = (value, bool) => {
    const selected = bool ? value : null
    onChangeField(selected)
    onChange && onChange(selected)
  }

  return (
    <div className={classes} role="group">
      {options.map(option => (
        <Fragment key={`${name}_${option.value}`}>
          <input
            id={`${name}_${option.value}`}
            name={name}
            value={option.value}
            type="radio"
            className="btn-check"
            autoComplete="off"
            checked={option.value.toString() === value?.toString()}
            onChange={e => handleChange(option.value, e.target.checked)}
          />
          <label
            className={`btn btn-outline btn-outline-dashed btn-outline-default rounded-2 py-5 px-6 d-flex align-items-center ${
              vertical ? 'flex-grow-1 w-100 mb-3' : 'me-3 mb-3'
            }`}
            htmlFor={`${name}_${option.value}`}
          >
            <div className="w-40px d-flex align-items-center justify-content-center me-4">
              <i className={`${option.icon} fs-2qx`}></i>
            </div>
            <div className="d-block fw-bold text-start">
              <span className={'text-dark fw-bolder d-block fs-4 d-block'}>
                {option.label}
              </span>
              {option.description && (
                <span
                  className={`text-muted fw-bold fs-4 m-0  ${
                    vertical ? '' : 'mw-200px'
                  } d-block`}
                >
                  {option.description}
                </span>
              )}
            </div>
          </label>
        </Fragment>
      ))}
    </div>
  )
}

const PaymentMethodFieldContainer = ({ name, ...rest }) => {
  const { control } = useFormContext()

  return <PaymentMethodField name={name} control={control} {...rest} />
}

PaymentMethodFieldContainer.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired
}

export default PaymentMethodFieldContainer
