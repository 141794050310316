import { Fragment } from 'react'

import _ from 'lodash'
import PropTypes from 'prop-types'
import { useController, useFormContext } from 'react-hook-form'

import getInputClasses from './utils/getInputClasses'
import getRules from './utils/getRules'

const BtnGroupRadio = ({
  name,
  control,
  options,
  asCards,
  renderOption,
  wrapperClassName,
  size,
  solid,
  white,
  flush,
  shadow,
  variant,
  className,
  defaultValue = '',
  required,
  pattern,
  validate,
  onChange,
  ...rest
}) => {
  const {
    field: { ref, value, onChange: onChangeField, ...inputProps },
    fieldState: { invalid, isTouched, isDirty },
    formState: { errors, touchedFields, dirtyFields }
  } = useController({
    name,
    control,
    rules: getRules({
      required,
      pattern,
      validate
    }),
    defaultValue
  })
  const classes = getInputClasses({
    prefix: `form-btn-group-radio${asCards ? '-as-cards' : ''}`,
    size,
    solid,
    white,
    flush,
    shadow,
    className,
    [`form-btn-group-radio-${variant}`]: !!variant,
    ['btn-group']: !asCards,
    ['row g-4']: asCards
  })

  const handleChange = (value, bool, option) => {
    const selected = bool ? value : null
    onChangeField(selected)
    onChange && onChange(selected, option)
  }

  return (
    <div className={classes} role="group">
      {options.map(option => {
        const isChecked = option.value.toString() === value?.toString()

        const Input = () => (
          <input
            id={`${name}_${option.value}`}
            name={name}
            value={option.value}
            type="radio"
            className="btn-check"
            autoComplete="off"
            checked={isChecked}
            onChange={e => handleChange(option.value, e.target.checked, option)}
            disabled={!!option.disabled}
            {...rest}
          />
        )

        if (asCards)
          return (
            <div
              key={`${name}_${option.value}`}
              className={`col-md-${options.length > 2 ? 4 : 6}`}
            >
              <Input />
              <label
                className={
                  wrapperClassName
                    ? wrapperClassName
                    : 'btn btn-outline btn-outline-dashed btn-active-light-primary p-7 d-flex align-items-center h-100'
                }
                htmlFor={`${name}_${option.value}`}
              >
                {renderOption ? (
                  renderOption(option, isChecked)
                ) : (
                  <>
                    <i
                      className={`${option.icon} fs-2qx  ${
                        option.label || option.description ? 'me-4' : ''
                      }`}
                      style={option.iconStyle}
                    ></i>
                    <div className="d-block fw-bold text-start">
                      <span
                        className={`text-dark fw-bold d-block fs-4 ${
                          option.description ? 'mb-1' : 'mb-0'
                        }`}
                      >
                        {option.label}
                        {option.badge}
                      </span>
                      {option.description && (
                        <span className="text-muted fw-bold fs-7 mw-200px d-block">
                          {option.description}
                        </span>
                      )}
                    </div>
                  </>
                )}
              </label>
            </div>
          )
        return (
          <Fragment key={`${name}_${option.value}`}>
            <Input />
            <label
              className={
                wrapperClassName ? wrapperClassName : 'btn btn-active-primary'
              }
              htmlFor={`${name}_${option.value}`}
            >
              {renderOption ? renderOption(option, isChecked) : option.label}
            </label>
          </Fragment>
        )
      })}
    </div>
  )
}

const BtnGroupRadioContainer = ({ name, ...rest }) => {
  const { control } = useFormContext()

  return <BtnGroupRadio name={name} control={control} {...rest} />
}

BtnGroupRadioContainer.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired
}

export default BtnGroupRadioContainer
