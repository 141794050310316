import { createContext, useContext, useState, useEffect } from 'react'

import { useCookies } from 'react-cookie'
import { encode, decode } from 'js-base64'
import { useRouter } from 'next/router'
import { getProviders, getSession, signIn } from 'next-auth/react'
import _ from 'lodash'
import Swal from 'sweetalert2'
// import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

import { showErrorAlert } from '@tootz/react-admin'
import { BlockUI } from '@tootz/react-admin/components'

const AuthContext = createContext(null)

const useAuthContext = () => useContext(AuthContext)

const PROVIDERS_ENABLED = false
const SIGN_IN = 'sign-in'
const SIGN_UP = 'sign-up'
const FORGOT_PASSWORD = 'forgot-password'
const RESET_PASSWORD = 'reset-password'
const CONFIRMATION = 'confirmation'
const RESEND_CONFIRMATION = 'resend-confirmation'
const DEFAULT_STYLE_CONFIG = {
  size: 'lg',
  solid: false,
  shadow: false,
  transparent: false
}
const EMAIL_COOKIE_KEY = 'ttz.ra-auth.e'
const PASSWORD_COOKIE_KEY = 'ttz.ra-auth.p'

const getStyleConfig = userStyleConfig => {
  let newConfig = { ...DEFAULT_STYLE_CONFIG, ...userStyleConfig }

  newConfig.style = 'outline'
  newConfig.btnStyle = 'outline'

  if (newConfig.solid) {
    newConfig.style = 'solid'
    newConfig.btnStyle = 'light'
  } else if (newConfig.shadow) {
    newConfig.style = 'shadow'
    newConfig.btnStyle = 'shadow'
  } else if (newConfig.transparent) {
    newConfig.style = 'transparent'
    newConfig.btnStyle = 'transparent'
  }

  return newConfig
}

const AuthProvider = ({
  afterSignInRedirectUrl = '/',
  customNavigation,
  httpClient,
  initialAction = 'sign-in',
  checkSessionOnAuthPages = false,
  disableReCaptcha = false,
  reCaptchaVersion = 'v3',
  reCaptchaKey = process.env.NEXT_PUBLIC_RECAPTCHA_KEY,
  styleConfig: userStyleConfig,
  enabledProviders = [],
  callbackUrl: propsCallbackUrl,
  children,
  ...rest
}) => {
  const router = useRouter()
  const { callbackUrl: callbackUrlQuery, email: emailQuery } = router.query
  let callbackUrl = callbackUrlQuery || afterSignInRedirectUrl
  if (propsCallbackUrl) callbackUrl = propsCallbackUrl
  const [isLoading, setIsLoading] = useState(false)
  const [providers, setProviders] = useState([])
  const [_filled, setFilled] = useState(false)
  const getDefaultValues = ({ ignoreFilled = false } = {}) => {
    const defaultValues = { email: currentEmail }

    if (ignoreFilled) return defaultValues

    if (_filled) {
      setFilled(false)

      return defaultValues
    }

    return {}
  }
  const [action, _setAction] = useState(initialAction || 'sign-in')
  const [lastAction, setLastAction] = useState('sign-in')
  const [cookies, setCookie, removeCookie] = useCookies([
    EMAIL_COOKIE_KEY,
    PASSWORD_COOKIE_KEY
  ])
  const currentEmail = cookies[EMAIL_COOKIE_KEY]
  const currentPassword =
    cookies[PASSWORD_COOKIE_KEY] && decode(cookies[PASSWORD_COOKIE_KEY])
  const setCurrentEmail = email => {
    if (
      typeof email != 'undefined' &&
      email != 'undefined' &&
      email !== currentEmail
    )
      setCookie(EMAIL_COOKIE_KEY, email, { path: '/' })
  }
  const setCurrentPassword = password =>
    password && setCookie(PASSWORD_COOKIE_KEY, encode(password))
  const styleConfig = getStyleConfig(userStyleConfig)
  const reCaptchaEnabled =
    !!reCaptchaKey &&
    !(_.isArray(disableReCaptcha)
      ? disableReCaptcha.includes(action)
      : disableReCaptcha)
  const useReCaptchaV3 = reCaptchaEnabled && reCaptchaVersion == 'v3'
  const useReCaptchaV2 = reCaptchaEnabled && reCaptchaVersion == 'v2'

  const setAction = newAction => {
    setLastAction(action)
    _setAction(newAction)
  }
  const backAction = () => {
    _setAction(lastAction)
    setLastAction(null)
  }
  const goToSignIn = (props = {}) => {
    const { filled } = props
    if (filled) setFilled(filled)

    setAction(SIGN_IN)

    if (!customNavigation) router.push('/auth/sign-in')
  }
  const goToSignUp = () => {
    setAction(SIGN_UP)

    if (!customNavigation) router.push('/auth/sign-up')
  }
  const goToForgotPassword = (props = {}) => {
    const { email } = props
    setCurrentEmail(email)
    setFilled(true)
    setAction(FORGOT_PASSWORD)

    if (!customNavigation) router.push('/auth/forgot-password')
  }
  const goToResetPassword = (props = {}) => {
    const { email } = props
    setCurrentEmail(email)
    setAction(RESET_PASSWORD)

    if (!customNavigation) router.push('/auth/reset-password')
  }
  const goToConfirmation = ({ email, password = '' }) => {
    setCurrentEmail(email)
    setCurrentPassword(password)
    setAction(CONFIRMATION)

    if (!customNavigation)
      router.push(`/auth/confirmation?email=${encodeURIComponent(email)}`)
  }
  const goToResendConfirmation = () => {
    setAction(RESEND_CONFIRMATION)

    if (!customNavigation) router.push('/auth/resend-confirmation')
  }
  const handleSignIn = async ({ login, password }) => {
    setIsLoading(true)

    const { error, ok, status, url } = await signIn('domain-sign-in', {
      login: login,
      password: password,
      callbackUrl,
      redirect: false
    })

    setIsLoading(false)

    return { error, ok, status, url }
  }
  const onRequestPasswordReset = async data => {
    setIsLoading(true)
    const { email } = data

    setCurrentEmail(email)

    httpClient
      .post('/auth/reset-password', {
        email
      })
      .then(goToResetPassword)
      .catch(showErrorAlert)
      .finally(() => setIsLoading(false))
  }
  const onRequestConfirmationToken = async data => {
    setIsLoading(true)
    const { email } = data

    setCurrentEmail(email)

    httpClient
      .post('/auth/request-confirmation-token', {
        email
      })
      .then(goToConfirmation)
      .catch(showErrorAlert)
      .finally(() => setIsLoading(false))
  }

  const checkSession = async () => {
    const session = await getSession()

    if (session) {
      Swal.fire({
        icon: 'success',
        title: 'Sessão ativa',
        text: 'Você já entrou na plataforma, podemos te redirecionar?',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: 'Sim!',
        denyButtonText: 'Não, quero sair',
        reverseButtons: true
      }).then(({ isConfirmed }) => {
        if (isConfirmed) {
          router.push('/')
        } else signOut()
      })
    }
  }

  const checkError = () => {
    const { error: errorCode } = router.query

    if (errorCode) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: errorCode
      })
    }
  }

  useEffect(() => {
    if (checkSessionOnAuthPages) checkSession()
  }, [checkSessionOnAuthPages])

  const loadProviders = async () => {
    if (enabledProviders && enabledProviders.length) {
      let providers = await getProviders()
      providers = _.pick(providers, enabledProviders)
      setProviders(providers)
    }
  }

  useEffect(() => {
    loadProviders()
  }, [enabledProviders])

  useEffect(() => {
    checkError()
  }, [])

  useEffect(() => {
    setIsLoading(false)
  }, [action])

  useEffect(() => {
    if (emailQuery && currentEmail !== emailQuery) setCurrentEmail(emailQuery)
  }, [emailQuery])

  return (
    <AuthContext.Provider
      value={{
        afterSignInRedirectUrl,
        providers,
        httpClient,
        isLoading,
        setIsLoading,
        styleConfig,

        callbackUrl,

        getDefaultValues,

        currentEmail,
        currentPassword,

        action,
        setAction,
        backAction,
        goToSignIn,
        goToSignUp,
        goToForgotPassword,
        goToResetPassword,
        goToConfirmation,
        goToResendConfirmation,

        setCurrentEmail,
        setCurrentPassword,

        reCaptchaEnabled,
        useReCaptchaV3,
        useReCaptchaV2,
        reCaptchaKey,

        handleSignIn,
        onRequestPasswordReset,
        onRequestConfirmationToken,

        PROVIDERS_ENABLED,

        ...rest
      }}
    >
      {/* <ConditionalWrapper
        condition={useReCaptchaV3}
        wrapper={children => (
          <GoogleReCaptchaProvider
            reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_KEY}
            language="PT_BR"
          >
            {children}
          </GoogleReCaptchaProvider>
        )}
      > */}
      <BlockUI blocking={isLoading} className="d-flex flex-column h-100">
        {children}
      </BlockUI>
      {/* </ConditionalWrapper> */}
    </AuthContext.Provider>
  )
}

const withAuth = (Component, propConfig) => {
  const WrappedComponent = componentProps => {
    const config = _.isFunction(propConfig)
      ? propConfig(componentProps)
      : propConfig

    return (
      <AuthProvider {...componentProps} {...config}>
        <Component {...componentProps} />
      </AuthProvider>
    )
  }

  WrappedComponent.displayName = `withAuth(${
    Component.displayName || Component.name
  })`

  return WrappedComponent
}

export { useAuthContext, AuthProvider, withAuth }
