import React from 'react'

import PropTypes from 'prop-types'
import Form from 'react-bootstrap/Form'
import { useFormContext, useController } from 'react-hook-form'

import getInputClasses from './utils/getInputClasses'
import getRules from './utils/getRules'

const FormControl = ({
  name,
  control,
  text,
  size,
  solid,
  white,
  flush,
  shadow,
  className,
  defaultValue = false,
  required,
  min,
  max,
  minLength,
  maxLength,
  pattern,
  validate,
  onChange,
  ...rest
}) => {
  const {
    field: { ref, value, onChange: onChangeField, ...inputProps },
    fieldState: { invalid, isTouched, isDirty },
    formState: { errors, touchedFields, dirtyFields }
  } = useController({
    name,
    control,
    rules: getRules({
      required,
      min,
      max,
      minLength,
      maxLength,
      pattern,
      validate
    }),
    defaultValue
  })
  const classes = getInputClasses({
    prefix: 'form-check',
    size,
    solid,
    white,
    flush,
    shadow,
    className,
    'pt-3': true
  })
  const handleChange = e => {
    onChangeField(e)
    onChange && onChange(e)
  }

  return (
    <Form.Check
      id={name}
      className={classes}
      checked={!!value}
      onChange={handleChange}
      isInvalid={!!errors[name] || invalid}
      label={text}
      {...inputProps}
      {...rest}
    />
  )
}

const FormControlContainer = ({ name, ...rest }) => {
  const { control } = useFormContext()

  return <FormControl name={name} control={control} {...rest} />
}

FormControlContainer.propTypes = {
  name: PropTypes.string.isRequired
}

export default FormControlContainer
