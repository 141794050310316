import { useEffect, useState } from 'react'

import _ from 'lodash'
import PropTypes from 'prop-types'
import { useFormContext, useController, useWatch } from 'react-hook-form'
import toastr from 'toastr'

import { useDataProviderContext } from '../../context/DataProviderContext'
import { useResourceRecordContext } from '../../context/ResourceRecordContext'
import UserPlaceholder from '../../svg/UserPlaceholder'

import fileToBase64 from './utils/fileToBase64'
import getInputClasses from './utils/getInputClasses'
import getRules from './utils/getRules'

const ImageInput = ({
  name,
  control,

  valueKey = 'id',
  removable,
  accept = '.png, .jpg, .jpeg, .gif',
  faIconPrefix = 'fad',
  mediaApiScope = 'client',
  base64AsValue,
  blankImage = '/placeholders/user.svg',
  imageFit = 'cover',
  setRoleObject,
  uploadArgs = {},

  size,
  solid,
  white,
  flush,
  shadow,
  className,
  circle,
  defaultValue = '',
  required,
  min,
  max,
  minLength,
  maxLength,
  pattern,
  validate,
  onChange
}) => {
  const {
    field: { ref, value, onChange: onChangeField, ...inputProps },
    fieldState: { invalid, isTouched, isDirty },
    formState: { errors, touchedFields, dirtyFields }
  } = useController({
    name,
    control,
    rules: getRules({
      required,
      min,
      max,
      minLength,
      maxLength,
      pattern,
      validate
    }),
    defaultValue
  })
  const { getValues, setValue } = useFormContext()
  const [imageUrl, setImageUrl] = useState(null)
  const classes = getInputClasses({
    prefix: 'image-input',
    size,
    solid,
    white,
    flush,
    shadow,
    className,
    'rounded-circle': circle,
    'image-input-empty': !imageUrl
  })
  const { httpClient } = useDataProviderContext()
  const [image, setImage] = useState('')
  const recordImageUrl = useWatch({
    name: `${name.replace('_id', '')}.file.url`
  })

  useEffect(() => {
    const record = getValues()
    if (_.has(record, `${name.replace('_id', '')}.file.url`))
      setImageUrl(_.get(record, `${name.replace('_id', '')}.file.url`))
  }, [name, recordImageUrl])

  const handleChange = async e => {
    setImage(e.target.value)
    let record
    let value = ''
    const imageFile =
      e.target.files && e.target.files.length ? e.target.files[0] : null
    if (imageFile) {
      const fileInBase64 = await fileToBase64(imageFile)

      value = base64AsValue
        ? fileInBase64
        : {
            data: fileInBase64,
            filename: imageFile.name,
            size: imageFile.size,
            content_type: imageFile.type
          }

      try {
        const res = await httpClient.post(
          `${mediaApiScope ? '/' + mediaApiScope : ''}/medias`,
          {
            record: {
              file: value,
              ...(uploadArgs || {})
            }
          }
        )

        record = _.get(res, 'data.record')
        value = _.get(record, valueKey, record?.id)

        if (setRoleObject) setValue(name.replace('_id', ''), record)
      } catch (error) {
        toastr.error(
          'Ocorreu um erro inesperado ao fazer o upload da mídia, tente novamente mais tarde',
          'Ooops'
        )

        value = null
      }

      const blobUrl = URL.createObjectURL(imageFile)
      setImageUrl(blobUrl)
    } else setImageUrl(null)

    onChangeField(value)
    onChange && onChange(value, record)
  }

  const handleRemoveImage = () => {
    setImage('')
    setImageUrl(null)
    onChangeField('')
    onChange && onChange('')
  }

  const handleCancelImage = () => handleRemoveImage()

  return (
    <div className={classes}>
      <div
        className={`image-input-wrapper bgi-position-center bgi-size-${imageFit} ${
          circle ? 'rounded-circle' : ''
        } `}
        style={{
          backgroundImage: `url('${imageUrl ? imageUrl : blankImage}')`
        }}
      >
        {!imageUrl && <UserPlaceholder className="w-100" />}
      </div>
      <label
        className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
        data-ttz-image-input-action="change"
        data-bs-toggle="tooltip"
        title="Alterar imagem"
        data-bs-original-title="Alterar imagem"
        htmlFor={name.replace('_id', '')}
      >
        <i className={`${faIconPrefix} fa-pen icon-sm text-muted`} />

        <input
          id={name.replace('_id', '')}
          type="file"
          accept={accept}
          onChange={handleChange}
          value={image}
          ref={ref}
        />

        {removable && <input type="hidden" name={`remove_${name}`} />}
      </label>
      {imageUrl && (
        <button
          type="button"
          className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
          data-ttz-image-input-action="remove"
          data-bs-toggle="tooltip"
          title="Cancelar imagem"
          data-bs-original-title="Cancelar imagem"
          onClick={handleCancelImage}
        >
          <i className={`${faIconPrefix} fa-times icon-xs text-muted`} />
        </button>
      )}
      {(image || imageUrl) && (
        <button
          type="button"
          className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
          data-ttz-image-input-action="remove"
          data-bs-toggle="tooltip"
          title="Remover imagem"
          data-bs-original-title="Remover imagem"
          onClick={handleRemoveImage}
        >
          <i className={`${faIconPrefix} fa-times icon-xs text-muted`} />
        </button>
      )}
    </div>
  )
}

const ImageInputContainer = ({ name, ...rest }) => {
  const { control } = useFormContext()

  return <ImageInput name={name} control={control} {...rest} />
}

ImageInputContainer.propTypes = {
  name: PropTypes.string.isRequired
}

export default ImageInputContainer
