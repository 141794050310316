import { createContext, useContext, useState } from 'react'

import _ from 'lodash'

const ModalContext = createContext()

const useModalContext = () => useContext(ModalContext)

const ModalProvider = ({ children }) => {
  const [_showModal, _setShowModal] = useState(false)
  const showModal = _setShowModal

  const Modal = ({ component, onHide, onSave, ...rest }) => {
    const Component = component

    return (
      <Component
        {...rest}
        show={_showModal}
        onHide={() => {
          onHide && onHide()
          _setShowModal(false)
        }}
        onSave={record => {
          onSave && onSave(record)
          _setShowModal(false)
        }}
      />
    )
  }

  return (
    <ModalContext.Provider
      value={{
        showModal
      }}
    >
      {children}
      {_showModal && <Modal {..._showModal} />}
    </ModalContext.Provider>
  )
}

// eslint-disable-next-line react/display-name
const withModal = Component => props => {
  return (
    <ModalProvider>
      <Component {...props} />
    </ModalProvider>
  )
}

export { useModalContext, ModalProvider, withModal }
