import { useEffect } from 'react'

import {
  useDataProvider,
  useSession,
  AbilityProvider,
  DataProviderProvider,
  SessionProvider
} from '@tootz/react-admin'

import useAbility from '@/services/ability'
import { api } from '@/services/api'

const MyApp = ({ children }) => {
  const { data: session } = useSession()
  const dataProvider = useDataProvider('', api)
  const fetcher = (resource, init) =>
    api.get(resource, init).then(res => res.data)
  const ability = useAbility(session?.user)

  useEffect(() => {
    const body = document.querySelector('body')

    document.documentElement.setAttribute('data-bs-theme', 'light')

    if (body) {
      body.setAttribute('id', 'ttz_app_body')
      body.setAttribute('data-ttz-app-layout', 'dark-sidebar')
      body.setAttribute('data-ttz-app-header-fixed', 'true')
      body.setAttribute('data-ttz-app-sidebar-enabled', 'true')
      body.setAttribute('data-ttz-app-sidebar-fixed', 'true')
      body.setAttribute('data-ttz-app-sidebar-hoverable', 'true')
      body.setAttribute('data-ttz-app-sidebar-push-header', 'true')
      body.setAttribute('data-ttz-app-sidebar-push-toolbar', 'true')
      body.setAttribute('data-ttz-app-sidebar-push-footer', 'true')
      body.setAttribute('data-ttz-app-toolbar-enabled', 'true')
    }

    return () => {
      if (body) {
        body.removeAttribute('id')
        body.removeAttribute('data-ttz-app-layout')
        body.removeAttribute('data-ttz-app-header-fixed')
        body.removeAttribute('data-ttz-app-sidebar-enabled')
        body.removeAttribute('data-ttz-app-sidebar-fixed')
        body.removeAttribute('data-ttz-app-sidebar-hoverable')
        body.removeAttribute('data-ttz-app-sidebar-push-header')
        body.removeAttribute('data-ttz-app-sidebar-push-toolbar')
        body.removeAttribute('data-ttz-app-sidebar-push-footer')
        body.removeAttribute('data-ttz-app-toolbar-enabled')
      }
    }
  })

  return (
    <AbilityProvider ability={ability}>
      <DataProviderProvider
        httpClient={api}
        fetcher={fetcher}
        dataProvider={dataProvider}
      >
        <SessionProvider session={session}>{children}</SessionProvider>
      </DataProviderProvider>
    </AbilityProvider>
  )
}

export default MyApp
