import _ from 'lodash'

export const apiBasePath = '/client'
export const kinds = [
  {
    slug: 'company',
    name: 'Pessoa Jurídica',
    icon: 'fad fa-buildings',
    description: 'Para empresas com CNPJ e domicilio fiscal no Brasil'
  },
  {
    slug: 'person',
    name: 'Pessoa Física',
    icon: 'fad fa-user-tie',
    description: 'Para titulares com cadastro de pessoa física'
  }
]
export const getKind = slug => _.find(kinds, { slug })
export const getConfig = (props = {}) => {
  const {} = props

  const config = {
    apiBasePath,
    kinds,
    getKind,
    forceIdPresence: false
  }

  return config
}
