import { memo } from 'react'

import _ from 'lodash'

import { Dropdown } from '@tootz/react-admin/components'

import { useSessionContext } from '../../../context/SessionContext'
import { filterMenuItemsByCondition } from '../../../helpers'
import truncateName from '../../../utils/truncateName'

const MenuItem = ({ slug, title, isSeparator, ...rest }) => {
  if (isSeparator) return <Dropdown.Divider {...rest} />

  return (
    <Dropdown.Link className={`${slug} px-5`} {...rest}>
      {title}
    </Dropdown.Link>
  )
}

const UserAvatarMomoized = ({ name, avatarUrl }) => {
  return avatarUrl ? (
    <div
      className="symbol-label"
      style={{ backgroundImage: `url(${avatarUrl})` }}
    ></div>
  ) : (
    <div className="symbol-label fs-4 fw-bold text-primary bg-light-dark hoverable">
      {name?.charAt(0)}
    </div>
  )
}

const UserAvatar = memo(
  UserAvatarMomoized,
  (prevProps, nextProps) =>
    prevProps.name === nextProps.name &&
    prevProps.avatarUrl === nextProps.avatarUrl
)

const User = ({ menuItems, hideName, customContent, customToggle }) => {
  const { user } = useSessionContext()

  return (
    <div
      id="ttz_header_user_menu_toggle"
      className="d-flex align-items-center ms-1 ms-lg-3 quick-user"
    >
      <Dropdown>
        <Dropdown.Toggle
          customToggle
          className={
            !customToggle && 'd-flex align-items-center cursor-pointer'
          }
        >
          {customToggle}
          {!customToggle && (
            <>
              <div className={`symbol symbol-40px ${hideName ? '' : 'me-4'}`}>
                <UserAvatar
                  name={_.get(user, 'name')}
                  avatarUrl={_.get(user, 'avatar.file.url')}
                />
              </div>
              {!hideName && (
                <span className="fw-bolder">
                  {truncateName(_.get(user, 'name', ''), 20)}
                </span>
              )}
            </>
          )}
        </Dropdown.Toggle>
        <Dropdown.Menu className="fw-bold py-4 fs-6 w-275px">
          <Dropdown.Content
            className={!customContent && 'd-flex align-items-center px-3 w-100'}
          >
            {customContent}
            {!customContent && (
              <>
                <div className="symbol symbol-50px me-5">
                  <UserAvatar
                    name={_.get(user, 'name')}
                    avatarUrl={_.get(user, 'avatar.file.url')}
                  />
                </div>
                <div>
                  <div className="fw-bolder d-flex align-items-center fs-5">
                    {truncateName(_.get(user, 'name'), 20)}
                    <span className="badge badge-light-success fw-bolder fs-5 px-2 py-1 ms-2"></span>
                  </div>
                  <a
                    href={`mailto:${user?.email}`}
                    target="_blank"
                    className="fw-bold text-muted text-hover-primary fs-7 d-block text-truncate w-175px"
                    rel="noreferrer"
                  >
                    {user?.email}
                  </a>
                </div>
              </>
            )}
          </Dropdown.Content>
          <Dropdown.Divider />
          {filterMenuItemsByCondition(menuItems).map(({ slug, ...rest }) => (
            <MenuItem key={slug} {...rest} />
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}

export default User
