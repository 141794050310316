import _ from 'lodash'
import toastr from 'toastr'

import { showErrorAlert } from '@tootz/react-admin'

import { Overlay } from '../../components/Overlay'
import { useAuthContext } from '../context'

import { ConfirmationFormModal } from './Modal'
import { ConfirmationFormBody } from './Body'

export const ConfirmationForm = ({ onConfirm, onError }) => {
  const {
    isLoading,
    setIsLoading,
    httpClient,
    callbackUrl,
    currentEmail,
    goToSignIn,
    handleSignIn,
    currentPassword
  } = useAuthContext()

  const handleValidateToken = async token => {
    if (isLoading || !token || token.length < 6) return

    setIsLoading(true)

    try {
      await httpClient.post('/auth/confirmation', {
        email: currentEmail,
        token
      })

      if (typeof onConfirm !== 'undefined' && _.isFunction(onConfirm))
        onConfirm({ email: currentEmail })
      else {
        toastr.success('Conta confirmada com sucesso')

        const { ok } = await handleSignIn({
          login: currentEmail,
          password: currentPassword
        })

        if (ok) location.replace(callbackUrl)
        else goToSignIn()
      }
    } catch (error) {
      if (typeof onError !== 'undefined' && _.isFunction(onError))
        onError({ error })
      else showErrorAlert(error)
    }

    setIsLoading(false)
  }

  return (
    <Overlay
      block
      active={isLoading}
      opacity={50}
      layer={
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Carregando...</span>
        </div>
      }
    >
      <ConfirmationFormBody onValidateToken={handleValidateToken} />
    </Overlay>
  )
}

export { ConfirmationFormModal }
