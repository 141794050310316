import { yupResolver } from '@hookform/resolvers/yup'
import _ from 'lodash'
import { useForm, FormProvider } from 'react-hook-form'
import Link from 'next/link'
import { Button } from 'react-bootstrap'

import { FormControl, FormGroup } from '@tootz/react-admin/form'

import { useAuthContext } from '../../context'

import validationSchema from './validationSchema'

const isDev = process.env.NODE_ENV === 'development'

const ResendConfirmationForm = () => {
  const {
    isLoading,
    styleConfig,
    config,
    onRequestConfirmationToken,
    goToSignIn
  } = useAuthContext()
  const methods = useForm({
    defaultValues: isDev ? { email: 'wendell@tootz.com.br' } : {},
    resolver: yupResolver(validationSchema)
  })

  return (
    <>
      {_.has(config, 'resendConfirmation.header') && (
        <div className="text-center mb-11">
          <h1 className="text-dark fw-bolder mb-3">
            {_.get(config, 'resendConfirmation.header.title')}
          </h1>
          <div className="text-gray-500 fw-semibold fs-6">
            {_.get(config, 'resendConfirmation.header.subtitle')}
          </div>
        </div>
      )}
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onRequestConfirmationToken)}>
          <FormGroup
            label="E-mail"
            labelClassName="fw-bolder"
            name="email"
            size={styleConfig.size}
            solid={styleConfig.solid ? true : undefined}
            control={FormControl}
            type="email"
            vertical
          />
          <div className="d-flex justify-content-center align-items-center gap-6">
            <button
              onClick={() => goToSignIn()}
              className={`btn btn-light btn-${styleConfig.inputsSize} mb-5 flex-grow-1`}
              disabled={isLoading}
            >
              Cancelar
            </button>
            <Button
              type="submit"
              variant="primary"
              size={styleConfig.inputsSize}
              className="mb-5 flex-grow-1"
              disabled={isLoading}
            >
              {isLoading ? 'Solicitando...' : 'Solicitar'}
            </Button>
          </div>
        </form>
      </FormProvider>
    </>
  )
}

export default ResendConfirmationForm
