import React from 'react'

import { useController, useFormContext } from 'react-hook-form'
import Switch from 'react-switch'
import PropTypes from 'prop-types'

import getInputClasses from './utils/getInputClasses'
import getRules from './utils/getRules'

const defaultOptions = {
  onColor: '#E1F0FF',
  onHandleColor: '#3699FF',
  offColor: '#e4e6ef',
  offHandleColor: '#3f4254',
  uncheckedIcon: false,
  checkedIcon: false
}

const FormSwitchController = ({
  name,
  control,
  options: propOptions,
  size,
  solid,
  white,
  flush,
  shadow,
  className,
  defaultValue = false,
  required,
  validate,
  onChange,
  ...rest
}) => {
  const {
    field: { ref, value, onChange: onChangeField, ...inputProps },
    fieldState: { invalid, isTouched, isDirty },
    formState: { errors, touchedFields, dirtyFields }
  } = useController({
    name,
    control,
    rules: getRules({
      required,
      validate
    }),
    defaultValue
  })
  const classes = getInputClasses({
    prefix: 'form-switch2',
    size,
    solid,
    white,
    flush,
    shadow,
    className
  })
  const handleChange = e => {
    onChangeField(e)
    onChange && onChange(e)
  }

  const options = { ...defaultOptions, ...propOptions }

  return (
    <Switch
      className={classes}
      checked={value}
      onChange={handleChange}
      // isInvalid={!!errors[name] || invalid}
      {...options}
      {...inputProps}
      {...rest}
    />
  )
}

const FormSwitchContainer = ({ name, ...rest }) => {
  const { control } = useFormContext()

  return <FormSwitchController name={name} control={control} {...rest} />
}

FormSwitchContainer.propTypes = {
  name: PropTypes.string.isRequired
}

export default FormSwitchContainer
