import { useState, useRef } from 'react'

import _ from 'lodash'
import ReCAPTCHA from 'react-google-recaptcha'
import Swal from 'sweetalert2'
import { Button } from 'react-bootstrap'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { showErrorAlert, getErrorMessage } from '@tootz/react-admin'

import { ConfirmationFormBody } from '../Confirmation/Body'
import { useAuthContext } from '../context'

import { ResetPasswordFields } from './Fields'

const isDev = process.env.NODE_ENV === 'development'

const ResetPassword = () => {
  const reCaptchaRef = useRef()
  const {
    httpClient,
    isLoading,
    setIsLoading,
    styleConfig,
    goToSignIn,
    reCaptchaEnabled,
    useReCaptchaV3,
    currentEmail,
    reCaptchaKey,
    config
  } = useAuthContext()
  const methods = useForm({
    resolver: yupResolver(ResetPasswordFields.validationSchema)
  })
  const [tokenValidated, setTokenValidated] = useState(false)

  const handleValidateToken = async token => {
    if (!token || token.length < 6) return

    setIsLoading(true)
    setTokenValidated(false)

    try {
      await httpClient.post('/auth/reset-password-token-validation', {
        email: currentEmail,
        token
      })

      setTokenValidated(token)
    } catch (error) {
      const { errors } = getErrorMessage(error)

      if (_.has(errors, 'reset_password_token')) {
        Swal.fire({
          icon: 'error',
          title: 'Código inválido',
          text: 'O código informado é inválido, verifique se foi digitado corretamente e, caso precise, solicite um novo no link do formulário'
        })
      } else showErrorAlert(error)
    }

    setIsLoading(false)
  }
  const handleResetPassword = async data => {
    setIsLoading(true)

    // if (reCaptchaEnabled) {
    //   let recaptchaValue

    //   if (useReCaptchaV3) recaptchaValue = await reCaptchaRef.current.execute()
    //   else recaptchaValue = reCaptchaRef.current.getValue()

    //   if (!recaptchaValue) {
    //     Swal.fire({
    //       icon: 'error',
    //       title: 'Oops...',
    //       text: 'Antes de entrar, use o reCAPTCHA pra verificar sua identidade'
    //     })

    //     setIsLoading(false)
    //     return
    //   }
    // }

    const { password, password_confirmation } = data

    httpClient
      .put('/auth/reset-password', {
        email: currentEmail,
        token: tokenValidated,
        password,
        password_confirmation
      })
      .then(() => {
        Swal.fire({
          icon: 'success',
          title: 'Feito!',
          text: 'Senha resetada com sucesso, faça login usando sua nova senha'
        }).then(goToSignIn)
      })
      .catch(showErrorAlert)
      .finally(() => setIsLoading(false))
  }

  if (!currentEmail) return <>E-mail não presente</>
  if (tokenValidated)
    return (
      <>
        {_.has(config, 'resetPassword.header') && (
          <div className="text-center mb-11">
            <h1 className="text-dark fw-bolder mb-3">
              {_.get(config, 'resetPassword.header.title')}
            </h1>
            <div className="text-gray-500 fw-semibold fs-6">
              {_.get(config, 'resetPassword.header.subtitle')}
            </div>
          </div>
        )}
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(handleResetPassword)}>
            <ResetPasswordFields />
            {reCaptchaEnabled && (
              <div className="d-flex align-items-center justify-content-center w-100">
                <ReCAPTCHA
                  ref={reCaptchaRef}
                  sitekey={reCaptchaKey}
                  size={useReCaptchaV3 ? 'invisible' : 'normal'}
                />
              </div>
            )}
            <div className="d-grid mb-10 mt-8">
              <Button
                type="submit"
                variant="primary"
                size={styleConfig.size}
                solid={styleConfig.solid ? true : undefined}
                disabled={isLoading}
              >
                {isLoading ? 'Resetando...' : 'Resetar senha'}
              </Button>
            </div>
            <div className="text-gray-500 text-center fw-semibold fs-6 d-flex justify-content-center">
              Já resetou sua senha?
              <button
                type="button"
                className="btn btn-flush link-primary fs-6 fw-semibold ms-1"
                onClick={goToSignIn}
              >
                Faça login
              </button>
            </div>
          </form>
        </FormProvider>
      </>
    )
  return (
    <ConfirmationFormBody
      onValidateToken={handleValidateToken}
      resendTokenApiUrl="/auth/reset-password"
    />
  )
}

export default ResetPassword
