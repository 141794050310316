const UserPlaceholder = ({ className }) => (
  <svg
    width="auto"
    height="auto"
    viewBox="0 0 900 900"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <rect width="900" height="900" fill="#F8FBFF" />
    <path
      d="M450.5 195C376.206 195 315.764 255.443 315.764 329.736C315.764 404.03 376.206 464.473 450.5 464.473C524.794 464.473 585.236 404.03 585.236 329.736C585.236 255.443 524.794 195 450.5 195Z"
      fill="#DCE5F1"
    />
    <path
      d="M618.138 552.495C581.25 515.041 532.349 494.414 480.441 494.414H420.559C368.652 494.414 319.75 515.041 282.862 552.495C246.155 589.766 225.939 638.964 225.939 691.029C225.939 699.297 232.642 706 240.91 706H660.09C668.358 706 675.061 699.297 675.061 691.029C675.061 638.964 654.845 589.766 618.138 552.495Z"
      fill="#DCE5F1"
    />
  </svg>
)

export default UserPlaceholder
