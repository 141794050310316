import { useState, useEffect } from 'react'

import { getAllStates, getStateCities } from 'easy-location-br'
import _ from 'lodash'
import { InputGroup } from 'react-bootstrap'
import { useFormContext, useWatch } from 'react-hook-form'
import { validateCep } from 'validations-br'
import * as yup from 'yup'
import { pt } from 'yup-locale-pt'

yup.setLocale(pt)

import {
  FormGroup,
  FormControl,
  InputMask,
  Select,
  FormSwitch
} from '../../form'
import api from '../../services/api'

const DEFAULT_NAMES = {
  zipCode: 'zip_code',
  street: 'street',
  number: 'number',
  state: 'state',
  city: 'city',
  district: 'district',
  complement: 'complement',
  city_cod: 'city_cod'
}

// const isValidUSZipCode = zipCode =>
//   /(^\d{5}$)|(^\d{5}-\d{4}$)/.test((zipCode || '').trim())

const getValidationSchema = (namePrefix, _names, country, language) => {
  const isBrazil = !country || country == 'BRA'
  const isPortuguese = !language || language == 'pt'

  const names = { ...DEFAULT_NAMES, ..._names }
  const {
    zipCode,
    street,
    number,
    state,
    city,
    district,
    complement,
    city_cod
  } = names

  let schema = yup.object().shape({
    [zipCode]: yup
      .string()
      .nullable()
      .test('is-cep', 'Não é um CEP válido', value =>
        isBrazil ? validateCep((value || '').trim().replace(/\D/g, '')) : true
      )
      .required()
      .label(isPortuguese ? 'CEP' : 'Zip Code'),
    [street]: yup
      .string()
      .nullable()
      .required()
      .label(isPortuguese ? 'Rua/Av/Logradouro' : 'Address'),
    [number]: yup
      .string()
      .nullable()
      .test(
        'is-not-empty',
        `${isPortuguese ? 'Nº' : 'No'} é obrigatório`,
        value => value && value.trim().length
      )
      .required()
      .label(isPortuguese ? 'Nº' : 'No'),
    [state]: yup
      .string()
      .nullable()
      .required()
      .label(isPortuguese ? 'UF' : 'State'),
    [district]: yup
      .string()
      .nullable()
      .test(
        'is-not-empty',
        `${isPortuguese ? 'Bairro' : 'Neighbourhood'} é obrigatório`,
        value => value && value.trim().length
      )
      .required()
      .label(isPortuguese ? 'Bairro' : 'Neighbourhood'),
    [city]: yup
      .string()
      .nullable()
      .required()
      .label(isPortuguese ? 'Cidade' : 'City'),
    [city_cod]: yup.string().nullable().label('Código da Cidade'),
    [complement]: yup
      .string()
      .nullable()
      .label(isPortuguese ? 'Complemento' : 'Line 1')
  })

  if (namePrefix) {
    const namePrefixes = _.isArray(namePrefix)
      ? namePrefix
      : namePrefix.split('.')
    namePrefixes.reverse().forEach(prefix => {
      schema = yup.object().shape({
        [prefix]: schema
      })
    })
  }

  return schema
}

const AddressFields = ({
  namePrefix: _namePrefix,
  names: _names,
  solid,
  horizontal,
  showSaveAsDefault,
  nestedFieldsSlot,
  hideFieldsUntilZipCode,
  country,
  required = true,
  language
}) => {
  const [zipCodeInfoLoadingStatus, setZipCodeInfoLoadingStatus] = useState('')
  const { setValue, getValues } = useFormContext()
  const isBrazil = !country || country == 'BRA'
  const isPortuguese = !language || language == 'pt'
  const names = { ...DEFAULT_NAMES, ..._names }
  const namePrefix = _namePrefix ? `${_namePrefix}.` : ''
  const watchZipCode = useWatch({
    name: `${namePrefix}${names.zipCode}`,
    defaultValue: getValues(`${namePrefix}${names.zipCode}`)
  })
  const watchState = useWatch({ name: `${namePrefix}${names.state}` })
  const zipCode = watchZipCode?.replace(/\D/g, '')
  const zipCodeHasValidLength = zipCode?.length === 8
  const vertical = !horizontal
  const blockEditing =
    isBrazil &&
    (!['success', 'unreachable', 'bypass'].includes(zipCodeInfoLoadingStatus) ||
      !zipCodeHasValidLength)
  const disableAddressFields = false
  // const disableAddressFields = zipCodeInfoLoadingStatus == 'success'

  const loadAddress = async () => {
    if (!zipCodeHasValidLength || !isBrazil) return

    setZipCodeInfoLoadingStatus('loading')

    try {
      const addressRes = await api.get(
        `https://viacep.com.br/ws/${zipCode}/json/`
      )
      const { logradouro, bairro, localidade, uf, ibge, erro } = addressRes.data

      if (erro) {
        setValue(`${namePrefix}${names.street}`, '')
        setValue(`${namePrefix}${names.district}`, '')
        setValue(`${namePrefix}${names.state}`, '')
        setValue(`${namePrefix}${names.city}`, '')
        setValue(`${namePrefix}${names.city_cod}`, '')

        setZipCodeInfoLoadingStatus('error')
      } else {
        setValue(`${namePrefix}${names.street}`, logradouro)
        setValue(`${namePrefix}${names.district}`, bairro)
        setValue(`${namePrefix}${names.state}`, uf, { shouldValidate: true })
        setValue(`${namePrefix}${names.city}`, localidade)
        setValue(`${namePrefix}${names.city_cod}`, ibge)

        setZipCodeInfoLoadingStatus('success')
      }
    } catch (error) {
      setZipCodeInfoLoadingStatus('unreachable')
    }
  }

  useEffect(() => {
    /*
     * Se houver valor no formulário que ainda não esteja setado
     * no watch, atualizar para que o watch não fique pra trás
     */
    const zipCodeValue = getValues(`${namePrefix}${names.zipCode}`)
    if (zipCodeValue && !watchZipCode)
      setValue(`${namePrefix}${names.zipCode}`, zipCodeValue)

    loadAddress()
  }, [watchZipCode])

  return (
    <>
      <FormControl name={`${namePrefix}${names.city_cod}`} hidden />
      <FormGroup
        name={`${namePrefix}${names.zipCode}`}
        label={isPortuguese ? 'CEP' : 'Zip Code'}
        vertical={vertical}
        solid={solid}
        required={required}
      >
        <div className="d-flex">
          {isBrazil ? (
            <InputGroup
              className={`${nestedFieldsSlot ? 'me-2' : ''}`}
              solid={solid}
            >
              <InputMask
                name={`${namePrefix}${names.zipCode}`}
                mask="99999-999"
                solid={solid}
                required={required}
              />
              <button
                type="button"
                className={`btn btn-icon btn-${
                  solid ? 'light' : 'outline'
                } btn-active-primary`}
                title="Atualizar CEP"
                disabled={zipCodeInfoLoadingStatus == 'loading'}
                onClick={loadAddress}
              >
                <i
                  className={`fad fa-arrows-rotate ${
                    zipCodeInfoLoadingStatus == 'loading'
                      ? 'text-primary fa-spin'
                      : ''
                  }`}
                />
              </button>
            </InputGroup>
          ) : (
            <FormControl
              name={`${namePrefix}${names.zipCode}`}
              solid={solid}
              required={required}
            />
          )}
          {nestedFieldsSlot}
        </div>
      </FormGroup>
      {(!isBrazil || !hideFieldsUntilZipCode || zipCodeHasValidLength) && (
        <div
          className={blockEditing ? 'overlay overlay-block cursor-default' : ''}
        >
          <div className="row">
            <div className="col-12">
              <FormGroup
                name={`${namePrefix}${names.street}`}
                label={isPortuguese ? 'Rua/Av/Logradouro' : 'Address'}
                control={FormControl}
                disabled={disableAddressFields}
                vertical={vertical}
                solid={solid}
                required={required}
              />
            </div>
            <div className={`${vertical ? 'col-md-4 col-lg-4 col-xl-3' : ''}`}>
              <FormGroup
                name={`${namePrefix}${names.number}`}
                label={isPortuguese ? 'Nº' : 'Number'}
                control={FormControl}
                vertical={vertical}
                solid={solid}
                required={required}
              />
            </div>
            <div className={`${vertical ? 'col-md-8 col-lg-8 col-xl-6' : ''}`}>
              <FormGroup
                name={`${namePrefix}${names.district}`}
                label={isPortuguese ? 'Bairro' : 'Neighborhood '}
                control={FormControl}
                disabled={disableAddressFields}
                vertical={vertical}
                solid={solid}
                required={required}
              />
            </div>
            <div className={`${vertical ? 'col-md-5 col-lg-3 col-xl-3' : ''}`}>
              <FormGroup
                name={`${namePrefix}${names.state}`}
                label={isPortuguese ? 'UF' : 'State'}
                control={isBrazil ? Select : FormControl}
                options={getAllStates().map(s => ({
                  label: s.id,
                  value: s.id
                }))}
                disabled={disableAddressFields}
                isClearable={false}
                vertical={vertical}
                solid={solid}
                required={required}
              />
            </div>
            <div className={`${vertical ? 'col-md-7 col-lg-9 col-xl-6' : ''}`}>
              <FormGroup
                name={`${namePrefix}${names.city}`}
                label={isPortuguese ? 'Cidade' : 'City'}
                control={isBrazil ? Select : FormControl}
                options={getStateCities(watchState).map(s => ({
                  label: s.name,
                  value: s.name
                }))}
                disabled={disableAddressFields}
                isClearable={false}
                vertical={vertical}
                solid={solid}
                required={required}
              />
            </div>
            <div className={`${vertical ? 'col-md-12 col-xl-6' : 'col-12'}`}>
              <FormGroup
                name={`${namePrefix}${names.complement}`}
                label={isPortuguese ? 'Complemento' : 'Line 1'}
                control={FormControl}
                solid={solid}
                vertical={vertical}
              />
            </div>
            {showSaveAsDefault && (
              <div className="col-12">
                <div className="d-flex flex-stack">
                  <div className="me-5">
                    <label className="fs-6 fw-bold form-label">
                      Salvar como principal?
                    </label>
                    <div className="fs-4 fw-bold text-muted">
                      Quando você precisar efetuar novas compras, este endereço
                      será usado
                    </div>
                  </div>
                  <FormSwitch
                    name={`${namePrefix}default`}
                    defaultValue={true}
                  />
                </div>
              </div>
            )}
          </div>
          {blockEditing && (
            <div className="overlay-layer bg-white bg-opacity-50">
              {!zipCodeHasValidLength && (
                <span className="btn btn-sm btn-light-dark cursor-default">
                  Primeiro insira o CEP
                </span>
              )}
              {zipCodeInfoLoadingStatus == 'loading' && (
                <i className="fad fa-arrows-rotate text-primary fa-spin"></i>
              )}
              {zipCodeHasValidLength && zipCodeInfoLoadingStatus == 'error' && (
                <span className="bg-light-danger px-5 py-4 rounded-3 d-flex flex-column align-items-center">
                  <span className="text-danger fw-bold text-center mb-4">
                    O <b>Correios</b> não retornou nenhum endereço válido para
                    este CEP, verifique os dígitos e tente novamente ou
                    continue, mas sabendo que o seu pedido pode ser recusado
                  </span>
                  <button
                    type="button"
                    className="btn btn-sm px-4 py-2 small btn-danger"
                    onClick={() => setZipCodeInfoLoadingStatus('bypass')}
                  >
                    Continuar mesmo asssim
                  </button>
                </span>
              )}
            </div>
          )}
        </div>
      )}
    </>
  )
}

AddressFields.getValidationSchema = getValidationSchema

export default AddressFields
export { getValidationSchema as getAddressFieldsValidationSchema }
