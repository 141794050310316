import React from 'react'

import Form from 'react-bootstrap/Form'
import { useController, useFormContext } from 'react-hook-form'
import classNames from 'classnames'
import _ from 'lodash'

import getInputClasses from './utils/getInputClasses'
import getRules from './utils/getRules'

const FormChecklist = ({
  name,
  control,
  options,
  inline = false,
  size,
  solid,
  white,
  flush,
  shadow,
  className,
  defaultValue = [],
  required,
  min,
  max,
  minLength,
  maxLength,
  pattern,
  validate,
  onChange
}) => {
  const {
    field: { ref, value: values, onChange: onChangeField, ...inputProps },
    fieldState: { invalid, isTouched, isDirty },
    formState: { errors, touchedFields, dirtyFields }
  } = useController({
    name,
    control,
    rules: getRules({
      required,
      min,
      max,
      minLength,
      maxLength,
      pattern,
      validate
    }),
    defaultValue
  })
  const classes = getInputClasses({
    prefix: 'form-check',
    size,
    solid,
    white,
    flush,
    shadow,
    className,
    'form-check-custom': true,
    'pt-2': true,
    'mb-2': true,
    'pe-4': inline
  })
  const wrapperClasses = classNames('d-flex', {
    'flex-row': inline,
    'flex-column': !inline
  })

  const handleChange = value => {
    const valuesCopy = _.isArray(values) ? values.slice() : []
    const valueIndex = valuesCopy.findIndex(v => v == value)

    if (valueIndex !== -1) valuesCopy.splice(valueIndex, 1)
    else valuesCopy.push(value)

    onChangeField(valuesCopy)
    onChange && onChange(valuesCopy)
  }

  return (
    <div className={wrapperClasses}>
      {options.map(({ label, value }) => {
        return (
          <Form.Check
            key={`${name}_${value}`}
            id={`${name}_${value}`}
            name={`${name}_${value}`}
            type="checkbox"
            value={value}
            label={label}
            inline={inline}
            defaultChecked={values?.includes(value)}
            checked={values?.includes(value)}
            onChange={() => handleChange(value)}
            className={classes}
          />
        )
      })}
    </div>
  )
}

const FormChecklistContainer = ({ name, ...rest }) => {
  const { control } = useFormContext()

  return <FormChecklist name={name} control={control} {...rest} />
}

export default FormChecklistContainer
