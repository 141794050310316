import { defineAbility } from '@casl/ability'
import _ from 'lodash'

import { User } from './entities'

const useAbility = user =>
  defineAbility(can => {
    if (!user) return

    const roles = _.get(user, 'roles', [user.main_role])

    roles.forEach(role => {})
  })

export default useAbility
export { User }
