import { useEffect, useState } from 'react'

import Image from 'next/image'
import { useRouter } from 'next/router'
import { toast } from 'react-toastify'
import store from 'store2'

import {
  withAuth,
  ConfirmationForm,
  ResendConfirmationForm,
  ForgotPasswordForm,
  ResetPasswordForm,
  SignInForm,
  useAuthContext
} from '@tootz/react-admin/auth'
import { Modal } from '@tootz/react-admin/components'

import getConfig from './config'
import { SignUpForm } from './SignUpForm'

const AuthModal = ({ onSignIn: onSignInCallback, onHide, ...rest }) => {
  const isClient = store('maestria.is-client')
  const defaultAction = isClient ? 'sign-in' : 'sign-up'
  const { action, setAction } = useAuthContext()
  const router = useRouter()

  const configs = {
    'sign-in': {
      component: SignInForm,
      componentProps: {
        onSignIn: () => {
          store('maestria.is-client', true)
          onSignInCallback && onSignInCallback()
          router.reload(window.location.pathname)
        }
      },
      title: 'Faça login na sua conta!',
      floatingOption: (
        <span className="position-absolute bottom-0 start-0 my-7 fs-5 text-center w-100">
          Não possui uma conta?
          <span
            className="btn btn-primary ms-3"
            onClick={() => setAction('sign-up')}
          >
            Crie sua conta
          </span>
        </span>
      )
    },
    'sign-up': {
      component: SignUpForm,
      componentProps: {
        withTerms: { href: '/Termos de uso Maestria.pdf', autoAgree: true },
        onSignUp: ({ email }) => {
          toast('Conta criada com sucesso, faça login para continuar')
          setAction('sign-in')
          store('maestria.is-client', true)
        }
      },
      title: 'Cadastre-se gratuitamente!',
      floatingOption: (
        <span className="position-absolute bottom-0 start-0 my-7 fs-5 text-center w-100">
          Já possui uma conta?{' '}
          <span
            className="btn btn-primary ms-3"
            onClick={() => setAction('sign-in')}
          >
            Entre aqui
          </span>
        </span>
      )
    },
    confirmation: {
      component: ConfirmationForm,
      componentProps: {
        onConfirm: () => {
          toast('Conta confirmada com sucesso, faça login para continuar')
          setAction('sign-in')
          store('maestria.is-client', true)
        }
      },
      title: 'Confirme sua conta',
      floatingOption: () => <></>
    },
    'resend-confirmation': {
      component: ResendConfirmationForm,
      componentProps: {},
      title: 'Insira seu e-mail para solicitar um novo código de confirmação',
      floatingOption: () => <></>
    },
    'forgot-password': {
      component: ForgotPasswordForm,
      componentProps: {
        onConfirm: () => {
          toast('Senha alterada com sucesso, faça login para continuar')
          setAction('sign-in')
          store('maestria.is-client', true)
        }
      },
      title: 'Esqueci minha senha',
      floatingOption: () => <></>
    },
    'reset-password': {
      component: ResetPasswordForm,
      componentProps: {
        onConfirm: () => {
          toast('Senha alterada com sucesso, faça login para continuar')
          setAction('sign-in')
          store('maestria.is-client', true)
        }
      },
      title: 'Esqueci minha senha',
      floatingOption: () => <></>
    }
  }
  const config = _.get(configs, action, defaultAction)
  const Form = config.component

  return (
    <Modal centered show onHide={onHide}>
      <Modal.Body noPaddings>
        <div className="d-flex h-100 w-100">
          <div className="flex-grow-1 d-flex flex-column align-items-center justify-content-center position-relative">
            <Image
              src="/logo-alt.svg"
              width={250}
              height={30}
              className="mt-20"
              alt={config.title}
            />
            {config.floatingOption}
            <div className="w-100 mw-400px pb-15 pt-5 my-3 px-10">
              <span className="fw-bold fs-2 d-block mt-4 text-center text-primary">
                {config.title}
              </span>

              {/*
              <GoogleLogin
                onSuccess={credentialResponse => {
                  const { clientId, credential, select_by } = credentialResponse
                  const userInfo = {
                    iss: 'https://accounts.google.com',
                    nbf: 1667786243,
                    aud: '1020852502970-0ocf5u4hbk5gu2069oiftto12d764hkr.apps.googleusercontent.com',
                    sub: '105154092808322044030',
                    hd: 'tootz.com.br',
                    email: 'wendell@tootz.com.br',
                    email_verified: true,
                    azp: '1020852502970-0ocf5u4hbk5gu2069oiftto12d764hkr.apps.googleusercontent.com',
                    name: 'Wendell P. Barreto',
                    picture:
                      'https://lh3.googleusercontent.com/a/ALm5wu2FXH5lqsDfDVIMlfH-wMCkrAi9564JTTPIw1oI=s96-c',
                    given_name: 'Wendell',
                    family_name: 'P. Barreto',
                    iat: 1667786543,
                    exp: 1667790143,
                    jti: 'a5a22b9e78c7d52cbc757aa193909d04d741960f'
                  }
                }}
                onError={() => {
                  console.log('Login Failed')
                }}
              />
            </GoogleOAuthProvider>
          */}
              <Form {...config.componentProps} {...rest} />
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default withAuth(AuthModal, getConfig)
