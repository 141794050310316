import * as yup from 'yup'

const schema = yup.object().shape({
  email: yup
    .string()
    .nullable()
    .email('Digite um e-mail válido')
    .required()
    .label('E-mail')
})

export default schema
