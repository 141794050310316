import { useState, useRef } from 'react'

import _ from 'lodash'
import Swal from 'sweetalert2'
import { Button } from 'react-bootstrap'
import { FormProvider, useForm } from 'react-hook-form'
import { signIn } from 'next-auth/react'
import { yupResolver } from '@hookform/resolvers/yup'
import ReCAPTCHA from 'react-google-recaptcha'

import { useAuthContext } from '../context'
import { Overlay } from '../../components/Overlay'

import { SignInFormFields } from './Fields'
import { SignInProviders } from './Providers'

const isDev = process.env.NODE_ENV === 'development'

export const SignInForm = ({ onSignIn, onError }) => {
  const reCaptchaRef = useRef()
  const {
    styleConfig,
    reCaptchaEnabled,
    useReCaptchaV3,
    reCaptchaKey,
    goToSignUp,
    callbackUrl,
    setCurrentEmail,
    goToResendConfirmation,
    getDefaultValues,
    config
  } = useAuthContext()
  const header =
    _.has(config, 'signIn.header') && _.get(config, 'signIn.header', {})
  const hideSignUpAction = _.has(config, 'signIn.hideSignUpAction')
    ? _.get(config, 'signIn.hideSignUpAction')
    : !_.has(config, 'signUp')
  const methods = useForm({
    defaultValues: getDefaultValues(),
    resolver: yupResolver(SignInFormFields.validationSchema)
  })
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleSignIn = async data => {
    if (isSubmitting) return

    setIsSubmitting(true)

    // if (reCaptchaEnabled) {
    //   let recaptchaValue

    //   if (useReCaptchaV3) recaptchaValue = await reCaptchaRef.current.execute()
    //   else recaptchaValue = reCaptchaRef.current.getValue()

    //   if (!recaptchaValue) {
    //     Swal.fire({
    //       icon: 'error',
    //       title: 'Oops...',
    //       text: 'Antes de entrar, use o reCAPTCHA pra verificar sua identidade'
    //     })

    //     setIsSubmitting(false)
    //     return
    //   }
    // }

    const { error, ok, status, url } = await signIn('domain-sign-in', {
      login: data.login,
      password: data.password,
      callbackUrl,
      redirect: false
    })

    if (ok) {
      if (typeof onSignIn !== 'undefined' && _.isFunction(onSignIn))
        return onSignIn({
          error,
          ok,
          status,
          url,
          callbackUrl
        })
      else return window.location.replace(callbackUrl)
    }

    if (error) {
      setCurrentEmail(data?.login)

      if (typeof onError !== 'undefined' && _.isFunction(onError))
        onError({ error, ok, status, url })
      else {
        if (error == 'invalid_password') {
          methods.setError('password', {
            type: 'custom',
            message: 'Senha incorreta'
          })
        } else if (error == 'login_not_found') {
          methods.setError('login', {
            type: 'custom',
            message: (
              <>
                E-mail não encontrado,{' '}
                <span
                  onClick={goToSignUp}
                  className="cursor-pointer text-primary"
                >
                  clique aqui
                </span>{' '}
                para criar uma conta
              </>
            )
          })
        } else if (error == 'unconfirmed') {
          methods.setError('password', {
            type: 'custom',
            message: (
              <>
                Conta não confirmada,{' '}
                <span
                  onClick={goToResendConfirmation}
                  className="cursor-pointer text-primary"
                >
                  clique aqui
                </span>{' '}
                para solicitar novo código
              </>
            )
          })

          Swal.fire({
            icon: 'error',
            title: 'Conta não confirmada',
            text: 'Sua conta foi previamente registrada mas não foi confirmada, clique no botão abaixo para solicitar novo código de confirmação'
          }).then(goToResendConfirmation)
        } else
          methods.setError('password', {
            type: 'custom',
            message: 'Credenciais inválidas'
          })
      }

      setIsSubmitting(false)
    }
  }

  return (
    <Overlay
      block
      active={isSubmitting}
      opacity={50}
      layer={
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Carregando...</span>
        </div>
      }
    >
      {header && (
        <div className="text-center mb-11">
          <h1 className="text-dark fw-bolder mb-3">{header?.title}</h1>
          <div className="text-gray-500 fw-semibold fs-6">
            {header?.subtitle}
          </div>
        </div>
      )}

      <SignInProviders />

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleSignIn)}>
          <SignInFormFields />

          {reCaptchaEnabled && (
            <div className="d-flex align-items-center justify-content-center w-100">
              <ReCAPTCHA
                ref={reCaptchaRef}
                sitekey={reCaptchaKey}
                size={useReCaptchaV3 ? 'invisible' : 'normal'}
              />
            </div>
          )}
          <div className="d-grid mb-10 mt-8">
            <Button
              type="submit"
              variant="primary"
              size={styleConfig.size}
              solid={styleConfig.solid ? true : undefined}
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <>
                  <span>
                    <i className="fad fa-spinner-third fa-spin"></i>
                  </span>{' '}
                  Entrando...
                </>
              ) : (
                'Entrar'
              )}
            </Button>
          </div>

          {!hideSignUpAction && (
            <div className="text-gray-500 text-center fw-semibold fs-6 d-flex justify-content-center">
              Não faz parte ainda?
              <button
                type="button"
                className="btn btn-flush link-primary fs-6 fw-semibold ms-1"
                onClick={goToSignUp}
              >
                Cadastre-se
              </button>
            </div>
          )}
        </form>
      </FormProvider>
    </Overlay>
  )
}
