import { createContext, useContext } from 'react'

import { DateTime } from 'luxon'
import { useSession } from 'next-auth/react'

import { useRecord } from '@tootz/react-admin'

import { apiBasePath } from './config'

const ClientSubscriptionContext = createContext()

const useClientSubscriptionContext = () => useContext(ClientSubscriptionContext)

const PLANS = [
  { slug: 'free', isTrial: true, iaEnabled: true },
  { slug: 'basic', isTrial: false, iaEnabled: false },
  { slug: 'pro', isTrial: false, iaEnabled: true },
  { slug: 'enterprise', isTrial: false, iaEnabled: true }
]

const ClientSubscriptionProvider = ({ children }) => {
  const { status } = useSession()
  const isAuthenticated = status === 'authenticated'
  const { record: client, loadWrapperConfig } = useRecord({
    r: apiBasePath,
    forceIdPresence: false,
    shouldFetch: isAuthenticated
  })

  const hasPlan =
    !!_.get(client, 'plan') &&
    PLANS.map(p => p.slug).includes(_.get(client, 'plan'))

  const planTrialStartedAt = _.get(client, 'started_free_plan_at')
  const planExpiresAt = hasPlan && _.get(client, 'plan_expires_at')
  const planIsExpired =
    hasPlan && DateTime.fromISO(planExpiresAt) < DateTime.local()

  const hasActivePlan = hasPlan && !planIsExpired

  const currentPlan =
    hasPlan && PLANS.find(p => p.slug == _.get(client, 'plan'))

  const planIsTrial = currentPlan && currentPlan.isTrial
  const planIsPaid = currentPlan && !currentPlan.isTrial

  const canUseIAFeatures = !planIsExpired && currentPlan?.iaEnabled

  return (
    <ClientSubscriptionContext.Provider
      value={{
        client,

        hasSession: isAuthenticated,
        isAuthenticated,

        loadWrapperConfig,

        hasPlan,
        hasActivePlan,

        planTrialStartedAt,
        planExpiresAt,

        currentPlan,
        planIsTrial,
        planIsPaid,
        planIsExpired,

        canUseIAFeatures
      }}
    >
      {children}
    </ClientSubscriptionContext.Provider>
  )
}

export { useClientSubscriptionContext, ClientSubscriptionProvider }
