import { useFormContext, useController } from 'react-hook-form'
import PropTypes from 'prop-types'
import Rate from 'rc-rate'

import getInputClasses from './utils/getInputClasses'
import getRules from './utils/getRules'

const FormControl = ({
  name,
  control,
  size,
  solid,
  white,
  flush,
  shadow,
  className,
  defaultValue = '',
  required,
  min,
  max,
  pattern,
  validate,
  onChange,
  ...rest
}) => {
  const {
    field: { ref, value, onChange: onChangeField, ...inputProps },
    fieldState: { invalid, isTouched, isDirty },
    formState: { errors, touchedFields, dirtyFields }
  } = useController({
    name,
    control,
    rules: getRules({
      required,
      min,
      max,
      pattern,
      validate
    }),
    defaultValue
  })
  const classes = getInputClasses({
    prefix: 'form-rate',
    size,
    solid,
    white,
    flush,
    shadow,
    className
  })
  const handleChange = e => {
    onChangeField(e)
    onChange && onChange(e)
  }

  return (
    <>
      <Rate
        className={classes}
        checked={value}
        onChange={handleChange}
        isInvalid={!!errors[name] || invalid}
        {...inputProps}
        {...rest}
      />
    </>
  )
}

const FormControlContainer = ({ name, ...rest }) => {
  const { control } = useFormContext()

  return <FormControl name={name} control={control} {...rest} />
}

FormControlContainer.propTypes = {
  name: PropTypes.string.isRequired
}

export default FormControlContainer
