import { useEffect } from 'react'

import PropTypes from 'prop-types'
import { useController, useFormContext, useWatch } from 'react-hook-form'
import ReactInputMask from 'react-input-mask'

import getInputClasses from './utils/getInputClasses'
import getRules from './utils/getRules'
import FormControl from './FormControl'
import { BtnGroupRadio, InputMask } from '../../form'

const RegistrationNumberInput = ({
  name,
  kind_name = 'kind',
  control,
  size,
  solid,
  white,
  flush,
  shadow,
  className,
  defaultValue = '',
  required,
  min,
  max,
  minLength,
  maxLength,
  pattern,
  validate,
  onChange,
  ...rest
}) => {
  const { register, setValue, getValues } = useFormContext()
  const {
    field: { ref, value, onChange: onChangeField, ...inputProps },
    fieldState: { invalid, isTouched, isDirty },
    formState: { errors, touchedFields, dirtyFields }
  } = useController({
    name,
    control,
    rules: getRules({
      required,
      min,
      max,
      minLength,
      maxLength,
      pattern,
      validate
    }),
    defaultValue
  })
  const classes = getInputClasses(
    {
      size,
      solid,
      white,
      flush,
      shadow,
      className
    },
    !!errors[name] || invalid
  )
  const availablePersons = [
    { label: 'Pessoa Física', value: 'person' },
    { label: 'Pessoa Jurídica', value: 'company' }
  ]

  const watchKind = useWatch({
    name: 'kind',
    defaultValue: availablePersons[0].value
  })

  return (
    <div className="d-flex gap-3 flex-wrap">
      <div>
        <BtnGroupRadio
          name={kind_name}
          options={availablePersons}
          defaultValue={'person'}
          {...rest}
        />
      </div>
      <div className="flex-fill">
        <ReactInputMask
          name={name}
          className={classes}
          value={value}
          onChange={onChangeField}
          {...inputProps}
          {...rest}
          mask={watchKind == 'person' ? '999.999.999-99' : '99.999.999/9999-99'}
          placeholder={watchKind == 'person' ? 'CPF' : 'CNPJ'}
        />
      </div>
    </div>
  )
}

const RegistrationNumberInputContainer = ({ name, ...rest }) => {
  const { control } = useFormContext()

  return <RegistrationNumberInput name={name} control={control} {...rest} />
}

RegistrationNumberInputContainer.propTypes = {
  name: PropTypes.string.isRequired
}

export default RegistrationNumberInputContainer
