import { useState, useRef } from 'react'

import _ from 'lodash'
import { useRouter } from 'next/router'
import { yupResolver } from '@hookform/resolvers/yup'
import { signIn } from 'next-auth/react'
import { useForm, FormProvider } from 'react-hook-form'
import Swal from 'sweetalert2'
import ReCAPTCHA from 'react-google-recaptcha'
import { Button } from 'react-bootstrap'

import { showErrorAlert } from '@tootz/react-admin'

import { useAuthContext } from '../context'

import { AcceptInviteFormFields } from './Fields'

const AcceptInvite = () => {
  const reCaptchaRef = useRef()
  const router = useRouter()
  const { token, email } = router.query
  const {
    httpClient,
    styleConfig,
    reCaptchaEnabled,
    useReCaptchaV3,
    reCaptchaKey,
    goToSignIn,
    callbackUrl,
    config
  } = useAuthContext()
  const methods = useForm({
    resolver: yupResolver(AcceptInviteFormFields.validationSchema)
  })
  const [isLoading, setIsLoading] = useState(false)

  const onSubmit = async data => {
    setIsLoading(true)

    // if (reCaptchaEnabled) {
    //   let recaptchaValue

    //   if (useReCaptchaV3) recaptchaValue = await reCaptchaRef.current.execute()
    //   else recaptchaValue = reCaptchaRef.current.getValue()

    //   if (!recaptchaValue) {
    //     Swal.fire({
    //       icon: 'error',
    //       title: 'Oops...',
    //       text: 'Antes de entrar, use o reCAPTCHA pra verificar sua identidade'
    //     })

    //     setIsLoading(false)
    //     return
    //   }
    // }

    const { name, cpf, registration_number, password, password_confirmation } =
      data

    httpClient
      .post('/auth/accept-invite', {
        token,
        name,
        cpf: cpf || registration_number,
        registration_number: cpf || registration_number,
        password,
        password_confirmation
      })
      .then(async () => {
        Swal.fire({
          icon: 'success',
          title: 'Conta ativada com sucesso',
          text: 'Clique no botão abaixo e você será redirecionado ao painel de gestão',
          confirmButtonText: 'Entrar'
        }).then(async () => {
          const { ok } = await signIn('domain-sign-in', {
            login: email,
            password,
            redirect: false
          })
          if (ok) {
            window.location.replace(callbackUrl || '/')
          }

          if (error)
            Swal.fire({
              icon: 'error',
              title: 'Ops...',
              text: `Ocorreu um erro inesperado, por favor tente novamente mais tarde (${status})`
            }).then(() => window.location.replace('/auth/sign-in'))
        })
      })
      .catch(showErrorAlert)
      .finally(() => setIsLoading(false))
  }

  if (!token || !email)
    return (
      <>
        <div className="text-center mb-6">
          <i className="fad fa-4x text-danger fa-circle-exclamation" />
        </div>
        <div className="text-center mb-10">
          <h1 className="text-dark mb-3">Ooops!</h1>
          <div className="text-muted fw-bold fs-5 mb-5">
            Link de convite inválido ou expirado, solicite um novo.
          </div>
        </div>
      </>
    )

  return (
    <>
      {_.has(config, 'acceptInvite.header') && (
        <div className="text-center mb-11">
          <h1 className="text-dark fw-bolder mb-3">
            {_.get(config, 'acceptInvite.header.title')}
          </h1>
          <div className="text-gray-500 fw-semibold fs-6">
            {_.get(config, 'acceptInvite.header.subtitle')}
          </div>
        </div>
      )}
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <AcceptInviteFormFields />
          {reCaptchaEnabled && (
            <div className="d-flex align-items-center justify-content-center w-100">
              <ReCAPTCHA
                ref={reCaptchaRef}
                sitekey={reCaptchaKey}
                size={useReCaptchaV3 ? 'invisible' : 'normal'}
              />
            </div>
          )}
          <div className="d-flex justify-content-center align-items-center gap-6 mb-10 mt-8">
            <button
              onClick={goToSignIn}
              className={`btn btn-light btn-${styleConfig.inputsSize} mb-5 flex-grow-1`}
              disabled={isLoading}
            >
              Cancelar
            </button>
            <Button
              type="submit"
              variant="primary"
              size={styleConfig.inputsSize}
              className="mb-5 flex-grow-1"
              disabled={isLoading}
            >
              {isLoading ? 'Criando conta...' : 'Criar conta'}
            </Button>
          </div>
        </form>
      </FormProvider>
    </>
  )
}

export default AcceptInvite
