import classNames from 'classnames'

import { ConditionalWrapper } from '@tootz/react-admin/components'

export const Overlay = ({
  block,
  show,
  active,
  bg = 'white',
  opacity = '75',
  cursor = 'wait',
  layer,
  children
}) => {
  const classes = classNames('overlay', {
    'overlay-hidden': active && !block && !show,
    'overlay-block': active && block,
    'overlay-show': active && show,
    [`cursor-${cursor}`]: active && !show
  })

  const layerClasses = classNames(
    `overlay-layer card-rounded bg-${bg} bg-opacity-${opacity}`
  )

  return (
    <div className={classes}>
      <div className="overlay-wrapper">{children}</div>
      {active && <div className={layerClasses}>{layer}</div>}
    </div>
  )
}

export default Overlay
