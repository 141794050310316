import React, { useEffect, useState, memo } from 'react'

import axios from 'axios'
import GoogleMapReact from 'google-map-react'
import _ from 'lodash'
import { Button } from 'react-bootstrap'
import { useFormContext, useController } from 'react-hook-form'
import toastr from 'toastr'

const FormMapLocationControllerMemoized = ({
  control,
  height = 200,
  defaultLatitude = -5.8600857,
  defaultLongitude = -35.1936753,
  disabled,
  latitudeLabel = 'Latitude',
  latitudeName = 'location.latitude',
  longitudeLabel = 'Longitude',
  longitudeName = 'location.longitude',
  onChange
}) => {
  const { getValues } = useFormContext()
  const { field: latitudeField } = useController({
    name: latitudeName,
    control
  })
  const { field: longitudeField } = useController({
    name: longitudeName,
    control
  })

  const [loadingCoordinates, setLoadingCoordinates] = useState(false)
  const [center, setCenter] = useState({
    lat: latitudeField.value ?? defaultLatitude,
    lng: longitudeField.value ?? defaultLongitude
  })

  const latitude = latitudeField.value ?? defaultLatitude
  const longitude = longitudeField.value ?? defaultLongitude

  const mapData = {
    center: {
      lat: defaultLatitude,
      lng: defaultLongitude
    },
    zoom: 16,
    options: {
      gestureHandling: 'cooperative'
    }
  }

  const handleOnChange = args => {
    latitudeField.onChange(args.center.lat)
    longitudeField.onChange(args.center.lng)
    setCenter({ lat: args.center.lat, lng: args.center.lng })

    onChange && onChange({ lat: args.center.lat, lng: args.center.lng })
  }

  const getCoordinates = () => {
    setLoadingCoordinates(true)
    const fieldsToSearch = [
      'street',
      'number',
      'complement',
      'neighborhood',
      'city_name',
      'state_acronym',
      'zipcode'
    ]

    const search = fieldsToSearch
      .map(field => _.get(getValues('location'), field))
      .filter(Boolean)
      .join(', ')

    axios
      .get('/api/maps', { params: { input: search } })
      .then(response => {
        if (response.data.candidates.length > 0) {
          latitudeField.onChange(
            response.data.candidates[0].geometry.location.lat
          )
          longitudeField.onChange(
            response.data.candidates[0].geometry.location.lng
          )
          setCenter({
            lat: response.data.candidates[0].geometry.location.lat,
            lng: response.data.candidates[0].geometry.location.lng
          })
        } else {
          toastr.error(
            'Verifique o endereço digitado e tente novamente',
            'Coordenadas não encontradas!'
          )
        }
      })
      .finally(() => setLoadingCoordinates(false))
  }

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-7">
        <h6 className="mb-0">Localização</h6>
        <Button
          onClick={getCoordinates}
          size="sm"
          disabled={disabled || loadingCoordinates}
        >
          {loadingCoordinates
            ? 'Carregando coordenadas...'
            : 'Carregar coordenadas'}
        </Button>
      </div>
      <div
        className="position-relative w-100"
        style={{ height: `${height}px` }}
      >
        {disabled && (
          <div
            className="w-100 h-100 position-absolute top-0 start-0 bg-white opacity-50"
            style={{ zIndex: 2 }}
          ></div>
        )}
        <div
          className="bg-white shadow-sm p-2 position-absolute w-240px"
          style={{
            zIndex: 1,
            borderBottomRightRadius: '15px'
          }}
        >
          <span className="text-dark-50 d-block">
            {latitudeLabel}: {latitude}
          </span>
          <span className="text-dark-50 d-block">
            {longitudeLabel}: {longitude}
          </span>
        </div>
        <i
          className="fas fa-map-marker-alt text-danger position-absolute"
          style={{
            zIndex: 1,
            top: 'calc(50% - 40px)',
            left: 'calc(50% - 15px)',
            fontSize: 40,
            userSelect: 'none',
            pointerEvents: 'none'
          }}
        ></i>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY,
            language: 'pt-BR',
            region: 'br',
            libraries: ['places']
          }}
          center={center}
          defaultZoom={mapData.zoom}
          options={mapData.options}
          resetBoundsOnResize
          onChange={handleOnChange}
          yesIWantToUseGoogleMapApiInternals
        />
      </div>
    </>
  )
}

const FormMapLocationController = memo(FormMapLocationControllerMemoized)

const FormMapLocationContainer = ({ ...rest }) => {
  const { control } = useFormContext()

  return <FormMapLocationController control={control} {...rest} />
}

export default FormMapLocationContainer
