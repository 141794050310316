import React from 'react'

import { useFormContext, useController } from 'react-hook-form'
import PropTypes from 'prop-types'
import { Form } from 'react-bootstrap'

import getInputClasses from './utils/getInputClasses'
import getRules from './utils/getRules'

const NumericInput = ({
  name,
  control,
  readOnly = false,
  step = 1,
  className = '',
  variant = 'primary',
  size,
  solid,
  white,
  flush,
  shadow,
  min,
  max,
  required,
  pattern,
  validate,
  defaultValue,
  ...rest
}) => {
  const { setValue } = useFormContext()

  const classes = getInputClasses({
    size,
    solid,
    white,
    flush,
    shadow,
    className,
    'px-12 text-center pe-7': true
  })

  const {
    field: { ref, value, onChange: onChangeField, ...inputProps },
    fieldState: { invalid, isTouched, isDirty },
    formState: { errors, touchedFields, dirtyFields }
  } = useController({
    name,
    control,
    rules: getRules({
      required,
      min,
      max,
      pattern,
      validate
    }),
    defaultValue
  })

  const handleChange = e => {
    onChangeField(e)
    onChange && onChange(e)
  }

  return (
    <div className={`position-relative ${className}`}>
      <button
        type="button"
        className={`btn btn-${variant} btn-sm btn-icon position-absolute top-50 start-0 translate-middle-y ms-1`}
        onClick={() => {
          setValue(
            name,
            min && parseInt(value) - step <= min ? min : parseInt(value) - step
          )
        }}
      >
        <i className="fas fa-minus fs-5"></i>
      </button>
      <Form.Control
        type="number"
        className={classes}
        value={value}
        onChange={handleChange}
        isInvalid={!!errors[name] || invalid}
        readOnly={readOnly}
        step={step}
        min={min}
        max={max}
        {...inputProps}
        {...rest}
      />
      <button
        type="button"
        className={`btn btn-${variant} btn-sm btn-icon position-absolute top-50 end-0 translate-middle-y me-1`}
        onClick={() =>
          setValue(
            name,
            max && parseInt(value) + step >= max ? max : parseInt(value) + step
          )
        }
      >
        <i className="fas fa-plus fs-5"></i>
      </button>
    </div>
  )
}

const NumericInputContainer = ({ name, ...rest }) => {
  const { control } = useFormContext()

  return <NumericInput name={name} control={control} {...rest} />
}

NumericInputContainer.propTypes = {
  name: PropTypes.string.isRequired
}

export default NumericInputContainer
