const messages = {
  required: () => 'Campo obrigatório',
  min: min => ({ value: min, message: `Valor deve ser no mínimo ${min}` }),
  max: max => ({ value: max, message: `Valor deve ser no máximo ${max}` }),
  minLength: minLength => ({
    value: minLength,
    message: `Campo deve ter pelo menos ${minLength} caracteres`
  }),
  maxLength: maxLength => ({
    value: maxLength,
    message: `Campo deve ter no máximo ${maxLength} caracteres`
  }),
  pattern: () => ''
}

const getRules = ({ required, min, max, minLength, maxLength, pattern }) => {
  const rules = {
    required: required ? messages.required(required) : required,
    min: min ? messages.min(min) : min,
    max: max ? messages.max(max) : max,
    minLength: minLength ? messages.minLength(minLength) : minLength,
    maxLength: maxLength ? messages.maxLength(maxLength) : maxLength,
    pattern: pattern ? messages.pattern(pattern) : pattern
  }

  return rules
}

export default getRules
