import dynamic from 'next/dynamic'
import { Helmet } from 'react-helmet'
import _ from 'lodash'

import { useAdminContext } from '../context/AdminContext'

import Sidebar from './Sidebar'
import Footer from './Footer'
import Header from './Header'
import ScrollTop from './ScrollTop'

const LayoutInit = dynamic(() => import('./LayoutInit'), { ssr: false })

const AdminLayout = ({ selfLayout, children }) => {
  const { brand, sidebar, getLogoIcon, containerFluid, header } =
    useAdminContext()
  const logoIcon = getLogoIcon()

  if (selfLayout === 'blank' || selfLayout) {
    const isComponent = _.isFunction(selfLayout)
    const SelfLayout = isComponent ? selfLayout : () => <></>

    return (
      <>
        <Helmet
          titleTemplate={`%s | ${brand?.name}`}
          defaultTitle={`${brand?.name}`}
        >
          <link rel="shortcut icon" href={logoIcon} />
          <meta name="description" content={brand?.description} />
          <html lang="pt_BR" />
          <script type="text/javascript">
            {`
                var defaultThemeMode = "light";
                var themeMode;

                if ( document.documentElement ) {
                  if ( document.documentElement.hasAttribute("data-bs-theme-mode")) {
                    themeMode = document.documentElement.getAttribute("data-bs-theme-mode");
                  } else {
                    if ( localStorage.getItem("data-bs-theme") !== null ) {
                      themeMode = localStorage.getItem("data-bs-theme");
                    } else {
                      themeMode = defaultThemeMode;
                    }
                  }

                  if (themeMode === "system") {
                    themeMode = window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light";
                  }

                  document.documentElement.setAttribute("data-bs-theme", themeMode);
                }
            `}
          </script>
        </Helmet>
        <div
          id="ttz_app_root"
          className="app-root d-flex flex-column flex-root"
        >
          <div
            id="ttz_app_page"
            className="app-page flex-column flex-column-fluid "
          >
            {isComponent ? <SelfLayout>{children}</SelfLayout> : children}
          </div>
        </div>
        <ScrollTop />
        <LayoutInit selfLayout />
      </>
    )
  }

  return (
    <>
      <Helmet
        titleTemplate={`%s | ${brand?.name}`}
        defaultTitle={`${brand?.name}`}
      >
        <link rel="shortcut icon" href={logoIcon} />
        <meta name="description" content={brand?.description} />
        <html lang="pt_BR" />
        <script type="text/javascript">
          {`
            var defaultThemeMode = "light";
            var themeMode;

            if ( document.documentElement ) {
              if ( document.documentElement.hasAttribute("data-bs-theme-mode")) {
                themeMode = document.documentElement.getAttribute("data-bs-theme-mode");
              } else {
                if ( localStorage.getItem("data-bs-theme") !== null ) {
                  themeMode = localStorage.getItem("data-bs-theme");
                } else {
                  themeMode = defaultThemeMode;
                }
              }

              if (themeMode === "system") {
                themeMode = window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light";
              }

              document.documentElement.setAttribute("data-bs-theme", themeMode);
            }
        `}
        </script>
      </Helmet>
      <div id="ttz_app_root" className="app-root d-flex flex-column flex-root">
        <div
          id="ttz_app_page"
          className="app-page flex-column flex-column-fluid "
        >
          {!header?.disabled && <Header />}
          <div
            id="ttz_app_wrapper"
            className="app-wrapper flex-column flex-row-fluid"
          >
            {!sidebar?.disabled && <Sidebar />}
            <div
              id="ttz_app_main"
              className="app-main flex-column flex-row-fluid"
            >
              <div className="d-flex flex-column flex-column-fluid">
                <div
                  id="ttz_app_content"
                  className="app-content flex-column-fluid"
                >
                  <div
                    id="ttz_app_content_container"
                    className={`app-container container${
                      containerFluid ? '-fluid' : ''
                    }`}
                  >
                    {children}
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </div>
      <ScrollTop />
      <LayoutInit />
    </>
  )
}

export default AdminLayout
