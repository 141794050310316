import * as yup from 'yup'

export const validationSchema = yup.object().shape({
  login: yup.string().nullable().required().label('Login'),
  password: yup
    .string()
    .nullable()
    .min(8, 'Digite no mínimo 8 caracteres')
    .max(128, 'Digite no máximo 128 caracteres')
    .matches(/^\S*$/, 'Senha não pdoeconter espaços')
    .required()
    .label('Senha')
})
