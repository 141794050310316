import React from 'react'

import getInputClasses from '../utils/getInputClasses'

import AsyncSelect from './AsyncSelect'
import Select from './Select'

const SelectWrapper = ({
  name,
  async,
  size,
  solid,
  white,
  flush,
  shadow,
  className,
  ...rest
}) => {
  const classes = getInputClasses({
    prefix: 'form-react-select',
    size,
    solid,
    white,
    flush,
    shadow,
    className
  })

  if (async)
    return <AsyncSelect name={name} className={classes} {...async} {...rest} />

  return <Select name={name} className={classes} {...rest} />
}

export default SelectWrapper
