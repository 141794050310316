import cardValidator from 'card-validator'
import _ from 'lodash'
import { useFormContext } from 'react-hook-form'
import * as yup from 'yup'
import { pt } from 'yup-locale-pt'

yup.setLocale(pt)

import { FormGroup, FormControl, InputMask, FormSwitch } from '../../form'
import americanExpressSvg from '../../public/credit-cards/american-express.svg'
import dinersSvg from '../../public/credit-cards/diners.svg'
import eloSvg from '../../public/credit-cards/elo.png'
import mastercardSvg from '../../public/credit-cards/mastercard.svg'
import visaSvg from '../../public/credit-cards/visa.svg'

const getValidationSchema = namePrefix => {
  let schema = yup.object().shape({
    full_name: yup
      .string()
      .nullable()
      .required()
      .test(
        'is-valid-card-holder-name',
        'Não é um nome válido',
        val => cardValidator.cardholderName(val).isValid
      )
      .label('Nome impresso no cartão'),
    number: yup
      .string()
      .nullable()
      .required()
      .test(
        'is-valid-credit-card',
        'Não é um número válido de cartão de crédito',
        val => val && cardValidator.number(val?.replace(/\D/g, '')).isValid
      )
      .test(
        'is-valid-type',
        'Somente são aceitos cartões Visa, Mastercard, American Express, Diners Club e Elo',
        val =>
          val &&
          [
            'visa',
            'mastercard',
            'american-express',
            'diners-club',
            'elo'
          ].includes(cardValidator.number(val.replace(/\D/g, ''))?.card?.type)
      )
      .label('Número'),

    expiration: yup
      .string()
      .nullable()
      .required()
      .test(
        'is-valid-expiration-date',
        'Não é uma validade válida',
        val => val && cardValidator.expirationDate(val).isValid
      )
      .label('Validade'),

    verification_value: yup
      .string()
      .nullable()
      .required()
      .test('len', 'Entre 3 e 4 dígitos', val =>
        val ? val.length >= 3 && val.length <= 4 : false
      )
      .label('CVV')
  })

  if (namePrefix) {
    const namePrefixes = _.isArray(namePrefix)
      ? namePrefix
      : namePrefix.split('.')
    namePrefixes.reverse().forEach(prefix => {
      schema = yup.object().shape({
        [prefix]: schema
      })
    })
  }

  return schema
}

const CreditCardFields = ({
  namePrefix: propsNamePrefix,
  showSaveAsDefault,
  withDescription,
  solid
}) => {
  const { watch } = useFormContext()

  const namePrefix = propsNamePrefix ? `${propsNamePrefix}.` : ''
  const watchCreditCardNumber = watch(`${namePrefix}number`)
  const creditCard = cardValidator.number(
    watchCreditCardNumber?.replace(/\D/g, '')
  )
  const creditCardFlags = {
    ['visa']: visaSvg,
    ['mastercard']: mastercardSvg,
    ['american-express']: americanExpressSvg,
    ['diners-club']: dinersSvg,
    ['elo']: eloSvg
  }
  const creditCardFlag = creditCardFlags[creditCard?.card?.type]

  return (
    <>
      {withDescription && (
        <FormGroup
          name={`${namePrefix}description`}
          label="Dê um nome para seu cartão"
          control={FormControl}
          vertical
          solid={solid}
          required
        />
      )}
      <div className="row">
        <div className="col-md-9">
          <FormGroup
            name={`${namePrefix}number`}
            label="Número"
            labelInfo="São aceitos cartões das bandeiras Visa, Mastercard, American Express, Diners Club Internacional e Elo"
            vertical
            solid={solid}
            required
          >
            <div className="position-relative">
              <InputMask
                name={`${namePrefix}number`}
                mask={'9999 9999 9999 9999'}
                alwaysShowMask
                maskChar=""
                solid={solid}
                required
                autoComplete="cc-number"
              />
              <div className="position-absolute translate-middle-y top-50 end-0 me-5">
                {creditCardFlag && (
                  <>
                    <img
                      {...creditCardFlag}
                      alt="Visa"
                      className="h-25px w-50px obj-fit-contain"
                    />
                  </>
                )}
              </div>
            </div>
          </FormGroup>
        </div>
        <div className="col-md-3">
          <FormGroup
            name={`${namePrefix}verification_value`}
            label={_.get(creditCard, 'card.code.name', 'CVV')}
            control={FormControl}
            vertical
            solid={solid}
            required
            autoComplete="cc-csc"
          />
        </div>
        <div className="col-md-8">
          <FormGroup
            name={`${namePrefix}full_name`}
            label="Nome impresso no cartão"
            control={FormControl}
            className="text-uppercase"
            vertical
            solid={solid}
            required
            autoComplete="cc-name"
          />
        </div>
        <div className="col-md-4">
          <FormGroup
            name={`${namePrefix}expiration`}
            label="Validade"
            control={InputMask}
            mask={'99/99'}
            placeholder="MM/YY"
            vertical
            solid={solid}
            required
            autoComplete="cc-exp"
          />
        </div>
      </div>
      {showSaveAsDefault && (
        <div className="col-12">
          <div className="d-flex flex-stack">
            <div className="me-5">
              <label className="fs-6 fw-bold form-label">
                Salvar como principal?
              </label>
              <div className="fs-4 fw-bold text-muted">
                Quando você precisar efetuar novas compras, este cartão será
                usado
              </div>
            </div>
            <FormSwitch name={`${namePrefix}default`} defaultValue={true} />
          </div>
        </div>
      )}
    </>
  )
}

CreditCardFields.getValidationSchema = getValidationSchema
CreditCardFields.validationSchema = getValidationSchema

export default CreditCardFields
