// import React, { useState } from "react";
// import dynamic from "next/dynamic";
// import { Controller, useFormContext } from "react-hook-form";
// import { EditorState } from "draft-js";
// import { convertToHTML, convertFromHTML } from "draft-convert";

// const Editor = dynamic(
//   import("react-draft-wysiwyg").then((module) => module.Editor),
//   { ssr: false }
// );

// const ContentEditor = ({ name, onChange, value, ...rest }) => {
//   const { control, getValues } = useFormContext();
//   const [editorState, setEditorState] = useState(() =>
//     EditorState.createWithContent(
//       convertFromHTML(getValues(name) ? getValues(name) : "")
//     )
//   );

//   return (
//     <Controller
//       name={name}
//       control={control}
//       render={({ field: { onChange, value, ...rest } }) => (
//         <Editor
//           wrapperClassName="editor-wrapper"
//           editorClassName="editor"
//           editorState={editorState}
//           onEditorStateChange={(params) => {
//             setEditorState(params);
//             onChange(convertToHTML(params.getCurrentContent()));
//           }}
//           {...rest}
//         />
//       )}
//       {...rest}
//     />
//   );
// };

// export default ContentEditor;

const ContentEditorPlaceholder = () => {
  return <></>
}

export default ContentEditorPlaceholder
