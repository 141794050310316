import PropTypes from 'prop-types'
import Form from 'react-bootstrap/Form'
import { useFormContext, useController } from 'react-hook-form'
import _ from 'lodash'

import getInputClasses from './utils/getInputClasses'
import getRules from './utils/getRules'

const FormControl = ({
  name,
  control,
  size,
  solid,
  white,
  flush,
  shadow,
  className,
  defaultValue = '',
  required,
  min,
  max,
  minLength,
  maxLength,
  pattern,
  validate,
  onChange,
  beforeChange,
  ...rest
}) => {
  const {
    field: { ref, value, onChange: onChangeField, ...inputProps },
    fieldState: { invalid, isTouched, isDirty },
    formState: { errors, touchedFields, dirtyFields }
  } = useController({
    name,
    control,
    rules: getRules({
      required,
      min,
      max,
      minLength,
      maxLength,
      pattern,
      validate
    }),
    defaultValue
  })
  const classes = getInputClasses({
    size,
    solid,
    white,
    flush,
    shadow,
    className
  })
  const handleChange = e => {
    let event = e

    if (beforeChange && _.isFunction(beforeChange)) event = beforeChange(event)

    onChangeField(event)
    onChange && onChange(event)
  }

  return (
    <Form.Control
      ref={ref}
      className={classes}
      value={value}
      onChange={handleChange}
      isInvalid={!!errors[name] || invalid}
      {...inputProps}
      {...rest}
      onWheel={e => (rest.type == 'number' ? e.currentTarget.blur() : null)}
    />
  )
}

const FormControlContainer = ({ name, ...rest }) => {
  const { control } = useFormContext()

  return <FormControl name={name} control={control} {...rest} />
}

FormControlContainer.propTypes = {
  name: PropTypes.string.isRequired
}

export default FormControlContainer
