import { useEffect, useState } from 'react'

import _ from 'lodash'
import { DateTime } from 'luxon'
import Countdown from 'react-countdown'
import VerificationInput from 'react-verification-input'
import toastr from 'toastr'
import Swal from 'sweetalert2'

import { showErrorAlert } from '@tootz/react-admin'

import { getErrorMessage } from '../../../services/getErrorMessage'
import { useAuthContext } from '../context'

const isDev = process.env.NODE_ENV == 'development'

export const ConfirmationFormBody = ({
  onValidateToken,
  resendTokenApiUrl = '/auth/request-confirmation-token'
}) => {
  const {
    isLoading,
    setIsLoading,
    httpClient,
    styleConfig,
    currentEmail,
    goToSignIn,
    currentPassword
  } = useAuthContext()
  const [token, setToken] = useState('')
  const tokenIsValid = token && token.length >= 6
  const [resendToken, setResendToken] = useState({
    iterator: 1,
    date: DateTime.local().toISO()
  })
  const [name, domain] = currentEmail ? currentEmail.split('@') : []
  const hiddenEmail = isDev
    ? currentEmail
    : name &&
      currentEmail &&
      `${name[0]}${new Array(name.length).join('*')}@${domain}`

  const handleResendToken = async () => {
    if (isLoading) return

    setIsLoading(true)

    try {
      await httpClient.post(resendTokenApiUrl, {
        email: currentEmail
      })

      toastr.success('Novo código enviado com sucesso')

      setResendToken(prevState => ({
        iterator: prevState.iterator + 1,
        date: DateTime.local()
          .plus({ seconds: 30 * prevState.iterator })
          .toISO()
      }))
    } catch (error) {
      const { message } = getErrorMessage(error)

      if (message == 'email já foi confirmado') {
        await Swal.fire({
          icon: 'info',
          title: 'Email já confirmado',
          text: 'Seu email já foi confirmado, você pode fazer login agora'
        })

        goToSignIn({ filled: true })
      } else showErrorAlert(error)
    }

    setIsLoading(false)
  }
  const handleValidateToken = () => {
    if (!tokenIsValid) return

    onValidateToken(token)
    setToken('')
  }

  useEffect(() => {
    if (tokenIsValid) handleValidateToken()
  }, [tokenIsValid])

  return (
    <div className="d-flex flex-center flex-column flex-column-fluid px-lg-10 pb-15 pb-lg-20">
      <div className="text-center mb-10">
        <svg
          width="162"
          height="162"
          viewBox="0 0 162 162"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="mh-125px"
        >
          <path
            d="M49.4498 17.217C48.1718 17.217 46.9262 17.667 46.046 18.5454C45.164 19.4202 44.714 20.6748 44.714 21.9492V140.123C44.714 141.397 45.164 142.643 46.046 143.525C46.9262 144.407 48.1718 144.857 49.4498 144.857H112.666C113.94 144.857 115.197 144.407 116.068 143.525C116.952 142.643 117.4 141.397 117.4 140.123V21.9492C117.4 20.6748 116.95 19.4202 116.068 18.5454C115.197 17.6652 113.94 17.217 112.666 17.217H102.906C102.138 17.217 101.454 17.676 101.164 18.387L99.7094 21.9492C99.2396 23.1138 98.867 23.6844 98.273 24.0696C97.6934 24.4638 96.662 24.7914 94.637 24.7914H67.475C65.4518 24.7914 64.4204 24.462 63.839 24.0696C63.2486 23.6862 62.8724 23.1156 62.4044 21.9492L60.9518 18.387C60.6674 17.6832 59.9834 17.217 59.2256 17.217H49.4498Z"
            fill="#9EDCFF"
          />
          <path
            d="M49.1649 13.176H112.946C117.55 13.176 121.185 16.83 121.185 21.4254V140.647C121.185 145.24 117.549 148.898 112.946 148.898H49.1649C44.5641 148.898 40.9245 145.24 40.9245 140.647V21.4254C40.9263 16.8318 44.5641 13.176 49.1649 13.176ZM49.1649 9.44458C42.5589 9.44458 37.1841 14.8194 37.1841 21.4254V140.647C37.1841 147.256 42.5589 152.627 49.1649 152.627H112.946C119.556 152.627 124.936 147.256 124.936 140.647V21.4254C124.936 14.8194 119.556 9.44458 112.946 9.44458H49.1649Z"
            fill="#7E94B5"
          />
          <path
            d="M75.0235 17.217C73.9813 17.217 73.1497 18.0486 73.1497 19.0908C73.1497 20.1312 73.9813 20.9664 75.0235 20.9664H88.0357C89.0671 20.9664 89.9113 20.1312 89.9113 19.0908C89.9113 18.0486 89.0671 17.217 88.0357 17.217H75.0235Z"
            fill="#B5B5C3"
          />
        </svg>
      </div>
      <div className="text-center mb-10">
        <h1 className="text-dark mb-3">Etapa de confirmação</h1>
        <div className="text-muted fw-semibold fs-5 mb-5">
          Digite o código de confirmação enviado para
        </div>
        <div className="fw-bold text-dark fs-3">{hiddenEmail}</div>
        {isDev && (
          <div className="fw-bold text-dark fs-3">{currentPassword}</div>
        )}
      </div>
      <div className="mb-10">
        <div className="fw-bold text-start text-dark fs-6 mb-2 ms-1 d-block">
          Digite seu código de 6 dígitos
        </div>
        <VerificationInput
          removeDefaultStyles
          classNames={{
            container:
              'd-flex flex-stack mb-2 h-50px gap-2 h-lg-60px min-w-300px w-100',
            character: `form-control h-lg-60px w-lg-60px fs-2qx fw-bold text-center m-0 form-control-${styleConfig.style}`,
            characterInactive: '',
            characterSelected: 'border-primary'
          }}
          placeholder=""
          value={token}
          onChange={value => setToken(value)}
          inputProps={{
            inputMode: 'numeric',
            pattern: '[0-9]*'
          }}
          disabled={isLoading}
        />
      </div>
      <div className="d-flex mb-13">
        <button
          type="button"
          onClick={handleValidateToken}
          disabled={!tokenIsValid}
          className="btn btn-lg btn-primary fw-bold"
        >
          {tokenIsValid ? 'Confirmar conta' : 'Digite o código'}
        </button>
      </div>
      <div className="text-center fw-semibold fs-5">
        <span className="text-muted me-1">
          Não recebeu o código? Peça um novo
        </span>

        <Countdown
          key={resendToken.iterator}
          date={resendToken.date}
          renderer={({ seconds, completed }) => (
            <>
              {completed ? (
                <span
                  className="cursor-pointer text-primary fw-bold"
                  onClick={handleResendToken}
                >
                  clicando aqui
                </span>
              ) : (
                <>em {seconds} segundos</>
              )}
            </>
          )}
        />
      </div>
    </div>
  )
}
