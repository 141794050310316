import React from 'react'

import _ from 'lodash'
import PropTypes from 'prop-types'
import { useController, useFormContext } from 'react-hook-form'
import IntlCurrencyInput from 'react-intl-currency-input'

import getInputClasses from './utils/getInputClasses'
import getRules from './utils/getRules'

// To do get by props another currencies partners like USD, EUR and etc.
const defaultConfig = {
  locale: 'pt-BR',
  formats: {
    number: {
      BRL: {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      },
      USD: {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        currencyDisplay: 'narrowSymbol'
      }
    }
  }
}

const MoneyInput = ({
  name,
  control,
  currency = 'BRL',
  asCents = true,
  config: _config,
  size,
  solid,
  white,
  flush,
  shadow,
  className,
  defaultValue = 0,
  required,
  min,
  max,
  minLength,
  maxLength,
  pattern,
  validate,
  onChange,
  ...rest
}) => {
  const {
    field: { ref, value, onChange: onChangeField, ...inputProps },
    fieldState: { invalid, isTouched, isDirty },
    formState: { errors, touchedFields, dirtyFields }
  } = useController({
    name,
    control,
    rules: getRules({
      required,
      min,
      max,
      minLength,
      maxLength,
      pattern,
      validate
    }),
    defaultValue
  })
  const config = { ...defaultConfig, ..._config }
  const classes = getInputClasses({
    size,
    solid,
    white,
    flush,
    shadow,
    className
  })
  const toValue = value => {
    if (asCents) return Math.round(value * 100)
    return value
  }
  const fromValue = value => {
    if (asCents) return value / 100
    return _.isString(value) ? parseFloat(value) : value
  }
  const handleChange = (event, value, maskedValue) => {
    event.preventDefault()
    const v = toValue(value)
    onChangeField(v)
    onChange && onChange(v)
  }

  return (
    <IntlCurrencyInput
      className={classes}
      config={config}
      currency={currency}
      onChange={handleChange}
      value={fromValue(value)}
      {...inputProps}
      {...rest}
    />
  )
}

const MoneyInputContainer = ({ name, ...rest }) => {
  const { control } = useFormContext()

  return <MoneyInput name={name} control={control} {...rest} />
}

MoneyInputContainer.propTypes = {
  name: PropTypes.string.isRequired
}

export default MoneyInputContainer
