import { useState } from 'react'

import PasswordStrengthBar from 'react-password-strength-bar'
import _ from 'lodash'

import {
  FormControl,
  FormGroup,
  InputGroup,
  FormCheck,
  ErrorMessage
} from '@tootz/react-admin/form'

import { useAuthContext } from '../../context'

import { getValidationSchema } from './validationSchema'

export const SignUpFormFields = ({ withTerms, children }) => {
  const { styleConfig, config } = useAuthContext()
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [showPasswordConfirmation, setShowPasswordConfirmation] =
    useState(false)

  const withEmailConfirmation = _.get(
    config,
    'signUp.withEmailConfirmation',
    false
  )

  return (
    <>
      <FormGroup
        label="Nome completo"
        labelClassName="fw-bolder"
        name="name"
        size={styleConfig.size}
        solid={styleConfig.solid ? true : undefined}
        control={FormControl}
        vertical
      />
      <FormGroup
        label="E-mail"
        labelClassName="fw-bolder"
        name="email"
        inputMode="email"
        size={styleConfig.size}
        solid={styleConfig.solid ? true : undefined}
        control={FormControl}
        vertical
      />
      {withEmailConfirmation && (
        <FormGroup
          label="Confirmação de e-mail"
          labelClassName="fw-bolder"
          name="email_confirmation"
          inputMode="email"
          size={styleConfig.size}
          solid={styleConfig.solid ? true : undefined}
          control={FormControl}
          vertical
        />
      )}
      <FormGroup
        label="Senha"
        labelClassName="fw-bolder"
        name="password"
        helpText="Use 8 ou mais caracteres."
        vertical
        size={styleConfig.size}
        solid={styleConfig.solid ? true : undefined}
      >
        <InputGroup
          size={styleConfig.size}
          solid={styleConfig.solid ? true : undefined}
        >
          <FormControl
            name="password"
            onChange={e => setPassword(e.target.value)}
            type={showPassword ? 'text' : 'password'}
            min={8}
            max={128}
            size={styleConfig.size}
            solid={styleConfig.solid ? true : undefined}
          />
          <button
            type="button"
            className={`btn btn-icon btn-${styleConfig.btnStyle} bg-hover-light btn-${styleConfig.size}`}
            onClick={() => setShowPassword(prevState => !prevState)}
          >
            <i className={`fad fa-${showPassword ? 'eye' : 'eye-slash'}`} />
          </button>
        </InputGroup>
        <PasswordStrengthBar
          password={password}
          min={8}
          max={128}
          scoreWords={false}
          shortScoreWord={false}
        />
      </FormGroup>
      <FormGroup
        label="Confirme sua senha"
        labelClassName="fw-bolder"
        className="position-relative"
        name="password_confirmation"
        vertical
      >
        <InputGroup
          size={styleConfig.size}
          solid={styleConfig.solid ? true : undefined}
        >
          <FormControl
            name="password_confirmation"
            type={showPasswordConfirmation ? 'text' : 'password'}
            size={styleConfig.size}
            solid={styleConfig.solid ? true : undefined}
            required
          />
          <button
            type="button"
            className={`btn btn-icon btn-${styleConfig.btnStyle} bg-hover-light btn-${styleConfig.size}`}
            onClick={() => setShowPasswordConfirmation(prevState => !prevState)}
          >
            <i
              className={`fad fa-${
                showPasswordConfirmation ? 'eye' : 'eye-slash'
              }`}
            />
          </button>
        </InputGroup>
      </FormGroup>

      {children}

      {!!withTerms && _.isString(withTerms) && (
        <div className="mb-6 mt-n7">
          <FormCheck
            name="accept_terms"
            required
            label={
              <>
                Declaro que li e concordo integralmente com os
                <br />{' '}
                <a
                  href={withTerms}
                  target="_blank"
                  rel="noreferrer"
                  className="fw-bold"
                >
                  Termos de Uso{' '}
                  <span className="fw-normal"> (clique para ler)</span>
                </a>{' '}
                da plataforma
              </>
            }
          />
          <ErrorMessage name="accept_terms" />
        </div>
      )}
    </>
  )
}

SignUpFormFields.validationSchema = getValidationSchema
