import { useRef } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import _ from 'lodash'
import { useForm, FormProvider } from 'react-hook-form'
import { Button } from 'react-bootstrap'
import ReCAPTCHA from 'react-google-recaptcha'

import { showErrorAlert } from '../../../services/showErrorAlert'
import { getErrorMessage } from '../../../services/getErrorMessage'
import { Overlay } from '../../components/Overlay'
import { useAuthContext } from '../context'

import { ForgotPasswordFormFields } from './Fields'

const ForgotPasswordForm = () => {
  const reCaptchaRef = useRef()
  const {
    httpClient,
    isLoading,
    styleConfig,
    config,
    goToSignIn,
    setCurrentEmail,
    reCaptchaEnabled,
    goToResetPassword,
    getDefaultValues,
    useReCaptchaV3,
    reCaptchaKey,
    setIsLoading
  } = useAuthContext()
  const header =
    _.has(config, 'forgotPassword.header') &&
    _.get(config, 'forgotPassword.header', {})
  const methods = useForm({
    defaultValues: getDefaultValues(),
    resolver: yupResolver(ForgotPasswordFormFields.validationSchema)
  })

  const handleRequestPasswordReset = async data => {
    if (isLoading) return

    setIsLoading(true)
    const { email } = data

    setCurrentEmail(email)

    try {
      await httpClient.post('/auth/reset-password', {
        email
      })

      goToResetPassword({ email })
    } catch (error) {
      const { errors } = getErrorMessage(error)

      if (_.get(errors, 'errors.email'))
        methods.setError('email', {
          type: 'manual',
          message: `E-mail ${_.get(errors, 'errors.email', []).join(', ')}`
        })
      else showErrorAlert(error)
    }

    setIsLoading(false)
  }

  return (
    <Overlay
      block
      active={isLoading}
      opacity={50}
      layer={
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Carregando...</span>
        </div>
      }
    >
      {header && (
        <div className="text-center mb-11">
          <h1 className="text-dark fw-bolder mb-3">{header.title}</h1>
          <div className="text-gray-500 fw-semibold fs-6">
            {header.subtitle}
          </div>
        </div>
      )}

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleRequestPasswordReset)}>
          <ForgotPasswordFormFields />

          {reCaptchaEnabled && (
            <div className="d-flex align-items-center justify-content-center w-100">
              <ReCAPTCHA
                ref={reCaptchaRef}
                sitekey={reCaptchaKey}
                size={useReCaptchaV3 ? 'invisible' : 'normal'}
              />
            </div>
          )}
          <div className="d-flex justify-content-center align-items-center gap-6 mb-10 mt-8">
            <button
              type="button"
              onClick={goToSignIn}
              className={`btn btn-light btn-${styleConfig.inputsSize} flex-grow-1`}
              disabled={isLoading}
            >
              Cancelar
            </button>
            <Button
              type="submit"
              variant="primary"
              size={styleConfig.size}
              solid={styleConfig.solid ? true : undefined}
              disabled={isLoading}
              className="flex-grow-1"
            >
              {isLoading ? (
                <>
                  <span>
                    <i className="fad fa-spinner-third fa-spin"></i>
                  </span>{' '}
                  Resetando...
                </>
              ) : (
                'Resetar'
              )}
            </Button>
          </div>
        </form>
      </FormProvider>
    </Overlay>
  )
}

export default ForgotPasswordForm
