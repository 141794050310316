import React, { useCallback } from 'react'

import Tags from '@yaireo/tagify/dist/react.tagify'
import { useController, useFormContext } from 'react-hook-form'

import getInputClasses from './utils/getInputClasses'

const defaultSettings = {
  delimiters: ',|;'
}

const FormTags = ({
  name,
  control,
  valuesSeparator = ';',
  size,
  solid,
  white,
  flush,
  shadow,
  className,
  defaultValue = '',
  required,
  validate,
  onChange,
  settings,
  formatter = {
    toValue: (values, separator) => values.join(separator),
    fromValue: (values, separator) => values
  },
  ...rest
}) => {
  const {
    field: { ref, value, onChange: onChangeField, ...inputProps },
    fieldState: { invalid, isTouched, isDirty },
    formState: { errors, touchedFields, dirtyFields }
  } = useController({
    name,
    control,
    rules: { required, validate },
    defaultValue
  })
  const _settings = {
    ...defaultSettings,
    ...settings
  }
  const classes = getInputClasses({
    size,
    solid,
    white,
    flush,
    shadow,
    className
  })

  const handleChange = useCallback(event => {
    let values = []

    try {
      values = event.detail.tagify.getCleanValue().map(opt => opt.value)
    } catch (error) {}

    const formattedValues = formatter.toValue(values, valuesSeparator)
    onChangeField(formattedValues)
    onChange && onChange(formattedValues)
  }, [])

  return (
    <Tags
      className={classes.split(' ')}
      settings={_settings}
      onChange={handleChange}
      value={formatter.fromValue(value, valuesSeparator)}
      isInvalid={!!errors[name] || invalid}
      {...inputProps}
      {...rest}
    />
  )
}

const FormTagsContainer = ({ name, ...rest }) => {
  const { control } = useFormContext()

  return <FormTags name={name} control={control} {...rest} />
}

export default FormTagsContainer
