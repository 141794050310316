import React, { Fragment } from 'react'

import _ from 'lodash'
import PropTypes from 'prop-types'
import { useController, useFormContext } from 'react-hook-form'

const BtnGroupCheckController = ({
  name,
  options,
  onChange,
  rules,
  defaultValue,
  btnClasses,
  size,
  solid,
  white,
  flush,
  className,
  control
}) => {
  const {
    field: { ref, value: values, onChange: onChangeInput },
    fieldState: { invalid, isTouched, isDirty },
    formState: { touchedFields, dirtyFields }
  } = useController({
    name,
    control,
    rules,
    defaultValue
  })

  const getClasses = () => {
    const classes = []

    classes.push('form-btn-group-radio')

    if (size) classes.push(`form-btn-group-radio-${size}`)
    if (solid) classes.push('form-btn-group-radio-solid')
    if (white) classes.push('form-btn-group-radio-white')
    if (flush) classes.push('form-btn-group-radio-flush')

    if (className) classes.push(className)

    return classes.join(' ')
  }

  const handleChange = (value, bool) => {
    const valuesCopy = _.isArray(values) ? values.slice() : []
    const valueIndex = valuesCopy.findIndex(v => v == value)

    if (valueIndex !== -1) valuesCopy.splice(valueIndex, 1)
    else valuesCopy.push(value)

    onChangeInput(valuesCopy)
    onChange && onChange(valuesCopy)
  }

  return (
    <div className={getClasses()} role="group">
      {options.map(option => (
        <Fragment key={`${name}_${option.value}`}>
          <input
            id={`${name}_${option.value}`}
            name={name}
            value={option.value}
            type="checkbox"
            className="btn-check "
            autoComplete="off"
            checked={
              values &&
              values.find(v => v.toString() === option.value.toString())
            }
            onChange={e => handleChange(option.value, e.target.checked)}
          />
          <label
            className="btn btn-active-primary"
            htmlFor={`${name}_${option.value}`}
          >
            {option.label}
          </label>
        </Fragment>
      ))}
    </div>
  )
}

const BtnGroupCheckContainer = ({ name, ...rest }) => {
  const { control } = useFormContext()

  return <BtnGroupCheckController name={name} control={control} {...rest} />
}

BtnGroupCheckContainer.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired
}

export default BtnGroupCheckContainer
