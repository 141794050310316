import React from 'react'

import _ from 'lodash'
import PropTypes from 'prop-types'
import { useFormContext, useController } from 'react-hook-form'
import ReactSelect from 'react-select'

import getRules from '../utils/getRules'

const Select = ({
  name,
  control,
  options,
  multiple,
  isClearable,
  disabled,
  placeholder: propsPlaceholder,
  className = '',
  defaultValue = '',
  required,
  min,
  max,
  minLength,
  maxLength,
  pattern,
  validate,
  onChange,
  ...rest
}) => {
  const {
    field: { ref, value, onChange: onChangeField, ...inputProps },
    fieldState: { invalid, isTouched, isDirty },
    formState: { errors, touchedFields, dirtyFields }
  } = useController({
    name,
    control,
    rules: getRules({
      required,
      min,
      max,
      minLength,
      maxLength,
      pattern,
      validate
    }),
    defaultValue: defaultValue ? defaultValue : multiple ? [] : ''
  })

  const placeholder = propsPlaceholder
    ? propsPlaceholder
    : `Selecione ${multiple ? 'uma ou mais opções' : 'uma opção'}`

  const getValue = value => {
    // In this function, never return undefined,
    // the component only accepts null as a empty option
    // or an empty array when multiple is true
    if (value == null || value == undefined) return multiple ? [] : null

    if (multiple) return options.filter(o => value.includes(o.value))
    else {
      const option = options.find(o => o.value == value)

      return !option ? null : option
    }
  }

  const handleChange = selection => {
    let newValue = selection?.value ?? null
    if (selection && multiple) newValue = selection.map(s => s.value)

    onChangeField(newValue)
    onChange && onChange(newValue)
  }

  return (
    <ReactSelect
      ref={ref}
      onChange={selection => handleChange(selection)}
      isMulti={multiple}
      options={options}
      placeholder={placeholder}
      value={getValue(value)}
      className={className}
      classNamePrefix="form-react-select"
      isClearable={isClearable}
      isDisabled={disabled}
      isInvalid={!!errors[name] || invalid}
      {...inputProps}
      {...rest}
    />
  )
}

const SelectContainer = ({ name, ...rest }) => {
  const { control } = useFormContext()

  return <Select name={name} control={control} {...rest} />
}

SelectContainer.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired
    })
  ),
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
  ])
}

export default SelectContainer
