import React, { useState, useEffect } from 'react'

import classNames from 'classnames'
import _ from 'lodash'
import PropTypes from 'prop-types'
import InputGroup from 'react-bootstrap/InputGroup'
import { useFormContext, useController } from 'react-hook-form'
import toastr from 'toastr'

import { useDataProviderContext } from '../../context/DataProviderContext'

import fileToBase64 from './utils/fileToBase64'
import getInputClasses from './utils/getInputClasses'
import getRules from './utils/getRules'

const FileInputInner = ({
  name,
  control,

  size,
  solid,
  white,
  flush,
  shadow,

  className = '',
  defaultValue = '',

  srcName,
  multiple,
  image,
  faIconPrefix = 'fad',
  mediaApiScope = 'company',
  base64AsValue,
  customHandleFiles,
  disableView,
  setRoleObject,
  uploadArgs = {},

  required,
  pattern,
  validate,
  onChange,
  disabled = false
}) => {
  const { setValue, getValues } = useFormContext()
  const record = getValues()
  const {
    field: { ref, value, onChange: onChangeField, ...inputProps },
    fieldState: { invalid, isTouched, isDirty },
    formState: { errors, touchedFields, dirtyFields }
  } = useController({
    name,
    control,
    rules: getRules({
      required,
      pattern,
      validate
    }),
    defaultValue
  })
  const { httpClient } = useDataProviderContext()
  const [files, setFiles] = useState(multiple ? [] : null)
  const inputGroupclasses = getInputClasses({
    prefix: 'input-group',
    size,
    solid,
    white,
    flush,
    shadow
  })
  const classes = getInputClasses({
    size,
    solid,
    white,
    flush,
    shadow,
    className
  })
  const btnClasses = classNames('btn', {
    'btn-outline btn-outline-secondary': !solid && !white,
    'btn-white': white
  })
  const handleFiles = async (files, multiple) => {
    if (!files) return multiple ? [] : null

    let filesInBase64 = await Promise.all(
      Array.from(files).map(async file => ({
        data: await fileToBase64(file),
        filename: file.name,
        size: file.size,
        content_type: file.type
      }))
    )

    try {
      if (base64AsValue) filesInBase64 = filesInBase64.map(file => file.data)
      const value = multiple ? filesInBase64 : filesInBase64[0]
      const res = await httpClient.post(
        `${mediaApiScope ? '/' + mediaApiScope : ''}/medias`,
        {
          record: {
            file: value,
            ...(uploadArgs || {})
          }
        }
      )
      const record = _.get(res, 'data.record')

      if (setRoleObject) setValue(name.replace('_id', ''), record)

      return record?.id
    } catch (error) {
      toastr.error(
        `Ocorreu um erro inesperado ao fazer o upload da mídia, tente novamente mais tarde</br><small>Error: ${error.message}</small>`,
        'Ooops'
      )

      return multiple ? [] : null
    }
  }
  const handleChange = async e => {
    setFiles(e.target.value)

    const _handleFiles = customHandleFiles ? customHandleFiles : handleFiles
    const value = await _handleFiles(e.target.files, multiple)

    onChangeField(value)
    onChange && onChange(value)
  }
  const handleRemoveImage = () => {
    setFiles(multiple ? [] : null)
    // setDefaultValue('')
  }
  const fileUrls = files
    ? multiple
      ? files.map(f => URL.createObjectURL(f))
      : ['']
    : multiple
    ? _.get(
        record,
        srcName ? srcName : `${name.replace('_id', '')}.file.url`,
        []
      )
    : [
        _.get(
          record,
          srcName ? srcName : `${name.replace('_id', '')}.file.url`,
          ''
        )
      ]
  const openFile = () => {
    if (image) {
      $.fancybox.open(
        fileUrls.map(fileUrl => ({ src: fileUrl })),
        {
          hash: false
        }
      )
    } else {
      window.open(fileUrls[0], '_blank')
    }
  }

  const texts = {
    btnIcon: image
      ? `image${multiple && fileUrls.length > 1 ? 's' : ''}`
      : 'file-alt',
    btnText: image
      ? `Visualizar ${
          multiple && fileUrls.length > 1 ? fileUrls.length : ''
        } image${multiple && fileUrls.length > 1 ? 'ns' : 'm'}`
      : `Visualizar ${
          multiple && fileUrls.length > 1 ? fileUrls.length : ''
        } arquivo${multiple && fileUrls.length > 1 ? 's' : ''}`,
    labelText: image
      ? `Selecione a${multiple ? 's' : ''} image${multiple ? 'ns' : 'm'}`
      : `Selecione o${multiple ? 's' : ''} arquivo${multiple ? 's' : ''}`
  }

  return (
    <InputGroup size={size} className={inputGroupclasses}>
      {fileUrls &&
        fileUrls.length > 0 &&
        fileUrls.find(file => !!file) &&
        !disableView && (
          <button
            type="button"
            className={`${btnClasses} flex-shrink-0`}
            onClick={openFile}
          >
            <i className={`${faIconPrefix} fa-${texts.btnIcon}`} />
            {texts.btnText}
          </button>
        )}
      <input
        type="file"
        name={name}
        multiple={multiple}
        onChange={handleChange}
        className={classes}
        disabled={disabled}
      />
    </InputGroup>
  )
}

const FileInput = ({ name, ...rest }) => {
  const { control } = useFormContext()

  return <FileInputInner name={name} control={control} {...rest} />
}
FileInput.propTypes = {
  name: PropTypes.string.isRequired
}

export default FileInput
