import { useState } from 'react'

import { Form } from 'react-bootstrap'
import _ from 'lodash'
import { useFormContext } from 'react-hook-form'

import {
  ErrorMessage,
  FormControl,
  FormGroup,
  InputGroup
} from '@tootz/react-admin/form'

import { useAuthContext } from '../../context'

import { validationSchema } from './validationSchema'

export const SignInFormFields = () => {
  const { styleConfig, goToForgotPassword, config } = useAuthContext()
  const { getValues } = useFormContext()
  const [seePassword, setSeePassword] = useState(false)
  const signInLoginLabel = _.get(config, 'signIn.loginLabel', 'Login')
  const hideForgotPassword = !_.has(config, 'forgotPassword')

  return (
    <>
      <FormGroup
        label={signInLoginLabel}
        labelClassName="fw-bolder"
        name="login"
        size={styleConfig.size}
        solid={styleConfig.solid ? true : undefined}
        control={FormControl}
        vertical
      />
      <div className="fv-row mb-3">
        <Form.Label className="fw-bolder">Senha</Form.Label>
        <InputGroup
          size={styleConfig.size}
          solid={styleConfig.solid ? true : undefined}
        >
          <FormControl
            name="password"
            type={`${seePassword ? 'text' : 'password'}`}
            size={styleConfig.size}
            solid={styleConfig.solid ? true : undefined}
          />
          <button
            type="button"
            className={`btn btn-icon btn-${styleConfig.btnStyle} bg-hover-light btn-${styleConfig.size}`}
            onClick={() => setSeePassword(!seePassword)}
          >
            <i className="fa-solid fa-eye" />
          </button>
        </InputGroup>
        <ErrorMessage name="password" />
      </div>

      {!hideForgotPassword && (
        <div className="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-2">
          <button
            type="button"
            className="btn btn-flush link-primary fs-6 fw-bold ms-auto"
            onClick={() => goToForgotPassword({ email: getValues('login') })}
          >
            Esqueceu a senha?
          </button>
        </div>
      )}
    </>
  )
}

SignInFormFields.validationSchema = validationSchema
