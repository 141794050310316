import React from 'react'

import PropTypes from 'prop-types'
import { useController, useFormContext } from 'react-hook-form'
import ReactInputMask from 'react-input-mask'

import getInputClasses from './utils/getInputClasses'
import getRules from './utils/getRules'

const InputMask = ({
  name,
  control,
  mask,
  size,
  solid,
  white,
  flush,
  shadow,
  className,
  defaultValue = '',
  required,
  min,
  max,
  minLength,
  maxLength,
  pattern,
  validate,
  onChange,
  ...rest
}) => {
  const {
    field: { ref, value, onChange: onChangeField, ...inputProps },
    fieldState: { invalid, isTouched, isDirty },
    formState: { errors, touchedFields, dirtyFields }
  } = useController({
    name,
    control,
    rules: getRules({
      required,
      min,
      max,
      minLength,
      maxLength,
      pattern,
      validate
    }),
    defaultValue
  })
  const classes = getInputClasses(
    {
      size,
      solid,
      white,
      flush,
      shadow,
      className
    },
    !!errors[name] || invalid
  )
  const handleChange = (e, a) => {
    onChangeField(e)
    onChange && onChange(e)
  }

  return (
    <ReactInputMask
      mask={mask}
      className={classes}
      value={value}
      onChange={handleChange}
      {...inputProps}
      {...rest}
    />
  )
}

const InputMaskContainer = ({ name, mask, ...rest }) => {
  const { control } = useFormContext()

  return <InputMask name={name} mask={mask} control={control} {...rest} />
}

InputMaskContainer.propTypes = {
  name: PropTypes.string.isRequired,
  mask: PropTypes.string.isRequired
}

export default InputMaskContainer
