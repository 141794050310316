import { useAuthContext, AuthProvider, withAuth } from './modules/auth/context'
import { SignInForm } from './modules/auth/SignIn'
import { SignUpForm } from './modules/auth/SignUp'
import ResetPasswordForm from './modules/auth/ResetPassword'
import ForgotPasswordForm from './modules/auth/ForgotPassword'
import AcceptInviteForm from './modules/auth/AcceptInvite'
import {
  ConfirmationForm,
  ConfirmationFormModal
} from './modules/auth/Confirmation'
import ResendConfirmationForm from './modules/auth/ResendConfirmation'

export {
  AuthProvider,
  withAuth,
  useAuthContext,
  SignInForm,
  SignUpForm,
  ForgotPasswordForm,
  ResetPasswordForm,
  AcceptInviteForm,
  ConfirmationForm,
  ConfirmationFormModal,
  ConfirmationFormModal as AuthConfirmationModal, // DEPRECATED
  ResendConfirmationForm
}
