import * as yup from 'yup'

import { SignUpForm as RASignUpForm } from '@tootz/react-admin/auth'
import { FormGroup, PhoneInput } from '@tootz/react-admin/form'

const validationSchema = yup.object().shape({
  fullphone: yup.string().required().label('Telefone')
  // phone_preifx: yup.string().required().label('DDD')
})

export const SignUpForm = props => {
  return (
    <RASignUpForm customValidations={validationSchema} {...props}>
      <FormGroup
        label="Telefone"
        labelClassName="fw-bolder"
        name="fullphone"
        required
        control={PhoneInput}
        vertical
      />
    </RASignUpForm>
  )
}

SignUpForm.validationSchema = validationSchema
